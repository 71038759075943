import React, { Fragment, useEffect } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import PayrollFrequency from '../../asset/images/Payroll-Frequency.jpg';
import resigntation1 from '../../asset/images/Untitledff.jpg';
import toptalent from '../../asset/images/top-talent.jpg';
import pentionplan from '../../asset/images/pention-plan.jpg';
import salestips from '../../asset/images/sales-tips.jpg';
import commonmistakes from '../../asset/images/common-mistakes.jpg';
import payrolldeduction from '../../asset/images/payroll-deduction.jpg';
import employeeInsurance from '../../asset/images/employee-insurance.jpg';
import newworkplace from '../../asset/images/new-workplace.jpg';
import { Link } from 'react-router-dom';


const VpmBlog = () => {


    return (
        <Fragment>
            <Container className='SectionBetween' id='con1'>

                <Row>
                    <Col lg={6} md={6} sm={12}>
                        <Card className='industryNewsCard'>
                            <div><Card.Img variant="top" className='industryImg' src={PayrollFrequency} alt='Understanding-Payroll-Companies' />
                                <div className='industryImgoverly'>
                                    <Link to="/understanding-payroll-companies-in-canada" class="industryicon">
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </Link>
                                </div>
                            </div>

                            <Card.Body>
                                <Card.Title className='industrycardTtle'>Understanding Payroll Companies in Canada</Card.Title>
                                <Card.Text className='commonDesc'>
                                    Payroll management is an essential aspect of any organization, and it's essential to ensure that employees are paid accurately and on time. Payroll management can be challenging for organizations, especially those that are new to the Canadian market.

                                </Card.Text>
                                <Link to="/understanding-payroll-companies-in-canada"><Button variant="primary" className='Storybutton mt-2'><span>READ MORE</span></Button>{' '}</Link>

                            </Card.Body>
                        </Card>

                    </Col>
                    <Col lg={6} md={6} sm={12}>
                        <Card className='industryNewsCard'>
                            <div><Card.Img variant="top" src={resigntation1} alt='Managing-Employee-Resignations' />
                                <div className='industryImgoverly'>
                                    <Link to="/managing-employee-resignations-best-practices" class="industryicon">
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </Link>
                                </div>
                            </div>


                            <Card.Body>
                                <Card.Title className='industrycardTtle'>Managing Employee Resignations: Best Practices</Card.Title>
                                <Card.Text className='commonDesc'>
                                    As a business owner, you must prepare for the inevitable situation of employees resigning. According to a July 2022 survey, 24% of Canadian workers have recently changed jobs, and retirement rates have increased by a third in the last decade.
                                </Card.Text>

                                <Link to="/managing-employee-resignations-best-practices"><Button variant="primary" className='Storybutton mt-2'><span>READ MORE</span></Button>{' '}</Link>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} md={6} sm={12}>
                        <Card className='industryNewsCard'>
                            <div><Card.Img variant="top" src={toptalent} alt='effective-ways-to-retain-top-talent-in-your-organization' />
                                <div className='industryImgoverly'>
                                    <Link to="/effective-ways-to-retain-top-talent-in-your-organization" class="industryicon">
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </Link>
                                </div>
                            </div>


                            <Card.Body>
                                <Card.Title className='industrycardTtle'>7 Effective Ways to Retain Top Talent in Your Organization
                                </Card.Title>
                                <Card.Text className='commonDesc'>
                                    In today's competitive business environment, retaining top talent has become a priority for many organizations. With the high cost of recruiting and training new employees, it's essential to find ways to keep your talented workforce.Here are seven effective strategies to retain your top talent-

                                </Card.Text>

                                <Link to="/effective-ways-to-retain-top-talent-in-your-organization"><Button variant="primary" className='Storybutton mt-2'><span>READ MORE</span></Button>{' '}</Link>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                        <Card className='industryNewsCard'>
                            <div><Card.Img variant="top" src={pentionplan} alt='understanding-the-canadian-pension-plan' />
                                <div className='industryImgoverly'>
                                    <Link to="/understanding-the-canadian-pension-plan" class="industryicon">
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </Link>
                                </div>
                            </div>


                            <Card.Body>
                                <Card.Title className='industrycardTtle'>Understanding the Canadian Pension Plan: A Complete Guide
                                </Card.Title>
                                <Card.Text className='commonDesc'>
                                    The Canadian Pension Plan (CPP) is a crucial social insurance program that provides financial support to Canadians during their retirement years. It's important to understand how the CPP works, what benefits it offers, and how to apply.In this blog post, we'll provide a comprehensive guide to the CPP...

                                </Card.Text>

                                <Link to="/understanding-the-canadian-pension-plan"><Button variant="primary" className='Storybutton mt-2'><span>READ MORE</span></Button>{' '}</Link>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} md={6} sm={12}>
                        <Card className='industryNewsCard'>
                            <div><Card.Img variant="top" src={salestips} alt='effective-sales-tips-to-close-your-next-deal' />
                                <div className='industryImgoverly'>
                                    <Link to="/effective-sales-tips-to-close-your-next-deal" class="industryicon">
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </Link>
                                </div>
                            </div>


                            <Card.Body>
                                <Card.Title className='industrycardTtle'>5 Effective Sales Tips to Close Your Next Deal
                                </Card.Title>
                                <Card.Text className='commonDesc'>
                                    Closing a deal is a critical stage in the sales process. Discover five valuable sales tips that can significantly increase your chances of successfully closing your next deal. By implementing these strategies, you'll be able to establish trust, understand your prospect's needs


                                </Card.Text>

                                <Link to="/effective-sales-tips-to-close-your-next-deal"><Button variant="primary" className='Storybutton mt-2'><span>READ MORE</span></Button>{' '}</Link>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                        <Card className='industryNewsCard'>
                            <div><Card.Img variant="top" src={commonmistakes} alt='avoiding-common-canadian-payroll-mistakes' />
                                <div className='industryImgoverly'>
                                    <Link to="/avoiding-common-canadian-payroll-mistakes" class="industryicon">
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </Link>
                                </div>
                            </div>


                            <Card.Body>
                                <Card.Title className='industrycardTtle'>Avoiding Common Canadian Payroll Mistakes: A Guide for Business Success
                                </Card.Title>
                                <Card.Text className='commonDesc'>
                                    Efficient payroll management is crucial for Canadian businesses. However, with the intricacies of payroll and ever-changing regulations, errors can occur. These mistakes not only impact employee satisfaction but can also lead to penalties and legal repercussions.


                                </Card.Text>

                                <Link to="/avoiding-common-canadian-payroll-mistakes"><Button variant="primary" className='Storybutton mt-2'><span>READ MORE</span></Button>{' '}</Link>

                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
                <Row>
                    <Col lg={6} md={6} sm={12}>
                        <Card className='industryNewsCard'>
                            <div><Card.Img variant="top" src={payrolldeduction} alt='payroll-deduction-plan' />
                                <div className='industryImgoverly'>
                                    <Link to="/payroll-deductions-in-canada" class="industryicon">
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </Link>
                                </div>
                            </div>


                            <Card.Body>
                                <Card.Title className='industrycardTtle'>Payroll Deductions in Canada: A Deep Dive into Statutory Deductions

                                </Card.Title>
                                <Card.Text className='commonDesc'>
                                    In Canada, payroll deductions play a crucial role in ensuring that employees' taxes, benefits, and other mandatory contributions are accurately withheld from their wages. As an employer, understanding the intricacies of statutory deductions is essential to maintain compliance with Canadian laws


                                </Card.Text>

                                <Link to="/payroll-deductions-in-canada"><Button variant="primary" className='Storybutton mt-2'><span>READ MORE</span></Button>{' '}</Link>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                        <Card className='industryNewsCard'>
                            <div><Card.Img variant="top" src={employeeInsurance} alt='understanding-employment-insurance-benefits' />
                                <div className='industryImgoverly'>
                                    <Link to="/understanding-employment-insurance-benefits-for-business-owners-in-canada" class="industryicon">
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </Link>
                                </div>
                            </div>


                            <Card.Body>
                                <Card.Title className='industrycardTtle'>Understanding Employment Insurance Benefits for Business Owners in Canada
                                </Card.Title>
                                <Card.Text className='commonDesc'>
                                    Being a business owner comes with its unique set of challenges, including the absence of traditional employment benefits such as Employment Insurance (EI). However, did you know that as a business owner in Canada, you may still be eligible for certain EI benefits?


                                </Card.Text>

                                <Link to="/understanding-employment-insurance-benefits-for-business-owners-in-canada"><Button variant="primary" className='Storybutton mt-2'><span>READ MORE</span></Button>{' '}</Link>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} md={6} sm={12}>
                        <Card className='industryNewsCard'>
                            <div><Card.Img variant="top" src={newworkplace} alt='Tips to Start a New Month at the Workplace' />
                                <div className='industryImgoverly'>
                                    <Link to="/tips-to-start-a-new-month-at-the-workplace" class="industryicon">
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </Link>
                                </div>
                            </div>


                            <Card.Body>
                                <Card.Title className='industrycardTtle'>10 Tips to Start a New Month at the Workplace
                                </Card.Title>
                                <Card.Text className='commonDesc'>
                                The start of a new month offers a valuable opportunity to reset, refocus, and set the tone for the days ahead. Whether you're a team leader or a team member, approaching each month with a clear plan and a positive mindset can significantly contribute to your professional..


                                </Card.Text>

                                <Link to="/tips-to-start-a-new-month-at-the-workplace"><Button variant="primary" className='Storybutton mt-2'><span>READ MORE</span></Button>{' '}</Link>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>



        </Fragment>


    );
};

export default VpmBlog;