import React, { Component, Fragment } from 'react';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import QuoteSection from '../components/QuoteSection/QuoteSection';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import TopHead from '../components/TopHead/TopHead';

class QuotePage extends Component {


    render() {
        return (
            <Fragment>
                <TopHead />
                <TopNavigation title="Request Quote | V-TAC Payroll Management"/>
                <QuoteSection />
                <TradePart />
                <Footer />
            </Fragment>
        );
    }
}

export default QuotePage;