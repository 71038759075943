import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
//axios for xml request
import axios from 'axios';
//xml file reader
import { withRouter } from "react-router";
import { HttpClient } from '../../lib/HttpClient';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class QuoteDetailsInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {

      id: null,
      company: "",
      numberOfEmployees: 0,
      costPerEmployee: 0,
      baseFee: 0,
      totalCostofEmployee: 0,
      peyrollRunCost: 0,
      onlineCharges: 0,
      totalCost: 0,
      successMessage: "",
      payperperiod: ""
    }
  }

  handleChange = event => {

    if (event.target.name == "totalCost") {
      this.state.totalCost = this.state.baseFee + this.state.costPerEmployee * this.state.numberOfEmployees + this.state.peyrollRunCost + this.state.onlineCharges;
    }
    else {
      this.setState({ [event.target.name]: event.target.value })
    }
  }


  submitHandaler = (event) => {
    event.preventDefault();
    axios.post("https://api.vtacpayroll.ca/sendQuation", this.state).then(Response => {
      // axios.post("http://localhost:5000/sendQuation", this.state).then(Response => {
      document.getElementById("RQForm").style.display = "none";
      document.getElementById("SuccessMSgDiv").style.display = "block";

    }).catch(error => {
      console.log(error.response.headers)
    })

  }

  componentDidMount() {
    document.getElementById("RQForm").style.display = "block";
    document.getElementById("SuccessMSgDiv").style.display = "none";
    let { id } = this.props.match.params;
    //console.log(this.props.match.params);
    HttpClient.get("/details/" + id)
      .then(result => {
        console.log(result);
        this.setState({
          id: result.data[0].id, fullName: result.data[0].fullName, company: result.data[0].company, phone: result.data[0].phone, email: result.data[0].email, numberOfEmployees: result.data[0].numberOfEmployees, payperperiod: result.data[0].payperperiod
        })
      }).catch(error => {
        console.log(error)
      })

  }


  render() {
    //const { fullName, company, phone, email, numberOfEmployees, postalCode, message } = this.state;

    return (
      <Fragment>
        <Container fluid={true} className="">
          <div >
            <Container>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <div className='quoteDetailsCard'>
                    <div id="RQForm">
                      <div className="quoteReplyHeader">
                        <h2 className='formTitle'>Quotation Reply</h2>
                      </div>
                      <Form onSubmit={this.submitHandaler} className="getinTouchform">
                        <Form.Group as={Row} className="mb-3" >
                          <Col sm="10">
                            <Form.Control type="hidden" name='id' value={this.state.id} />
                            <Form.Control type="hidden" name='payperperiod' value={this.state.payperperiod} />
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" >
                          <Col sm="10">
                            <Form.Control type="hidden" name='fullName' value={this.state.fullName} disabled />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column sm="3" className='fromLebel'>
                            Name of Company*
                          </Form.Label>
                          <Col sm="9">
                            <Form.Control className='disablefromControl' style={{ 'width': '95%' }} type="text" name='company' value={this.state.company} disabled />
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" >

                          <Col sm="10">
                            <Form.Control type="hidden" name='phone' value={this.state.phone} disabled />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">

                          <Col sm="10">
                            <Form.Control type="hidden" name='email' value={this.state.email} disabled />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column sm="3">
                            Total Employees*
                          </Form.Label>
                          <Col sm="9">
                            <Form.Control className='disablefromControl' type="number" style={{ 'width': '30%' }} name='numberOfEmployees' value={this.state.numberOfEmployees} disabled />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column sm="3">
                            One Time Set-up Fee*
                          </Form.Label>
                          <Col sm="9">
                            <Form.Control min={0} style={{ 'width': '30%' }} name='baseFee' value={this.state.baseFee} onChange={this.handleChange} />
                          </Col>
                        </Form.Group>
                        <Form.Text className="text-muted formtext">
                          One Time Set-up fee includes the following
                        </Form.Text>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                          <Form.Check type="checkbox" className='checkboxForm' label="Set-up of payroll details" checked />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                          <Form.Check type="checkbox" className='checkboxForm' label="Checking calculations" checked />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                          <Form.Check type="checkbox" className='checkboxForm' label="Administering first payroll" checked />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                          <Form.Check type="checkbox" className='checkboxForm' label="Reviewing payroll output" checked />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                          <Form.Check type="checkbox" className='checkboxForm' label="Training to the concerned employees" />
                        </Form.Group>
                        <Form.Text className="text-muted formtext">
                          <strong>Direct Cost</strong>
                        </Form.Text>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column sm="3">
                            Per Employee Cost $
                          </Form.Label>
                          &nbsp;&nbsp;&nbsp;
                          {/* <div style={{ display: "flex", maxWidth: "100%" }}>  */}

                          <span> <Form.Control style={{ 'width': '110%' }} name='costPerEmployee' title='Cost per Employee' value={this.state.costPerEmployee} onChange={this.handleChange} /></span>

                          <span style={{ marginLeft: "25px", marginRight: "15px" }}><strong>&nbsp;x&nbsp;</strong></span>
                          <span><Form.Control className='disablefromControl' style={{ 'width': '110%' }} type="number" name='numberOfEmployees' title='Total employee' value={this.state.numberOfEmployees} onChange={this.handleChange} disabled /></span>

                          <span style={{ marginLeft: "25px", marginRight: "15px" }}><strong>&nbsp;=&nbsp;</strong></span>

                          <span><Form.Control className='disablefromControl' style={{ 'width': '100%' }} type="number" name='totalCostofEmployee' value={parseFloat(this.state.costPerEmployee) * parseFloat(this.state.numberOfEmployees)} title="Total cost of Employee" onChange={this.handleChange} disabled /></span>
                          {/* </div> */}

                        </Form.Group>
                        <Form.Text className="text-muted formtext">
                          <strong>Fixed Cost</strong>
                        </Form.Text>
                        <Form.Group as={Row} className="mb-3" >
                          <Form.Label column sm="3">

                            Peyroll Run Cost $
                          </Form.Label>
                          <Col sm="9">
                            <Form.Control style={{ 'width': '95%' }} min={0} name='peyrollRunCost' onChange={this.handleChange} value={this.state.peyrollRunCost} />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" >
                          <Form.Label column sm="3">
                            Online Charges $
                          </Form.Label>
                          <Col sm="9">
                            <Form.Control style={{ 'width': '95%' }} min={0} name='onlineCharges' onChange={this.handleChange} value={this.state.onlineCharges} />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" >
                          <Form.Label column sm="3">
                            Total Cost $
                          </Form.Label>
                          <Col sm="9">
                            <Form.Control className='disablefromControl' style={{ 'width': '95%' }} type="number" min={0} name='totalCost' onChange={this.handleChange} value={parseFloat(this.state.baseFee) + (parseFloat(this.state.costPerEmployee) * parseFloat(this.state.numberOfEmployees)) + parseFloat(this.state.peyrollRunCost) + parseFloat(this.state.onlineCharges)} disabled />
                          </Col>
                        </Form.Group>
                        <div className='text-center'><Button className="replyBtn mt -5 mb-3" type="submit">
                          <span>Reply Quotation</span>
                        </Button></div>
                      </Form>
                    </div>
                    <div id='SuccessMSgDiv'>
                      <h2 className='SuccessMsg'>Quotation has been sent Successfully</h2>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </Fragment>
    );
  }
}

export default withRouter(QuoteDetailsInfo);



