import React, { Component, Fragment } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import drgauravlogo from '../../asset/images/drgaurav-logo.png';
import groverlogo from '../../asset/images/grover-logo.png';
import kingswaylogo from '../../asset/images/kingsway-logo.png';
import sandhudentistry from '../../asset/images/sandhu-dentistry.png';
import wecare from '../../asset/images/we-care.png';
import mountroyaldental from '../../asset/images/mount-royal-dental.png';
import mathesondentalclinic from '../../asset/images/matheson-dental-clinic.png';
import muttidental from '../../asset/images/mutti-dental.png';
import alineadental from '../../asset/images/alinea-dental.png';
import dentistrymerritt from '../../asset/images/dentistry-merritt.png';
import drdaisychemalyandassociates from '../../asset/images/dr-daisy-chemaly-and-associates.png';
import drschindlerandassociates from '../../asset/images/dr-schindler-and-associates.png';


class DentalOfficesSlide extends Component {
    render() {
        var settings = {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 3000,
            slidesToShow: 6,
            slidesToScroll: 3,
            autoplay: true,
            autoplaySpeed: 2000,


            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 6,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <Fragment>

                <Container className="slideShowSection SectionBetween">
                    <h2 className='mainTitle text-center mb-5'><strong className='spancolor'>Trusted</strong> By BUSINESSES LIKE YOURS</h2>
                    <Slider {...settings}>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={groverlogo} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={drgauravlogo} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={sandhudentistry} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={wecare} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={mountroyaldental} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={mathesondentalclinic} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={kingswaylogo} /></a>
                                </Col>
                            </Row>
                        </div>
                       
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={muttidental} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={alineadental} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={dentistrymerritt} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={drdaisychemalyandassociates} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={drschindlerandassociates} /></a>
                                </Col>
                            </Row>
                        </div>
                       
                    </Slider>


                </Container>
            </Fragment>
        );
    }
}

export default DentalOfficesSlide;