import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faYoutube, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
// import Logo from '../../asset/images/vpmlogo.png';
import Logo from '../../asset/images/Transparent logo.png';

export default class Footer extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="footerSection SectionBetween" >
                    <div>
                        <Container>
                            <Row>
                                <Col lg={3} md={6} sm={12} className="mt-3 ">
                                    <Link to="/"><h2 class="FooterTitle"><img className="logoImageFooter" title="VPM" src={Logo} /></h2></Link>
                                    <div>
                                        <p style={{ color: "white" }}><span><strong>Let’s Stay in Touch.</strong></span></p>
                                        <div className="footerLinkDiv mt-3">
                                            <a href="https://www.linkedin.com/company/v-tac-payroll-management/" target='_blank' className="footerSocialLink"><FontAwesomeIcon className='footerfontAwsome' icon={faLinkedin} /></a>
                                            {/* <a href="#" className="footerSocialLink"><FontAwesomeIcon className='footerfontAwsome' icon={faTwitter} /></a> */}
                                            <a href="https://www.facebook.com/vtacpayroll/" target='_blank' className='footerSocialLink' ><FontAwesomeIcon className='footerfontAwsome' icon={faFacebook} /></a><br />
                                            <a href="https://www.youtube.com/@v-tacpayrollmanagement3092/videos" target='_blank' className='footerSocialLink' ><FontAwesomeIcon className='footerfontAwsome' icon={faYoutube} /></a>

                                        </div>
                                        <p className="footerAdressSubtitle mt-3"><FontAwesomeIcon style={{ color: '#00BCD4' }} icon={faEnvelope} /><strong> info@vtacpayroll.ca</strong> </p>
                                        <Row>

                                            <Col>
                                                <a href="tel:+1-866-315-8822">
                                                    <Button className="footerButton mt-2" style={{ width: '200' }} variant="primary" type="submit">
                                                        <span> 1-866-315-8822</span>
                                                    </Button>
                                                </a>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col lg={3} md={6} sm={12} className="mt-5">
                                    <h2 class="FooterTitle"><span>Industries</span></h2>
                                    <div><ul className='footerUl'>
                                        <Link to="/payroll-service-dentists"><li><FontAwesomeIcon className="iconBullet" icon={faCheckCircle} /><a className='aColor'> Dentistry</a></li></Link>
                                        <Link to="/hospitality-payroll-software"><li> <FontAwesomeIcon className="iconBullet" icon={faCheckCircle} /><a className='aColor'> Hospitality</a></li></Link>
                                        <Link to="/payroll-for-law-firms"><li> <FontAwesomeIcon className="iconBullet" icon={faCheckCircle} /><a className='aColor'> Law Offices</a></li></Link>
                                        <Link to="/payroll-service-employment-agency"><li> <FontAwesomeIcon className="iconBullet" icon={faCheckCircle} /><a className='aColor' > Employment Agencies</a></li></Link>
                                        <Link to="/payroll-company-construction"><li> <FontAwesomeIcon className="iconBullet" icon={faCheckCircle} /><a className='aColor'> Construction</a></li></Link>
                                    </ul>
                                    </div>
                                </Col>

                                <Col lg={3} md={6} sm={12} className="mt-5 text-justify">
                                    <h2 class="FooterTitle"><span>GENERAL</span></h2>
                                    <div>
                                        <ul className='footerUl'>
                                            <Link to="/business-payroll-services"> <li><FontAwesomeIcon className="iconBullet" icon={faCheckCircle} /><a className='aColor'> Payroll Services</a></li></Link>
                                            <Link to="/who-we-are"><li><FontAwesomeIcon className="iconBullet" icon={faCheckCircle} /><a className='aColor'> Who We Are</a></li></Link>
                                            <Link to="/switching-payroll-vpm"><li><FontAwesomeIcon className="iconBullet" icon={faCheckCircle} /><a className='aColor' > Making the Switch</a></li></Link>
                                            <Link to="/how-were-different"><li><FontAwesomeIcon className="iconBullet" icon={faCheckCircle} /><a className='aColor'> How We’re Different</a></li></Link>
                                            <Link to="/faq"><li> <FontAwesomeIcon className="iconBullet" icon={faCheckCircle} /><a className='aColor'> FAQ</a></li></Link>
                                            <Link to="/careers"><li> <FontAwesomeIcon className="iconBullet" icon={faCheckCircle} /><a className='aColor' onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}> Careers</a></li></Link>
                                            <Link to="/request-payroll-quote"><li> <FontAwesomeIcon className="iconBullet" icon={faCheckCircle} /><a className='aColor'> Request Quote</a></li></Link>
                                            <Link to="/contact"><li> <FontAwesomeIcon className="iconBullet" icon={faCheckCircle} /><a className='aColor'> Contact Us</a></li></Link>
                                            <Link to="/industry-news"><li> <FontAwesomeIcon className="iconBullet" icon={faCheckCircle} /><a className='aColor'> Industry News</a></li></Link>
                                            <Link to="/client-success-stories"><li><FontAwesomeIcon className="iconBullet" icon={faCheckCircle} /><a className='aColor'> Client Success Stories</a></li></Link>
                                        </ul>
                                    </div>
                                </Col>
                                <Col lg={3} md={6} sm={12} className="  mt-5">
                                    <div><h2 class="FooterTitle">Office address</h2>
                                        <ul className='footerUl'>
                                            <li className='liOfficeAddress'> <strong>Toronto – Head Office</strong> <p>401 Bay Street, Suite 1600 Toronto, ON M5H 2Y4</p></li>
                                            <hr className="hrFooter" style={{ bordertop: 'dotted 1px' }} />
                                            <li className='liOfficeAddress'>​<strong>Toronto</strong><p>100 King Street West,<br></br>Suite 5700 <br></br>Toronto, ON M5X 1C7</p></li>
                                            <hr className="hrFooter" />
                                            <li className='liOfficeAddress'>​<strong>Toronto</strong><p>881A Jane Street, Suite 207 Toronto, ON M6M 4C4</p></li>
                                            <hr className="hrFooter" />
                                            <li className='liOfficeAddress'>​<strong>Calgary</strong><p>300, 840-6 Avenue SW<br></br>Calgary, AB T2P 3E5</p></li>
                                            <hr className="hrFooter" />
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>

                <Container fluid={true} className="text-center copyrightSec">
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <h3 className="copyrightLink" >
                                Copyright &copy; 2022 V-TAC Payroll Management. All rights reserved.
                            </h3>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}
