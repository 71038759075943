import React, { Fragment } from 'react';
import TopHead from '../../components/TopHead/TopHead';
import TopNavigation from '../../components/TopNavigation/TopNavigation';
import TradePart from '../../components/TradePart/TradePart';
import QuoteComponentCyan from '../../components/QuoteComponentCyan/QuoteComponentCyan';
import Footer from '../../components/Footer/Footer';
import IndustryNewsBanner from '../../components/IndustryNewsBanner/IndustryNewsBanner';
import { Container } from 'react-bootstrap';
import AvoidingCommonMistakes from '../../components/Blogs/AvoidingCommonMistakes';

const Blog6Page = () => {
    return (
        <Fragment>
            <Container fluid="true" className='accContainer'>
                <TopHead />
                <TopNavigation title="Avoiding Common Canadian Payroll Mistakes: A Guide for Business Success
           | V-TAC Payroll Management" />
                <IndustryNewsBanner />
                <AvoidingCommonMistakes />
                <QuoteComponentCyan />
                <TradePart />
                <Footer />
            </Container>
        </Fragment>
    );
};

export default Blog6Page;