import React, { Component, Fragment } from 'react';
import FAQNavigation from '../components/FAQNavigation/FAQNavigation';
import FAQTopBanner from '../components/FAQTopBanner/FAQTopBanner';
import TopHead from '../components/TopHead/TopHead';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import QuoteComponentCyan from '../components/QuoteComponentCyan/QuoteComponentCyan';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';

class FAQPage extends Component {
    render() {
        return (
            <Fragment>
                <TopHead />
                <TopNavigation title="FAQ | V-TAC Payroll Management"/>
                <FAQTopBanner />
                <FAQNavigation />
                <QuoteComponentCyan />
                <TradePart />
                <Footer />
            </Fragment>
        );
    }
}

export default FAQPage;