import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class HowWeAreDifferentBanner extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="HowDefferentTopBanner  p-0">
                    <div className='payrollServicesTopBannerOverly'>
                        <Container className="topContent text-center">
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <h1 className='topTitle '>
                                        HOW WE’RE DIFFERENT
                                    </h1>
                                    <Link to="/request-payroll-quote"><Button className='quotebutton mt-2'><span>GET A QUOTE</span></Button></Link>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </Fragment>
        );
    }
}

export default HowWeAreDifferentBanner;