import React, { Fragment } from 'react';
import TopHead from '../../components/TopHead/TopHead';
import TopNavigation from '../../components/TopNavigation/TopNavigation';
import TradePart from '../../components/TradePart/TradePart';
import QuoteComponentCyan from '../../components/QuoteComponentCyan/QuoteComponentCyan';
import Footer from '../../components/Footer/Footer';
import IndustryNewsBanner from '../../components/IndustryNewsBanner/IndustryNewsBanner';
import { Container } from 'react-bootstrap';
import EffectiveSales from '../../components/Blogs/EffectiveSales';

const Blog5Page = () => {
    return (
        <Fragment>
            <Container fluid="true" className='accContainer'>
                <TopHead />
                <TopNavigation title="5 Effective Sales Tips to Close Your Next Deal: A Complete Guide
               | V-TAC Payroll Management" />
                <IndustryNewsBanner />
                <EffectiveSales />
                <QuoteComponentCyan />
                <TradePart />
                <Footer />
            </Container>
        </Fragment>
    );
};

export default Blog5Page;