import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import meeting3 from '../../asset/images/meeting3.jpg';
import takingnotes1 from '../../asset/images/taking-notes-1.jpg';

class UnifiedHRSection4 extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className='helpSmallBusinessContainer ' >
                    <div>
                        <Container>
                            <Row>
                                <Col lg={8} md={6} sm={12}>
                                    <h2 className='commonTitle SectionBetween'>Choosing the <span className='spancolor'>Best</span> HR Software for better Management & Employee Performance</h2>
                                    <p className='commonDesc'>When you are looking for HCM software, you need to understand your business’s HR needs. Consider all the challenges related to HR that your business faces.</p>
                                    <p className='commonDesc'>Meet with one of our skilled representatives to discuss exactly how VPM can meet your needs.</p>
                                </Col>
                                <Col lg={4} md={6} sm={12}>
                                    <img className="helpSmallBusinessImage float-right SectionBetween" alt="Two people meeting" title='Two people meeting' src={meeting3} />
                                </Col>
                            </Row>
                            <Row className='SectionBetween'>
                                <Col lg={4} md={6} sm={12}>
                                    <div className='mb-5'>
                                    <img className="helpSmallBusinessImage" alt="Person taking notes" title='Person taking notes' src={takingnotes1} />
                                    </div>
                                   
                                </Col>
                                <Col lg={8} md={6} sm={12}>

                                    <h2 className='commonTitle'>
                                        Fully <span className='spancolor'>Customized</span> HR Management for Your Convenience</h2>
                                    <p className='commonDesc'>At V-Tac Payroll, our Unified HR & Benefits service uses HCM software. We apply this technology to human resource processes to help business owners manage their employees.</p>
                                    <p className='commonDesc'>Our customizable HCM software can help you optimize the performance of your employees.</p>

                                </Col>
                            </Row>

                        </Container>
                    </div>

                </Container>
            </Fragment>
        );
    }
}

export default UnifiedHRSection4;