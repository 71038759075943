import React, { Component, Fragment } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import groverlogo from '../../asset/images/grover-logo.png';
import kingswaylogo from '../../asset/images/kingsway-logo.png';
import shelllogo from '../../asset/images/shell-logo.png';
import subwaylogo from '../../asset/images/subway-logo.png';
// import tbotlogo from '../../asset/images/tbot-logo.png';
import vislogo from '../../asset/images/vis-logo.png';
import wimpyslogo from '../../asset/images/wimpys-logo.png';
import korbiteclogo from '../../asset/images/korbitec-logo.png';
import weisberglaw from '../../asset/images/weisberg-law.png';
import alineadental from '../../asset/images/alinea-dental.png';
import sammys from '../../asset/images/sammys.png';
import graffitilogo from '../../asset/images/graffiti-logo.png';
import chellseylogo from '../../asset/images/chellsey-logo.png';
import theberlin from '../../asset/images/the-berlin.png';

class WhoWeAreSlide extends Component {
    render() {
        var settings = {
            dots: false,
            arrows: true,
            infinite: true,
            speed: 3000,
            slidesToShow: 6,
            slidesToScroll: 6,
            autoplay: true,
            autoplaySpeed: 2000,


            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 6,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <Fragment>

                <Container className="slideShowSection SectionBetween">
                    <h2 className='mainTitle text-center mb-5'><strong className='spancolor'>Trusted</strong> By:</h2>
                    <Slider {...settings}>
                      
                       
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={groverlogo} alt='grover' /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={wimpyslogo} alt='wimpys' /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={shelllogo} alt='shell' /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={subwaylogo} alt='subway' /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={korbiteclogo} alt='korbitec' /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={weisberglaw} alt='weisberglaw' /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={alineadental}  alt='alineadental'/></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={sammys} alt='sammys'/></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={graffitilogo} alt='graffiti' /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={chellseylogo} alt='chellsey' /></a>
                                </Col>
                            </Row>
                        </div>
                        
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={vislogo} alt='vis' /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={theberlin} alt='theberlin' /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={kingswaylogo} alt='kingsway' /></a>
                                </Col>
                            </Row>
                        </div>
                    </Slider>


                </Container>
            </Fragment>
        );
    }
}

export default WhoWeAreSlide;