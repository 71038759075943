import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class PayrollServiceEightPart extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className='quoteComponentGray SectionBetween text-center'>
                    <div>
                        <Container>
                            <Row>
                                <Col>
                                    <h2 className='qoutetopTitlePS'>YOU’RE IN GOOD HANDS</h2>
                                    <h4 className='qoutetopSubTitle2'><p>V-TAC Payroll Management (VPM) has over 20 years of experience in developing and implementing quality solutions for specific payroll needs. You’ll be matched with a professional, experienced, and friendly payroll consultant who will respond to your needs within 30 minutes.</p></h4>
                                 <Link to="/how-were-different"><Button variant="primary" className='button2 mb-3'><span>SEE HOW WE'RE DIFFERENT</span></Button></Link>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                </Container>
            </Fragment>
        );
    }
}

export default PayrollServiceEightPart;