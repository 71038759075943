import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import restaurantownerwithlaptop from '../../asset/images/restaurant-owner-with-laptop.jpg';
import { Link } from 'react-router-dom';

class JoinNow extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className='SectionBetween joinSection'>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <img className="restaurantOwnerwithlaptopImg " alt="Restaurant owner with laptop" title='Restaurant owner with laptop' src={restaurantownerwithlaptop} loading="lazy" />
                        </Col>
                        <Col lg={6} md={6} sm={12} >
                            <div class="joindiv">
                                <div class="mt-5">
                                    <h3 className='subTitle'>Make the switch to a hassle-free experience.</h3>
                                    <p className='description'>Request a quote to see how VPM can fit the needs of your business.<br />
                                       <Link to="/contact"> <strong><a className='joinNowLink'>Join now to receive one FREE month of payroll &amp; a tablet*!</a></strong></Link></p>
                                    <p> <Link to="/promotional-conditions" className='quoteCondition'>*some conditions apply</Link></p>
                                </div>
                                <div>
                                    <Link to="/contact"><Button className='Storybutton mt-3 mb-3' data-aos="fade-down"
                                        data-aos-easing="linear"
                                        data-aos-duration="1500"
                                        data-aos-once="true"><span>JOIN NOW</span></Button></Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default JoinNow;