import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class MountRoyalDentalBanner extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="nexasStaffingBanner  p-0">
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <div className='container' style={{ width: "40%" }}>
                                <h1 className='nexastopTitle text-center'>
                                MOUNT ROYAL SAVES TIME & MONEY WITH VPM
                                </h1>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default MountRoyalDentalBanner;