import React, { Component, Fragment } from 'react'
import { Switch, Route } from "react-router-dom";
import QuotePage from '../pages/QuotePage';
import QuoteDetaisPage from '../pages/QuoteDetaisPage';
import ContactPage from '../pages/ContactPage';
import PayrollServicesPage from '../pages/PayrollServicesPage';
import HomePage from '../pages/HomePage';
import UnifiedHRAndBenefitsPage from '../pages/UnifiedHRAndBenefitsPage';
import DentalOfficesPage from '../pages/DentalOfficesPage';
import HospitalityIndustryPage from '../pages/HospitalityIndustryPage';
import EmploymentAgenciesPage from '../pages/EmploymentAgenciesPage';
import LawFirmsPage from '../pages/LawFirmsPage';
import ConstructionOrConstructorPage from '../pages/ConstructionOrConstructorPage';
import WhoWeAREPage from '../pages/WhoWeAREPage';
import HowWeAreDifferentPage from '../pages/HowWeAreDifferentPage';
import MakingTheSwitchPage from '../pages/MakingTheSwitchPage';
import CareersPage from '../pages/CareersPage';
import FAQPage from '../pages/FAQPage';
import ClientSuccessStoriesPage from '../pages/ClientSuccessStoriesPage';
import IndustryNewsPage from '../pages/IndustryNewsPage';
import NexasStaffingPage from '../pages/NexasStaffingPage';
import MountRoyalDentalPage from '../pages/MountRoyalDentalPage';
import InsuranceAgentPage from '../pages/InsuranceAgentPage';
import GlobalImagingPage from '../pages/GlobalImagingPage';
import SchindlerAssociatesPage from '../pages/SchindlerAssociatesPage';
import ConditionPage from '../pages/ConditionPage';
import AllIndustryNewsPge from '../pages/AllIndustryNewsPge';
import QuotationSendPage from '../pages/QuotationSendPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import GoogleReviewPage from '../pages/GoogleReviewPage';
import UnderstandingPayrollPage from '../pages/BlogPage/UnderstandingPayrollPage';
import ManagingEmployeePage from '../pages/BlogPage/ManagingEmployeePage';
import EffectiveWaysPage from '../pages/BlogPage/EffectiveWaysPage';
import PensionPlanPage from '../pages/BlogPage/PensionPlanPage';
import EffectiveSalesPage from '../pages/BlogPage/EffectiveSalesPage';
import AvoidingCommonMistakesPage from '../pages/BlogPage/AvoidingCommonMistakesPage';
import PayrollDeductionsPage from '../pages/BlogPage/PayrollDeductionsPage';
import UnderstandingEmploymentPage from '../pages/BlogPage/UnderstandingEmploymentPage';
import TheWorkplacePage from '../pages/BlogPage/TheWorkplacePage';
import PayrollCalculatorPage from '../pages/PayrollCalculatorPage';

//const payrollService = React.lazy(()=>import('../pages/PayrollServicesPage'))


export default class AppRoute extends Component {
    render() {
        return (
            <Fragment>

                <Switch>
                    <Route exact path="/" component={HomePage} />
                    <Route exact path="/payroll-calculator" component={PayrollCalculatorPage} />
                    <Route exact path="/client-review" component={GoogleReviewPage} />
                    <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
                    <Route exact path="/all-industry-news" component={AllIndustryNewsPge} />
                    <Route exact path="/contact" component={ContactPage} />
                    <Route exact path="/promotional-conditions" component={ConditionPage} />
                    <Route exact path="/industry-news" component={IndustryNewsPage} />
                    <Route exact path="/global-imaging" component={GlobalImagingPage} />
                    <Route exact path="/dr-schindler-associates" component={SchindlerAssociatesPage} />
                    <Route exact path="/insurance-agent" component={InsuranceAgentPage} />
                    <Route exact path="/mount-royal-dental" component={MountRoyalDentalPage} />
                    <Route exact path="/nexus-staffing" component={NexasStaffingPage} />
                    <Route exact path="/client-success-stories" component={ClientSuccessStoriesPage} />
                    <Route exact path="/faq" component={FAQPage} />
                    <Route exact path="/careers" component={CareersPage} />
                    <Route exact path="/switching-payroll-vpm" component={MakingTheSwitchPage} />
                    <Route exact path="/how-were-different" component={HowWeAreDifferentPage} />
                    <Route exact path="/who-we-are" component={WhoWeAREPage} />
                    <Route exact path="/payroll-company-construction" component={ConstructionOrConstructorPage} />
                    <Route exact path="/payroll-for-law-firms" component={LawFirmsPage} />
                    <Route exact path="/payroll-service-employment-agency" component={EmploymentAgenciesPage} />
                    <Route exact path="/hospitality-payroll-software" component={HospitalityIndustryPage} />
                    <Route exact path="/payroll-service-dentists" component={DentalOfficesPage} />
                    <Route exact path="/unified-hr-benefits" component={UnifiedHRAndBenefitsPage} />
                    <Route exact path="/business-payroll-services" component={PayrollServicesPage} />
                    <Route exact path="/request-payroll-quote" component={QuotePage} />
                    <Route exact path="/quoteDetails/" component={QuoteDetaisPage} />
                    <Route exact path="/quoteDetails/:id" component={QuoteDetaisPage} />
                    <Route exact path="/request-payroll-success" component={QuotationSendPage} />


                    {/* blog route */}
                    <Route exact path="/understanding-payroll-companies-in-canada" component={UnderstandingPayrollPage} />
                    <Route exact path="/managing-employee-resignations-best-practices" component={ManagingEmployeePage} />
                    <Route exact path="/effective-ways-to-retain-top-talent-in-your-organization" component={EffectiveWaysPage} />
                    <Route exact path="/understanding-the-canadian-pension-plan" component={PensionPlanPage} />
                    <Route exact path="/effective-sales-tips-to-close-your-next-deal" component={EffectiveSalesPage} />
                    <Route exact path="/avoiding-common-canadian-payroll-mistakes" component={AvoidingCommonMistakesPage} />
                    <Route exact path="/payroll-deductions-in-canada" component={PayrollDeductionsPage} />
                    <Route exact path="/understanding-employment-insurance-benefits-for-business-owners-in-canada" component={UnderstandingEmploymentPage} />
                    <Route exact path="/tips-to-start-a-new-month-at-the-workplace" component={TheWorkplacePage} />

                </Switch>

            </Fragment>

        )
    }
}
