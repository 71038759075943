import React, { Component, Fragment } from 'react';
import { Navbar, Nav, Row, Col, Container, Button, Dropdown } from 'react-bootstrap';
import '../../asset/css/custom.css';
import '../../asset/css/responsive.css';
import '../../asset/css/bootstrap.min.css';
import vpmlogo from '../../asset/images/vpmlogo.png';
import { NavLink, Link } from "react-router-dom";
import NavDropdown from 'react-bootstrap/NavDropdown';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.min.css';

class TopNavigation extends Component {
    constructor(props) {
        super();
        this.state = {
            pageTitle: props.title
        }
    }
    // scroll to top on page load
    componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    HomeClick() {
        if (document.getElementById("RQForm") != null || document.getElementById("RQForm") != undefined) {
            document.getElementById("RQForm").style.display = "block";
            document.getElementById("SuccessMSgDiv").style.display = "none";
        }

    }
    render() {

        return (
            <Fragment>
                <title>{this.state.pageTitle}</title>
                <Container fluid={true} className='navSection'>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <Navbar bg="light" expand="lg" className="custom-navbar" >
                                <Navbar.Brand href="#">
                                    <img src={vpmlogo} className='vpmlogo ml-2 'loading="lazy"  alt="V-TAC Payroll Management" title='V-TAC Payroll Management' onClick={() => {
                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                }} />
                                </Navbar.Brand>
                                <Link to="/request-payroll-quote"> <Navbar.Brand ><button className='quoteBtnForSmallScreen'>Request Quote</button></Navbar.Brand></Link >
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="ml-auto">
                                        <Nav.Link ><NavLink exact activeStyle={{ color: '#E6001F' }} className="navItem" to='/' onClick={() => {
                                            window.scrollTo({ top: 0, behavior: 'smooth' });
                                        }}>Home</NavLink></Nav.Link>
                                        <Nav.Link ><NavLink className="navItem" to='/payroll-calculator' onClick={() => {
                                            window.scrollTo({ top: 0, behavior: 'smooth' });
                                        }}>Payroll Calculator</NavLink></Nav.Link>
                                        <NavDropdown title="Solutions" id="collasible-nav-dropdown" renderMenuOnMount={true}>
                                            <hr className='navHr'></hr>
                                            <NavDropdown.Item
                                                as={NavLink} to="/business-payroll-services" onClick={() => {
                                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                                }}>Payroll Services</NavDropdown.Item>
                                            <NavDropdown.Item as={NavLink} to="/unified-hr-benefits" onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}>
                                                Unified HR & Benefits
                                            </NavDropdown.Item>
                                        </NavDropdown>

                                        <NavDropdown title="Industries We Serve" id="collasible-nav-dropdown" renderMenuOnMount={true}>
                                            <hr className='navHr'></hr>
                                            <NavDropdown.Item as={NavLink} to="/payroll-service-dentists" onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}>Dental Offices</NavDropdown.Item>
                                            <NavDropdown.Item as={NavLink} to="/hospitality-payroll-software" onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}>Hospitality Industry</NavDropdown.Item>
                                            <NavDropdown.Item as={NavLink} to="/payroll-service-employment-agency" onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}>Employment Agencies</NavDropdown.Item>
                                            <NavDropdown.Item as={NavLink} to="/payroll-for-law-firms" onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}>Law Firms</NavDropdown.Item>
                                            <NavDropdown.Item as={NavLink} to="/payroll-company-construction" onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}>Construction/Contractors</NavDropdown.Item>
                                        </NavDropdown>

                                        <NavDropdown title="About" id="collasible-nav-dropdown" renderMenuOnMount={true} >
                                            <hr className='navHr'></hr>
                                            <NavDropdown.Item as={NavLink} to="/who-we-are" onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}>Who We Are</NavDropdown.Item>
                                            <NavDropdown.Item as={NavLink} to="/client-review" onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}>Client Review</NavDropdown.Item>
                                            <NavDropdown.Item as={NavLink} to="/how-were-different" onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}>How We’re Different</NavDropdown.Item>
                                            <NavDropdown.Item as={NavLink} to="/switching-payroll-vpm" onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}>Making the Switch</NavDropdown.Item>
                                            <NavDropdown.Item as={NavLink} to="/privacy-policy" onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}>Privacy Policy</NavDropdown.Item>
                                            <NavDropdown.Item as={NavLink} to="/careers">Careers</NavDropdown.Item>
                                        </NavDropdown>

                                        <NavDropdown title="Resources" id="collasible-nav-dropdown" renderMenuOnMount={true}>
                                            <hr className='navHr'></hr>
                                            <NavDropdown.Item as={NavLink} to="/faq" onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}>FAQ</NavDropdown.Item>
                                            <NavDropdown.Item as={NavLink} to="/client-success-stories" onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}>Client Success Stories</NavDropdown.Item>
                                            <NavDropdown.Item activeClass="active" as={NavLink} to="/industry-news" onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}>Industry News</NavDropdown.Item>
                                        </NavDropdown>
                                        <Nav.Link> <NavLink className="navItem" to="/contact" onClick={() => {
                                            window.scrollTo({ top: 0, behavior: 'smooth' });
                                        }}>Contact</NavLink></Nav.Link>
                                    </Nav>
                                    <Link to="/request-payroll-quote"><Button className="navButtonLink" onClick={() => {
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }}><span><strong>Request Quote</strong></span></Button>{' '}</Link>

                                </Navbar.Collapse>
                            </Navbar>
                            {/* <button id='top'
                    onClick={() => {
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    }}

                ><FontAwesomeIcon className='Top' icon={faArrowCircleUp} /></button> */}
                        </Col>
                    </Row>


                    <div style={{ clear: 'both' }}></div>

                </Container>
            </Fragment>
        );
    }
}

export default TopNavigation;


















