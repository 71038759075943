import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle,faCheckDouble } from '@fortawesome/free-solid-svg-icons';

import vpmpayrollreports from '../../asset/images/vpm-payroll-reports.png';

class PayrollSrrviceSecondPart extends Component {
    render() {
        return (
            <Fragment>
                <Container className='SectionBetween'>
                    <h2 className='mainTitle'>
                        FULLY CUSTOMIZED<span className='spancolor'> PAYROLL SERVICES</span><br></br> FOR YOUR UNIQUE BUSINESS
                    </h2>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <div style={{ width: "90%" }}>
                                <p className='PayrollDesc mt-5'><span><FontAwesomeIcon className="iconBullet" icon={faCheckDouble} /> </span>Set up your payroll structure according to the way you pay your employees, no matter the size of your business.</p>
                                <p className='PayrollDesc'><FontAwesomeIcon className="iconBullet" icon={faCheckDouble} /> Customize your payroll in regards to taxes, deductions, benefits, and paid/unpaid leave.</p>
                                <p className='PayrollDesc'><FontAwesomeIcon className="iconBullet" icon={faCheckDouble} /><span> Ensure compliance by utilizing built-in options that adhere to Canadian Revenue Agency (CRA) provincial legislation</span> </p>
                            </div>

                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <img className="payrollSrrviceSecondPartImage mt-3 " alt="vpm-payroll-reports" title='vpm-payroll-reports' src={vpmpayrollreports} />
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default PayrollSrrviceSecondPart;