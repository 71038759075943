import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import demoImg from '../../asset/images/username-logo-png-180x180.png';
import googlelogo from '../../asset/images/googlelogo.png';
import fbstar from '../../asset/images/fb-star.png';

// add google review css
import "../../components/GoogleReview/googleReview.css"



class GoogleReview extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <div>
                        <h2 className='review-title'>
                            VPM (V-Tac Payroll Management)
                        </h2>
                    </div>
                    <hr></hr>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Mike DiMaso</strong> &nbsp; <a href='https://www.google.com/search?q=vtacpayroll&hl=en-US&authuser=1&sxsrf=APwXEdcMlBmnPi8Z6V12HlVQkZuul91Hcg%3A1680252744855&ei=SJ8mZOjYM_bx4-EP65yU8AM&ved=0ahUKEwjonPWf5YX-AhX2-DgGHWsOBT4Q4dUDCA8&uact=5&oq=vtacpayroll&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIECCMQJ0oECEEYAVCZCliMDWCDE2gBcAB4AIABkgGIAZYCkgEDMC4ymAEAoAEBwAEB&sclient=gws-wiz-serp#lrd=0x882b34ccd9525551:0x8d77ce9b2c3ffd22,1' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Dealing with V-Tac over the past 10 years the customer support that we have received has been fantastic. They work hard to offer the best performance payroll service and insure that all our needs are met without any hesitation. In the end, it comes down to knowing the business and what they need to help us to be successful and achieve our goals.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>George Adamidis</strong> &nbsp; <a href='https://www.google.com/search?q=vtacpayroll&hl=en-US&authuser=1&sxsrf=APwXEdcMlBmnPi8Z6V12HlVQkZuul91Hcg%3A1680252744855&ei=SJ8mZOjYM_bx4-EP65yU8AM&ved=0ahUKEwjonPWf5YX-AhX2-DgGHWsOBT4Q4dUDCA8&uact=5&oq=vtacpayroll&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIECCMQJ0oECEEYAVCZCliMDWCDE2gBcAB4AIABkgGIAZYCkgEDMC4ymAEAoAEBwAEB&sclient=gws-wiz-serp#lrd=0x882b34ccd9525551:0x8d77ce9b2c3ffd22,1' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    We've used VPM for almost 3 years and are extremely happy with the service, cost and responsiveness of the team.  We will continue to support VPM for the foreseeable future and encourage others to check them out.
                                </h2>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>39 Spices</strong> &nbsp; <a href='https://www.google.com/search?q=vtacpayroll&hl=en-US&authuser=1&sxsrf=APwXEdcMlBmnPi8Z6V12HlVQkZuul91Hcg%3A1680252744855&ei=SJ8mZOjYM_bx4-EP65yU8AM&ved=0ahUKEwjonPWf5YX-AhX2-DgGHWsOBT4Q4dUDCA8&uact=5&oq=vtacpayroll&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIECCMQJ0oECEEYAVCZCliMDWCDE2gBcAB4AIABkgGIAZYCkgEDMC4ymAEAoAEBwAEB&sclient=gws-wiz-serp#lrd=0x882b34ccd9525551:0x8d77ce9b2c3ffd22,1' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    I have used VPM's services for over 3 years. They have been amazing the entire time. They assign a payroll consultant to your account so you always have a point of contact, this made things a lot easier as the consultant knew my account and could help me with any issues I had. Exceptional customer service!
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Shakil Abdus</strong> &nbsp; <a href='https://www.google.com/search?q=vtacpayroll&hl=en-US&authuser=1&sxsrf=APwXEdcMlBmnPi8Z6V12HlVQkZuul91Hcg%3A1680252744855&ei=SJ8mZOjYM_bx4-EP65yU8AM&ved=0ahUKEwjonPWf5YX-AhX2-DgGHWsOBT4Q4dUDCA8&uact=5&oq=vtacpayroll&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIECCMQJ0oECEEYAVCZCliMDWCDE2gBcAB4AIABkgGIAZYCkgEDMC4ymAEAoAEBwAEB&sclient=gws-wiz-serp#lrd=0x882b34ccd9525551:0x8d77ce9b2c3ffd22,1' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    We have been with VPM for over 15 Years and I couldn't be more happier with their service. our Pay slips and deposits are always submitted in a very timely manner. I want to especially thank Noor and Nazim for all your help and support.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Sonia Vargas</strong> &nbsp; <a href='https://www.google.com/search?q=vtacpayroll&hl=en-US&authuser=1&sxsrf=APwXEdcMlBmnPi8Z6V12HlVQkZuul91Hcg%3A1680252744855&ei=SJ8mZOjYM_bx4-EP65yU8AM&ved=0ahUKEwjonPWf5YX-AhX2-DgGHWsOBT4Q4dUDCA8&uact=5&oq=vtacpayroll&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIECCMQJ0oECEEYAVCZCliMDWCDE2gBcAB4AIABkgGIAZYCkgEDMC4ymAEAoAEBwAEB&sclient=gws-wiz-serp#lrd=0x882b34ccd9525551:0x8d77ce9b2c3ffd22,1' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    VPM has been serving as our Payroll Management Team over the last two years. To date, they have been efficient, professional, and productive in their service to us here at Eclipse Security Services Inc. The team is reliable, resourceful and, very accommodating.
                                </h2>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>E LS</strong> &nbsp; <a href='https://www.google.com/search?q=vtacpayroll&hl=en-US&authuser=1&sxsrf=APwXEdcMlBmnPi8Z6V12HlVQkZuul91Hcg%3A1680252744855&ei=SJ8mZOjYM_bx4-EP65yU8AM&ved=0ahUKEwjonPWf5YX-AhX2-DgGHWsOBT4Q4dUDCA8&uact=5&oq=vtacpayroll&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIECCMQJ0oECEEYAVCZCliMDWCDE2gBcAB4AIABkgGIAZYCkgEDMC4ymAEAoAEBwAEB&sclient=gws-wiz-serp#lrd=0x882b34ccd9525551:0x8d77ce9b2c3ffd22,1' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    I have been using VPM for 11 months now and they are excellent. They save me so much time. I highly recommend them to any small/mid business even with one employee because they know the updated employee guidelines.  Pricing is very good for the service they offer.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Kim Kendall</strong> &nbsp; <a href='https://www.google.com/search?q=vtacpayroll&hl=en-US&authuser=1&sxsrf=APwXEdcMlBmnPi8Z6V12HlVQkZuul91Hcg%3A1680252744855&ei=SJ8mZOjYM_bx4-EP65yU8AM&ved=0ahUKEwjonPWf5YX-AhX2-DgGHWsOBT4Q4dUDCA8&uact=5&oq=vtacpayroll&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIECCMQJ0oECEEYAVCZCliMDWCDE2gBcAB4AIABkgGIAZYCkgEDMC4ymAEAoAEBwAEB&sclient=gws-wiz-serp#lrd=0x882b34ccd9525551:0x8d77ce9b2c3ffd22,1' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    VPM has been our payroll provider for many years.  They are prompt and always keep us informed of anything we need to know especially with this pandemic.  I would highly recommend them!
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Jim Liu</strong> &nbsp; <a href='https://www.google.com/search?q=vtacpayroll&hl=en-US&authuser=1&sxsrf=APwXEdcMlBmnPi8Z6V12HlVQkZuul91Hcg%3A1680252744855&ei=SJ8mZOjYM_bx4-EP65yU8AM&ved=0ahUKEwjonPWf5YX-AhX2-DgGHWsOBT4Q4dUDCA8&uact=5&oq=vtacpayroll&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIECCMQJ0oECEEYAVCZCliMDWCDE2gBcAB4AIABkgGIAZYCkgEDMC4ymAEAoAEBwAEB&sclient=gws-wiz-serp#lrd=0x882b34ccd9525551:0x8d77ce9b2c3ffd22,1' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    V-Tac Payroll Management has been my payroll provider since 2007, the management team and staff are extremely efficient and professional. On some occasions when I make a mistake or two, they are quick to respond to my need to correct. Thank you Nazim & Nur for your excellent service.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Space Age Closets</strong> &nbsp; <a href='https://www.google.com/search?q=vtacpayroll&hl=en-US&authuser=1&sxsrf=APwXEdcMlBmnPi8Z6V12HlVQkZuul91Hcg%3A1680252744855&ei=SJ8mZOjYM_bx4-EP65yU8AM&ved=0ahUKEwjonPWf5YX-AhX2-DgGHWsOBT4Q4dUDCA8&uact=5&oq=vtacpayroll&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIECCMQJ0oECEEYAVCZCliMDWCDE2gBcAB4AIABkgGIAZYCkgEDMC4ymAEAoAEBwAEB&sclient=gws-wiz-serp#lrd=0x882b34ccd9525551:0x8d77ce9b2c3ffd22,1' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    After eight years of worry-free service, I'm more than happy with them and always recommend them to my fellow business owners.  Manuel Ventura, President of Johnorma Enterprises.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Asif Khan</strong> &nbsp; <a href='https://www.google.com/search?q=vtacpayroll&hl=en-US&authuser=1&sxsrf=APwXEdcMlBmnPi8Z6V12HlVQkZuul91Hcg%3A1680252744855&ei=SJ8mZOjYM_bx4-EP65yU8AM&ved=0ahUKEwjonPWf5YX-AhX2-DgGHWsOBT4Q4dUDCA8&uact=5&oq=vtacpayroll&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIECCMQJ0oECEEYAVCZCliMDWCDE2gBcAB4AIABkgGIAZYCkgEDMC4ymAEAoAEBwAEB&sclient=gws-wiz-serp#lrd=0x882b34ccd9525551:0x8d77ce9b2c3ffd22,1' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Excellent company. VPM cares about their clients and provides industry leading service. I recommend VPM.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Maria Guion</strong> &nbsp; <a href='https://www.google.com/search?q=vtacpayroll&hl=en-US&authuser=1&sxsrf=APwXEdcMlBmnPi8Z6V12HlVQkZuul91Hcg%3A1680252744855&ei=SJ8mZOjYM_bx4-EP65yU8AM&ved=0ahUKEwjonPWf5YX-AhX2-DgGHWsOBT4Q4dUDCA8&uact=5&oq=vtacpayroll&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIECCMQJ0oECEEYAVCZCliMDWCDE2gBcAB4AIABkgGIAZYCkgEDMC4ymAEAoAEBwAEB&sclient=gws-wiz-serp#lrd=0x882b34ccd9525551:0x8d77ce9b2c3ffd22,1' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Vtac has quality and quantity service to our company. Very efficient staff and owner is always on top of the service delivered on time.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Tanveer Satter</strong> &nbsp; <a href='https://www.google.com/search?q=vtacpayroll&hl=en-US&authuser=1&sxsrf=APwXEdcMlBmnPi8Z6V12HlVQkZuul91Hcg%3A1680252744855&ei=SJ8mZOjYM_bx4-EP65yU8AM&ved=0ahUKEwjonPWf5YX-AhX2-DgGHWsOBT4Q4dUDCA8&uact=5&oq=vtacpayroll&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIECCMQJ0oECEEYAVCZCliMDWCDE2gBcAB4AIABkgGIAZYCkgEDMC4ymAEAoAEBwAEB&sclient=gws-wiz-serp#lrd=0x882b34ccd9525551:0x8d77ce9b2c3ffd22,1' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    VPM payroll service is honestly the best payroll service we have ever used. would highly recommend to anyone !
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Jordon Hansen</strong> &nbsp; <a href='https://www.google.com/search?q=vtacpayroll&hl=en-US&authuser=1&sxsrf=APwXEdcMlBmnPi8Z6V12HlVQkZuul91Hcg%3A1680252744855&ei=SJ8mZOjYM_bx4-EP65yU8AM&ved=0ahUKEwjonPWf5YX-AhX2-DgGHWsOBT4Q4dUDCA8&uact=5&oq=vtacpayroll&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIECCMQJ0oECEEYAVCZCliMDWCDE2gBcAB4AIABkgGIAZYCkgEDMC4ymAEAoAEBwAEB&sclient=gws-wiz-serp#lrd=0x882b34ccd9525551:0x8d77ce9b2c3ffd22,1' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Excellent customer service, my direct deposit is always on time. Their online system is very user friendly. Fantastic service!!
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Andrew Yegorovykh</strong> &nbsp; <a href='https://www.google.com/search?q=vtacpayroll&hl=en-US&authuser=1&sxsrf=APwXEdcMlBmnPi8Z6V12HlVQkZuul91Hcg%3A1680252744855&ei=SJ8mZOjYM_bx4-EP65yU8AM&ved=0ahUKEwjonPWf5YX-AhX2-DgGHWsOBT4Q4dUDCA8&uact=5&oq=vtacpayroll&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIECCMQJ0oECEEYAVCZCliMDWCDE2gBcAB4AIABkgGIAZYCkgEDMC4ymAEAoAEBwAEB&sclient=gws-wiz-serp#lrd=0x882b34ccd9525551:0x8d77ce9b2c3ffd22,1' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Flawless and efficient service so far with VPM.
                                    Our payroll for 6 people is flowing smooth.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Enver Coovadia</strong> &nbsp; <a href='https://www.google.com/search?q=vtacpayroll&hl=en-US&authuser=1&sxsrf=APwXEdcMlBmnPi8Z6V12HlVQkZuul91Hcg%3A1680252744855&ei=SJ8mZOjYM_bx4-EP65yU8AM&ved=0ahUKEwjonPWf5YX-AhX2-DgGHWsOBT4Q4dUDCA8&uact=5&oq=vtacpayroll&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIECCMQJ0oECEEYAVCZCliMDWCDE2gBcAB4AIABkgGIAZYCkgEDMC4ymAEAoAEBwAEB&sclient=gws-wiz-serp#lrd=0x882b34ccd9525551:0x8d77ce9b2c3ffd22,1' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Vtac payroll has helped my company navigate through these tough times regarding the CERB benefits and programs.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Steve :: Altamira Maintenance</strong> &nbsp; <a href='https://www.google.com/search?q=vtacpayroll&hl=en-US&authuser=1&sxsrf=APwXEdcMlBmnPi8Z6V12HlVQkZuul91Hcg%3A1680252744855&ei=SJ8mZOjYM_bx4-EP65yU8AM&ved=0ahUKEwjonPWf5YX-AhX2-DgGHWsOBT4Q4dUDCA8&uact=5&oq=vtacpayroll&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIECCMQJ0oECEEYAVCZCliMDWCDE2gBcAB4AIABkgGIAZYCkgEDMC4ymAEAoAEBwAEB&sclient=gws-wiz-serp#lrd=0x882b34ccd9525551:0x8d77ce9b2c3ffd22,1' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Excellent payroll service , we have been customer of theirs for 15 years .
                                    Knowledgeable payroll team
                                </h2>
                            </div>
                        </Col>
                    </Row>


                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Jim K S Liu</strong> &nbsp; <a href='https://www.facebook.com/vtacpayroll/reviews/?ref=page_internal' target="_blank" style={{textDecoration: "none" }}> <FontAwesomeIcon className="brandicon" icon={faFacebook} /></a></p>
                                <p className='TestLtd'><img className="staricon" src={fbstar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    We have been with VTAC for over 12 years and we are 100% satisfied.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>George Adamidis</strong> &nbsp; <a href='https://www.facebook.com/vtacpayroll/reviews/?ref=page_internal' target="_blank" style={{ textDecoration: "none" }}> <FontAwesomeIcon className="brandicon" icon={faFacebook} /></a></p>
                                <p className='TestLtd'><img className="staricon" src={fbstar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Professional service, responsive to my questions and a great rate.  Highly recommended.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Funmi Foluke Ola</strong> &nbsp; <a href='https://www.facebook.com/vtacpayroll/reviews/?ref=page_internal' target="_blank" style={{ textDecoration: "none" }}> <FontAwesomeIcon className="brandicon" icon={faFacebook} /></a></p>
                                <p className='TestLtd'><img className="staricon" src={fbstar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Great working with VTAC. They are very fast to answer request. Thanks
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Lucy Chiappetta</strong> &nbsp; <a href='https://www.facebook.com/vtacpayroll/reviews/?ref=page_internal' target="_blank" style={{ textDecoration: "none" }}> <FontAwesomeIcon className="brandicon" icon={faFacebook} /></a></p>
                                <p className='TestLtd'><img className="staricon" src={fbstar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Very simple and easy to set up with pleasant customer service.  It is more cost efficient to use VPM rather than the time required to do your own payroll!  I would recommend.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Shehan De Silva</strong> &nbsp; <a href='https://www.facebook.com/vtacpayroll/reviews/?ref=page_internal' target="_blank" style={{ textDecoration: "none" }}> <FontAwesomeIcon className="brandicon" icon={faFacebook} /></a></p>
                                <p className='TestLtd'><img className="staricon" src={fbstar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Lost Craft has had a great experience. Great company. Excellent customer service. Highly recommend, Nabil is a great rep.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Waseem Aslam</strong> &nbsp; <a href='https://www.facebook.com/vtacpayroll/reviews/?ref=page_internal' target="_blank" style={{ textDecoration: "none" }}> <FontAwesomeIcon className="brandicon" icon={faFacebook} /></a></p>
                                <p className='TestLtd'><img className="staricon" src={fbstar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Fast and accurate payroll processing. Excellent customer service.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Melissa Palleschi</strong> &nbsp; <a href='https://www.facebook.com/vtacpayroll/reviews/?ref=page_internal' target="_blank" style={{ textDecoration: "none" }}> <FontAwesomeIcon className="brandicon" icon={faFacebook} /></a></p>
                                <p className='TestLtd'><img className="staricon" src={fbstar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Their team is polite, processing changes is quick and easy. They check in frequently and assist with respect and courteous.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>John Smith</strong> &nbsp; <a href='https://www.facebook.com/vtacpayroll/reviews/?ref=page_internal' target="_blank" style={{ textDecoration: "none" }}> <FontAwesomeIcon className="brandicon" icon={faFacebook} /></a></p>
                                <p className='TestLtd'><img className="staricon" src={fbstar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Quality Driving & Motorcycle School is extremely happy with VTAC Payroll Management and their high level of service they provide. Nabil is always helpful and available to answer any questions.  We appreciate their live support and payroll reminders.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Audrey Arisman</strong> &nbsp; <a href='https://www.facebook.com/vtacpayroll/reviews/?ref=page_internal' target="_blank" style={{ textDecoration: "none" }}> <FontAwesomeIcon className="brandicon" icon={faFacebook} /></a></p>
                                <p className='TestLtd'><img className="staricon" src={fbstar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    VTAC has easy processes and helpful staff. Their reporting is complete and pricing very reasonable for our small organization. And they send submission reminders!!
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Sarigat M Oves</strong> &nbsp; <a href='https://www.facebook.com/vtacpayroll/reviews/?ref=page_internal' target="_blank" style={{ textDecoration: "none" }}> <FontAwesomeIcon className="brandicon" icon={faFacebook} /></a></p>
                                <p className='TestLtd'><img className="staricon" src={fbstar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    quick response time.
                                    always co operative staff.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Evan Zaretsky </strong> &nbsp; <a href='https://www.facebook.com/vtacpayroll/reviews/?ref=page_internal' target="_blank" style={{ textDecoration: "none" }}> <FontAwesomeIcon className="brandicon" icon={faFacebook} /></a></p>
                                <p className='TestLtd'><img className="staricon" src={fbstar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    VTAC has simplified one of the more frustrating parts of runnning a business. Easy to work with and great rates,
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Glenda Gibbons-Reid</strong> &nbsp; <a href='https://www.facebook.com/vtacpayroll/reviews/?ref=page_internal' target="_blank" style={{ textDecoration: "none" }}> <FontAwesomeIcon className="brandicon" icon={faFacebook} /></a></p>
                                <p className='TestLtd'><img className="staricon" src={fbstar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    VTAC is very informative, friendly, quick and efficient. I really appreciate the bi-weekly reminders.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default GoogleReview;