import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import expect from '../../asset/images/expect.PNG';
import setup from '../../asset/images/setup.PNG';

class MakingTheSwitch48Hours extends Component {
    render() {
        return (
            <Fragment>
                <Container className='SectionBetween'>
                    <div>
                        <h2 className='mainTitle'>UP & RUNNING WITHIN <strong className='spancolor'> 48 HOURS*</strong> </h2>
                        <Link to="/promotional-conditions"> <p className=' quoteCondition text-center'>*some conditions apply</p></Link>
                        <Container>
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <div className='text-center'>  <img className="expectImg" alt='expect' src={expect} /></div>
                                    <h2 className='commonTitle mt-3 text-center'>What to expect</h2>
                                    <p className='commonDesc text-center'>Once a price quote has been requested, received and accepted, your dedicated account manager will walk you through the onboarding process outlined below. </p>
                                    <p className='commonDesc text-center' >There are no setup fees for any work associated with joining VPM. It’s completely free for you.</p>
                                </Col>

                                <Col lg={6} md={6} sm={12}>
                                    <div className='text-center'>  <img className="expectImg" src={setup} alt='YTD payroll' /></div>
                                    <h2 className='commonTitle mt-3 text-center'>Get a free full YTD payroll update upon setup</h2>
                                    <p className='commonDesc text-center'>Simplify your year-end process with a free full year-to-date payroll update for each active employee in our system, coupled with a full reconciliation of these records with comparable client YTD reporting.</p>
                                    <p className='commonDesc text-center'>Have access to all payroll reports, such as T4’s, from one updated system.</p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </Fragment>
        );
    }
}

export default MakingTheSwitch48Hours;