import React, { Component, Fragment } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import mollymaidlogo from '../../asset/images/mollymaid-logo.png';
import sourcelogo from '../../asset/images/source-logo.png';
import ablestaffing from '../../asset/images/able-staffing.png';
import nexusstaffing from '../../asset/images/nexus-staffing.png';
import genovea from '../../asset/images/genovea.png';
import mbaservices from '../../asset/images/mba-services.png';
import hiresourcestaffing from '../../asset/images/hire-source-staffing.png';
import stepinstaffing from '../../asset/images/step-in-staffing.png';
import yourpersonnel from '../../asset/images/your-personnel.png';
import srstaffing from '../../asset/images/sr-staffing.png';
import wecare from '../../asset/images/we-care.png';
import eclipse from '../../asset/images/eclipse.png';


class EmploymentAgencySlide extends Component {
    render() {
        var settings = {
            dots: false,
            arrows: true,
            infinite: true,
            speed: 3000,
            slidesToShow: 6,
            slidesToScroll: 3,
            autoplay: true,
            autoplaySpeed: 2000,


            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <Fragment>

                <Container className="slideShowSection SectionBetween">
                    <h2 className='mainTitle text-center mb-5'><strong className='spancolor'>Trusted</strong> BY BUSINESSES LIKE YOURS</h2>
                    <Slider {...settings}>

                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={sourcelogo} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={ablestaffing} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={mollymaidlogo} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={nexusstaffing} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={genovea} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={mbaservices} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={hiresourcestaffing} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={stepinstaffing} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={yourpersonnel} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={srstaffing} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={wecare} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={eclipse} /></a>
                                </Col>
                            </Row>
                        </div>
                    </Slider>


                </Container>
            </Fragment>
        );
    }
}

export default EmploymentAgencySlide;