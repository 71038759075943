import React, { Fragment } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import pentionplanscaled from "../../asset/images/pention-plan-scaled.jpg";
import { Link } from 'react-router-dom';

const Blog4 = () => {
    return (
        <Fragment>
            <Container className='InsdustryNewSce'>
                <Row>
                    <Col lg={12} md={12} sm={12}>
                        <div>
                            <img className="insNewsImg" src={pentionplanscaled} alt='understanding-the-canadian-pension-plan' />
                            <h2 className='insNewsTitle'>Understanding the Canadian Pension Plan: A Complete Guide</h2>
                            <p className='insNewsdetailsDesc'>
                                The Canadian Pension Plan (CPP) is a crucial social insurance program that provides financial support to Canadians during their retirement years. It's important to understand how the CPP works, what benefits it offers, and how to apply. In this blog post, we'll provide a comprehensive guide to the CPP, including eligibility requirements, benefit amounts, and the application process.
                            </p>
                            <p className='insNewssubTitle2' >What is the Canadian Pension Plan?</p>
                            <p className='insNewsdetailsDesc'>
                                The CPP is a government-run pension plan that provides income to Canadians during their retirement years. Funded by contributions from Canadian workers, their employers, and the federal government, the CPP also offers benefits to disabled Canadians and surviving spouses and children.
                            </p>
                            <p className='insNewssubTitle2' >Eligibility for CPP Benefits</p>
                            <p className='insNewsdetailsDesc'>
                                To be eligible for CPP benefits, you must have made contributions to the CPP during your working years. In general, you must have worked and made contributions to the CPP for at least one-third of the years between the age of 18 and the year you turn 65. The CPP also offers disability benefits for those who are unable to work due to a disability, as well as survivor benefits for the surviving spouse and children of a deceased CPP contributor.
                            </p>
                            <p className='insNewssubTitle2' >CPP Benefit Amounts</p>
                            <p className='insNewsdetailsDesc'>
                                The amount of CPP benefits you receive depends on several factors, including your contributions to the plan and the age at which you start receiving benefits. The standard CPP retirement pension is calculated as 25% of your average earnings over your working years, up to a maximum amount set each year by the government. For 2021, the maximum monthly CPP retirement pension is $1,203.75.
                            </p>
                            <p className='insNewssubTitle2' >How to Apply for CPP Benefits?</p>
                            <p className='insNewsdetailsDesc'>
                                To apply for CPP benefits, you can do so online, by mail, or in person at a Service Canada office. You will need to provide personal information, including your Social Insurance Number, as well as information about your work history and contributions to the CPP. The application process can take several months, so it's important to apply as early as possible to avoid delays.
                            </p>
                            <p className='insNewssubTitle2' >Conclusion</p>
                            <p className='insNewsdetailsDesc'>
                                The Canadian Pension Plan is a crucial part of retirement income for many Canadians. By understanding how the CPP works, what benefits it offers, and how to apply, you can maximize your retirement income. We hope this guide has provided you with valuable information that can help you navigate the CPP system with ease.
                            </p>
                            <Link to="/industry-news"><Button onClick={window.scrollTo({ top: 0, behavior: 'smooth' })} variant="primary" className='view-All-Post mt-2'><span>VIEW ALL POST</span></Button>{' '}</Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default Blog4;