import React, { Component, Fragment } from 'react';
import TopHead from '../components/TopHead/TopHead';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import QuoteComponentCyan from '../components/QuoteComponentCyan/QuoteComponentCyan';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import InsuranceAgentBanner from '../components/InsuranceAgentBanner/InsuranceAgentBanner';
import InsAgentSec1 from '../components/InsAgentSec1/InsAgentSec1';
import InsAgentFeedback1 from '../components/InsAgentFeedback1/InsAgentFeedback1';
import InsAgentSec2 from '../components/InsAgentSec2/InsAgentSec2';
import InsAgentFeedback2 from '../components/InsAgentFeedback2/InsAgentFeedback2';

class InsuranceAgentPage extends Component {
    render() {
        return (
            <Fragment>
                <TopHead />
                <TopNavigation title="Asif Khan Insurance | V-TAC Payroll Management" />
                <InsuranceAgentBanner />
                <InsAgentSec1 />
                <InsAgentFeedback1 />
                <InsAgentSec2 />
                <InsAgentFeedback2 />
                <QuoteComponentCyan />
                <TradePart />
                <Footer />
            </Fragment>
        );
    }
}

export default InsuranceAgentPage;