import React, { Component, Fragment } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import teriyaki from '../../asset/images/teriyaki.png';
import topharbilliards from '../../asset/images/top-har-billiards.png';
import sammys from '../../asset/images/sammys.png';
import sandhudentistry from '../../asset/images/sandhu-dentistry.png';
import mammaspizza from '../../asset/images/mammas-pizza.png';

import ozzys from '../../asset/images/ozzys.png';
import theberlin from '../../asset/images/the-berlin.png';
import wimpyslogo from '../../asset/images/wimpys-logo.png';
import subwaylogo from '../../asset/images/subway-logo.png';
import pizzhutlogo from '../../asset/images/pizzhut-logo.png';
import mollymaidlogo from '../../asset/images/mollymaid-logo.png';
import ignitelogo from '../../asset/images/ignite-logo.png';
import kfclogo from '../../asset/images/kfc-logo.png';
import freshiilogo from '../../asset/images/freshii-logo.png';
import herologo from '../../asset/images/hero-logo.png';
import apglogo from '../../asset/images/apg-logo.png';
import bestwesternlogo from '../../asset/images/bestwestern-logo.png';
import fourseams from '../../asset/images/fourseams.png';
import gomticanada from '../../asset/images/gomti-canada.png';
import ohsogood from '../../asset/images/oh-so-good.png';
import thehideout from '../../asset/images/the-hideout.png';

class HospitalityIndustrySlide extends Component {
    render() {
        var settings = {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 3000,
            slidesToShow: 6,
            slidesToScroll: 3,
            autoplay: true,
            autoplaySpeed: 2000,


            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 6,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <Fragment>

                <Container className="slideShowSection SectionBetween">
                    <h2 className='mainTitle text-center mb-5'><strong className='spancolor'>Trusted</strong> By BUSINESSES LIKE YOURS</h2>
                    <Slider {...settings}>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={teriyaki} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={topharbilliards} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={sandhudentistry} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={sammys} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={mammaspizza} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={ozzys} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={theberlin} /></a>
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={wimpyslogo} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={subwaylogo} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={pizzhutlogo} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={mollymaidlogo} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={ignitelogo} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={kfclogo} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={freshiilogo} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={herologo} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={apglogo} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={bestwesternlogo} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={fourseams} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={gomticanada} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={ohsogood} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={thehideout} /></a>
                                </Col>
                            </Row>
                        </div>

                    </Slider>


                </Container>
            </Fragment>
        );
    }
}

export default HospitalityIndustrySlide;