import React, { Component, Fragment } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import vpminterface from '../../asset/images/vpm-interface.jpg';
import { Link } from "react-router-dom";

class SwitchToVpm extends Component {
    render() {
        return (
            <Fragment>
                <Container className='SectionBetween'>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div style={{ width: "80%" }} className=" container text-center mt-3">
                                <h2 className='mainTitle'>Experienced & Professional <span className='spancolor'>Certified</span> Payroll Specialists</h2>
                            </div>
                            <div className='container' style={{ width: "90%" }}> <p className='switchdescription text-center'>VPM takes the headache out of payroll. Our focus is to take care of our clients’ payroll services by paying close attention to the finite details including all the relevant laws.</p></div>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col lg={6} md={6} sm={12}>
                            <div>
                                <h2 className='switchSubTitle'>Switch your payroll to<strong style={{ color: "#E6001F" }}> VPM</strong></h2>
                                <p className='commonDesc'>Switching to VPM will not interrupt your payroll schedule. We provide a simple, hassle and stress-free experience when starting up your payroll services with our company.</p>
                                <p className='commonDesc'>Your customized payroll solution will be setup within 48 hours or less. If you ever experience any issues, our team will assist you in under 30 minutes guaranteed – we don’t transfer you between help desks, get the help you need right away.</p>

                                <Link to="/request-payroll-quote"><Button className='quotebutton' onClick={() => {
                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                }} data-aos="fade-down"
                                    data-aos-easing="linear"
                                    data-aos-duration="1500"
                                    data-aos-once="true"><span>GET A QUOTE</span></Button></Link>
                            </div>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                            <img className="vpminterfaceImg " alt="vpm-interface" title='vpm-interface' src={vpminterface} loading="lazy" />
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default SwitchToVpm;