import React, { Component, Fragment } from 'react';
import AnualSummary from '../components/AnualSummary/AnualSummary';
import Client from '../components/Client/Client';
import Footer from '../components/Footer/Footer';
import HomeQuoteCard from '../components/HomeQuoteCard/HomeQuoteCard';
import JoinNow from '../components/JoinNow/JoinNow';
import NewsEventsInsights from '../components/NewsEventsInsights/NewsEventsInsights';
import OurSotution from '../components/OurSotution/OurSotution';
import SwitchToVpm from '../components/SwitchToVpm/SwitchToVpm';
import TopBanner from '../components/TopBanner/TopBanner';
import TopHead from '../components/TopHead/TopHead';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import TrustedClient from '../components/TrustedClient/TrustedClient';
import WhySwitchVpm from '../components/WhySwitchVpm/WhySwitchVpm';



export default class HomePage extends Component {
    render() {

        return (
            <Fragment>
                <TopHead />
                <TopNavigation title="Home | V-TAC Payroll Management " />
                {/* <div style={{clear:'both'}}></div> */}
                <TopBanner />
                <HomeQuoteCard />
                <OurSotution />
                <AnualSummary />
                <SwitchToVpm />
                <JoinNow />
                <WhySwitchVpm />
                <TrustedClient />
                <Client />
                <NewsEventsInsights />
                <TradePart />
                <Footer />
            </Fragment>
        )
    }
}
