import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import PayrollFrequencyscaled from "../../asset/images/Payroll-Frequency-scaled.jpg";
import TimeTheftscaled from "../../asset/images/Time-Theft-scaled.jpg";
import Outsourcepayrollscaled from "../../asset/images/Outsource-payroll-scaled.jpg";
import workingfromhometaxesscaled from "../../asset/images/working-from-home-taxes-scaled.jpg";
import EmploymentInsurancescaled from "../../asset/images/Employment-Insurance-scaled.jpg";
import Taxablebenefitscppscaled from "../../asset/images/Taxable-benefits-cpp-scaled.jpg";
import womanworkingondesktopScale from "../../asset/images/woman-working-on-desktopScale.jpg";
import workplacemeetingscaled from "../../asset/images/workplace-meeting-scaled.jpg";
import blocksScaled from "../../asset/images/blocksScaled.jpg";
import waitressScaled from "../../asset/images/waitressScaled.jpg";
import chef from "../../asset/images/chef.jpg";
import hotelreception from "../../asset/images/hotel-reception.jpg";
import comparingnotes from "../../asset/images/comparing-notes.jpg";
import officeteamwithmasksScaled from "../../asset/images/office-team-with-masksScaled.jpg";
import changes1 from "../../asset/images/changes-1.jpg";
import changes2 from "../../asset/images/changes-2.jpg";
import changes3 from "../../asset/images/changes-3.jpg";
import documentsScaled from "../../asset/images/documentsScaled.jpg";
import compliance2 from "../../asset/images/compliance-2.jpg";
import compliance1 from "../../asset/images/compliance-1.jpg";
import compliance3 from "../../asset/images/compliance-3.jpg";
import personwearingmaskScaled from "../../asset/images/person-wearing-maskScaled.jpg";
import sickdays1 from "../../asset/images/sick-days1.jpg";
import sickdays2 from "../../asset/images/sick-days2.jpg";
import sickdays3 from "../../asset/images/sick-days3.jpg";
import calculatorScaled from "../../asset/images/calculatorScaled.jpg";
import payrollcompliance1 from "../../asset/images/payroll-compliance1.jpg";
import payrollcompliance2 from "../../asset/images/payroll-compliance2.jpg";
import payrollcompliance3 from "../../asset/images/payroll-compliance3.jpg";
import momworkingfromhomeScaled from "../../asset/images/mom-working-from-homeScaled.jpg";
import advantage1 from "../../asset/images/advantage1.jpg";
import advantage2 from "../../asset/images/advantage2.jpg";
import advantage3 from "../../asset/images/advantage3.jpg";
import officediscussionScaled from "../../asset/images/office-discussionScaled.jpg";
import code1 from "../../asset/images/code1.jpg";
import code2 from "../../asset/images/code2.jpg";
import code3 from "../../asset/images/code3.jpg";
import businessmanonmobileScaled from "../../asset/images/businessman-on-mobileScaled.jpg";
import personwithmask1 from "../../asset/images/person-with-mask (1).jpg";
import hrteam1 from "../../asset/images/hr-team (1).jpg";
import outsourcing1 from "../../asset/images/outsourcing1.jpg";
import outsourcing2 from "../../asset/images/outsourcing2.jpg";
import outsourcing3 from "../../asset/images/outsourcing3.jpg";
import dentistatwork1 from "../../asset/images/dentist-at-work (1).jpg";
import vpminterface1 from "../../asset/images/vpm-interface (1).jpg";
import binders1 from "../../asset/images/binders (1).jpg";
import Covidposter from "../../asset/images/Covidposter.jpg";

class AllIndustryNews extends Component {


    render() {
        return (
            <Fragment>
                <Container fluid="true" className='accContainer' >

                    <Container className='InsdustryNewSce'>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div id='div1'>
                                    <img className="insNewsImg" src={PayrollFrequencyscaled} />
                                    <h2 className='insNewsTitle'>Which Payroll Frequency is the Most Effective?</h2>
                                    <p className='insNewsdetailsDesc'>
                                        (Article is based on information available at the time it was written: 2022-06-06)
                                    </p>
                                    <p className='insNewsdetailsDesc'>
                                        The most common payroll frequencies include weekly, bi-weekly, semi-monthly and monthly. We will go into more detail for each and outline the pros and cons and why an employer might choose one type of frequency over another for their payroll process.
                                    </p>
                                    <p className='insNewsdetailsDesc'>
                                        <strong>Weekly</strong><br></br>
                                        Payrolls that are processed weekly would have 52 or 53 pay periods in a year. Employees get a payment made each week which allows them to have funds on hand more often. Many individuals “live paycheque to paycheque” so weekly pay is appreciated by many employees. From the employer’s perspective, this is ideal if you have many employees that join and leave your organization (staffing agency, temporary work, on call etc.) as this provides the payment to new employees right away and concludes final payments to any employees who leave. The drawback to weekly payrolls includes processing time and fees. If the payroll runs from Sunday to Saturday, this leaves you with a few days at the beginning of the following week to collect and process the payroll data. Payroll companies typically charge per payroll run and weekly would be the most expensive compared to running the same payroll at a different frequency.
                                    </p>
                                    <p className='insNewsdetailsDesc'>
                                        <strong>Bi-weekly</strong><br></br>
                                        This is similar to weekly but includes half the periods (26 or 27) in a year. Bi-weekly is the most common frequency used in Canada as it provides a middle ground between weekly and monthly. Employees get paid frequently and less payrolls are completed in the year. This frequency is ideal for both hourly and salaried employees because of the lead time available between the end of the pay period and the pay date. Using bi-weekly instead of weekly, employers could potentially save half their payroll processing fees.
                                    </p>
                                    <p className='insNewsdetailsDesc'>
                                        <strong>Semi-Monthly</strong><br></br>
                                        This is in the middle ground alongside bi-weekly. This cycle is similar to bi-weekly but the pay period is always the same being split in two for a month, totalling 24 pay periods a year. The first half of the month and the second half are the most common semi-monthly payroll dates, with the pay dates being the 15th and end of the month. The main differences between semi-monthly and bi-weekly are the number of payrolls in the year and the lead time between the end of the payroll and the pay date. This makes semi-monthly more attractive to static payrolls such as those that have salaried employees but not as great if you have hourly employees.
                                    </p>
                                    <p className='insNewsdetailsDesc'>
                                        <strong>Monthly</strong><br></br>
                                        This is the opposite to weekly. Employees don’t get paid as often and this may cause them issues as they need to budget for the entire month. This typically saves the employer the most in terms of payroll processing fees. It is ideal for salaried, not ideal for hourly employees. <br></br>
                                    </p>
                                    <p className='insNewsdetailsDesc'>The consensus seems to be that the higher frequency payrolls tend to be preferred by employees and the lower frequency ones are preferred by the employer. Running payroll online allows a smoother operation regardless of frequency and the pay date itself make a difference as well but it ultimately depends on the goals and structure of the business as to which is most efficient. </p>
                                    <Link to="/industry-news"><Button id='b1' variant="primary" className='view-All-Post mt-2'><span>VIEW ALL POST</span></Button>{' '}</Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <Container className='InsdustryNewSce'>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div id='div2'>
                                    <img className="insNewsImg" src={TimeTheftscaled} />
                                    <h2 className='insNewsTitle'>How to Mitigate Time Theft</h2>
                                    <p className='insNewsdetailsDesc'>
                                        (Article is based on information available at the time it was written: 2022-05-24)
                                    </p>
                                    <p className='insNewsdetailsDesc'>
                                        Time Theft occurs when an employee gets paid for hours that were not necessarily earned/worked. The payroll process does not catch all the discrepancies of hours reported. This can range from unintentional mistakes to theft with malicious intent. There are many examples of time theft and some types may be unavoidable, while others can be mitigated. Examples include forgetting to punch out for lunch, taking longer breaks than provided and not producing work during working hours (employees who work from home but are not attentive during work hours). Businesses need to be vigilant in tracking employee’s hours, be it from home or in office. Payroll companies generally process the information that is provided to them, to reduce time theft, the information needs to be screened prior to completing the payroll process.
                                    </p>
                                    <h3 className='insNewssubTitle'>Ways to reduce time theft</h3>
                                    <p className='insNewsdetailsDesc'>
                                        The first step to mitigate time theft is to set a policy in place that everyone is aware of. This should provide clear guidelines and establish what is and is not acceptable. Employees who do not follow the guidelines should face repercussions, as you should not allow behaviour that is unacceptable to continue. This also allows you to uncover any opportunities that would be available to exploit. For example, for in-office work, many businesses use a punch in system (online based or manual/in person). Employees can alter the times they clock in/out, not punch out for breaks and even round uptime. While this can be tricky to determine, it’s best that everyone understands what is expected of them.

                                    </p>
                                    <p className='insNewsdetailsDesc'>
                                        Having the payroll online can present opportunities to crack down on time theft. If employees self-report hours through a system, a manager usually needs to review and approve the hours. This is a great first line of defense solution but this process itself can be automated. Payroll services can include options for tracking hours that provide managers with insights into suspicious activity, for example, unreasonable overtime being reported or breaks not being clocked in. Another form of time theft can be a waste of time at work such as browsing on the internet. The ways to mitigate this could be to have certain sites blocked on work properties (laptops, tablets etc.).
                                    </p>
                                    <p className='insNewsdetailsDesc'>
                                        Instead of having a time-based indicator, a performance-based indicator may also prove to be a great deterrence from time theft. Knowing how long certain tasks should take (granted nothing out of the ordinary happens) can help determine the productivity level of employees. If the daily tasks of an employee take 7 hours, and they work 8 hours a day, they should complete the daily tasks each day with nothing extending to the next day. If you find that employees are not completing their tasks with ample time then this may indicate that they are not using the work hours appropriately. It is important to note that there should be a fair assessment of the time to completion for tasks.

                                    </p>
                                    <p className='insNewsdetailsDesc'>
                                        There are many ways that time theft occurs and it may not be possible to eliminate it entirely. The next best is to mitigate the extent to which it happens. Not all businesses face the same issues so the solution(s) will vary from business to business.
                                    </p>

                                    <Link to="/industry-news"><Button variant="primary" className='view-All-Post mt-2'><span>VIEW ALL POST</span></Button>{' '}</Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <Container className='InsdustryNewSce'>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div id='div3'>
                                    <img className="insNewsImg" src={Outsourcepayrollscaled} />
                                    <h2 className='insNewsTitle'>Why Small Businesses Outsource Payroll</h2>
                                    <p className='insNewsdetailsDesc'>
                                        (Article is based on information available at the time it was written: 2022-04-12)
                                    </p>
                                    <p className='insNewsdetailsDesc'>
                                        Completing the payroll process in-house is a viable option for many small businesses. But there comes a point when outsourcing your payroll becomes more beneficial for your business. Payroll companies offer many advantages that would save you time, money and headaches.
                                    </p>
                                    <h3 className='insNewssubTitle'>TIME</h3>
                                    <p className='insNewsdetailsDesc'>
                                        Many payroll companies offer services that allow you to complete your payroll online. This includes entries, deposits and electronic copies of reports/stubs. There’s little to no need to have paper copies of any documents as they are all available online or through a portal. Direct deposits are also completed for each employee so there is no time wasted on writing/signing cheques. Additional time-consuming services that are often overlooked include completing T4s, ROEs and employee inquiries. T4s would be provided to each employee and a copy of the T4/T4 Summary would be sent to CRA as well. Completing ROEs can be very time-consuming but payroll companies will take care of it! The source deductions are also submitted to CRA on your behalf. Employee inquiries and requests also take up valuable time, ESS (employee self-service) portals offer employees the convenience of finding any documents they need in one simple, secure location. If employees have questions you’re unable to answer, payroll service providers will be able to assist you.
                                    </p>
                                    <h3 className='insNewssubTitle'>ACCURACY</h3>
                                    <p className='insNewsdetailsDesc'>
                                        Payroll companies have many clients that have a broad variety of payroll needs. This means that payroll service providers must understand what is required of them and keep up to date with ever-changing guidelines, legislations and laws. The experience, knowledge and compliance regulations that payroll companies poses are valuable asset to have for your business.  They will ensure your reporting and payments are made within the necessary compliance thresholds. Getting assistance from qualified professionals provides you with a sense of comfort and confidence that your payroll process is being taken care of.
                                    </p>
                                    <h3 className='insNewssubTitle'>COST</h3>
                                    <p className='insNewsdetailsDesc'>
                                        One of the biggest reasons a small business may choose to keep the payroll in-house is to keep costs down. A great way to put the cost into perspective is to compare it to time and effort. Undoubtedly, it will require more time and effort to complete the entire payroll process in-house than to outsource it. With outsourcing, all you would be required to do is submit the payroll hours/updates each period. If you have a designated employee to complete the payroll, you would need to enter the hours, double-check the work, make adjustments if necessary, create/save reports, provide employees with stubs and payment, remit the source deductions, complete ROEs etc. all while ensuring you are complying to the law. The question small businesses would need to ask themselves is if the additional time, effort and energy saved outweighs the cost to outsource payroll.
                                    </p>
                                    <p className='insNewsdetailsDesc'>
                                        All of these benefits provide you with more opportunities and resources to operate and expand upon your business in a meaningful way.
                                    </p>

                                    <Link to="/industry-news"><Button variant="primary" className='view-All-Post mt-2'><span>VIEW ALL POST</span></Button>{' '}</Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <Container className='InsdustryNewSce'>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div id='div4'>
                                    <img className="insNewsImg" src={workingfromhometaxesscaled} />
                                    <h2 className='insNewsTitle'>Taxation & Expenses: Working Remotely</h2>
                                    <p className='insNewsdetailsDesc'>
                                        (Article is based on information available at the time it was written: 2022-04-02)
                                    </p>
                                    <p className='insNewsdetailsDesc'>
                                        March 2020 was a time that fundamentally changed the way many people operated on a day-to-day basis. Some of these changes became permanent and some are ever-increasing. Remote work is one of these changes. As more businesses are adopting work-from-home practices, their payroll process can become more complicated.
                                    </p>
                                    <h3 className='insNewssubTitle'>EMPLOYERS </h3>
                                    <p className='insNewsdetailsDesc'>
                                        The number of individuals working for an organization from a different province or territory has increased in recent years. A business operating in Alberta may have employees working from home who reside out of the province. This scenario is becoming more common and the payroll process must ensure that any tax implications are accounted for.
                                    </p>
                                    <p className='insNewsdetailsDesc'>
                                        Employers should deduct employees’ taxes depending on the situation they are in. If an employee resides in and works from home for an employer who has a permanent establishment in the same province/territory, that province/territory’s taxes should be used. What if the employee works from home in a province where the employer does not have a permanent establishment? If as an employer you operate and only have a permanent establishment in Ontario, but hire remote workers in British Columbia, you would use Ontario’s tax rates to process their payroll.
                                    </p>
                                    <p className='insNewsdetailsDesc'>
                                        If it is unclear whether or not an employer has a permanent establishment in a certain province/territory then you are allowed to use tax rates of the province that processed payment to the employee. For example, if the payroll process happens in Manitoba, then you would use Manitoba’s rates. This situation becomes difficult to navigate, especially in recent times as more payroll companies offer payroll services digitally and/or across multiple provinces/territories. Many employers complete their payroll online and these services may not even be completed in the country!
                                    </p>
                                    <p className='insNewsdetailsDesc'>
                                        It is also important to note that if as an employer you need to use a different province/territories rates, then you may also be obligated to follow any other reporting standards in that province. For example, if hiring in British Columbia you would also need to ensure WCB and Employer Health Tax are paid accordingly if it applies to your business.
                                    </p>
                                    <h3 className='insNewssubTitle'>EMPLOYEES</h3>
                                    <p className='insNewsdetailsDesc'>Depending on which combination of provinces/territories the employee worked in/for, they will be responsible to remit any additional taxes owing when they file their personal income tax return (they could also end up with a refund as well). Depending on eligibility, employees are able to claim certain expenses for their “home office”.</p>
                                    <p className='insNewsdetailsDesc'>The payroll process does not need to be complicated, payroll companies provide these payroll services to employers to ensure the obligations are being met accordingly.</p>
                                    <Link to="/industry-news"><Button variant="primary" className='view-All-Post mt-2'><span>VIEW ALL POST</span></Button>{' '}</Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <Container className='InsdustryNewSce'>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div id='div5'>
                                    <img className="insNewsImg" src={EmploymentInsurancescaled} />
                                    <h2 className='insNewsTitle'>Payroll Deduction: Employment Insurance (EI)</h2>
                                    <p className='insNewsdetailsDesc'>
                                        (Article is based on information available at the time it was written: 2022-03-28)
                                    </p>
                                    <h3 className='insNewssubTitle'>What is EI?</h3>
                                    <p className='insNewsdetailsDesc'>
                                        Employment Insurance (EI) is a benefit provided to individuals who have lost their employment and are able to work, but unable to find work. The loss of employment must be due to factors not deemed to be the fault of the individual. In addition to the loss of employment, Service Canada also administers benefits for maternity/paternity leave, caregiver benefits as well as sickness benefits, among others. The EI rate and structure are similar across all provinces and territories except for Quebec (the rate is different and is administered through different organizations).
                                    </p>
                                    <h3 className='insNewssubTitle'>How do I make contributions and how much should I contribute to EI?</h3>
                                    <p className='insNewsdetailsDesc'>
                                        As an employee, you do not need to take any steps to contribute. EI is a standard source deduction and the employer is required to withhold the EI from their employee’s gross earnings when completing each payroll run and remit it to CRA. For 2022, the EI rate is 1.58% and this amount is applied to the employee’s gross earnings. Employers are required to contribute 1.4x the amount of EI that the employee is contributing. For example, if an employee contributes $500 to EI, the employer must contribute 1.4x that amount which would be $700. The EI rate is applied to all insurable earnings up until a maximum of $60,300 for 2022. The maximum amount you can contribute to EI in 2022 is $952.74 ($1333.84 for the employer). To summarize, the employer or payroll service provider (on behalf of an employer) will automatically make the EI deductions/calculations and remit them to CRA.
                                    </p>
                                    <h3 className='insNewssubTitle'>Who needs to contribute to EI? </h3>
                                    <p className='insNewsdetailsDesc'>
                                        Almost any individual who receives remuneration/benefits for work has to contribute to EI. There is no age restriction for this. EI generally does not apply to owners of a business and so EI deductions are not taken off their pay. If, as an employee, you are related to the employer, you may also not be eligible to contribute/receive EI benefits (there are factors that determine this, it will not be the same for all circumstances). Certain EI benefits are also available for self-employed individuals who make contributions (not the “regular” benefits).

                                    </p>
                                    <h3 className='insNewssubTitle'>How much can I receive from the EI benefit?</h3>
                                    <p className='insNewsdetailsDesc'>
                                        The amount to be received will vary and will depend on the application that is provided to Service Canada. The maximum amount you could receive in 2022 is $638 per week. The general calculation for the benefit is to take 55% of your average insurable weekly earnings (up to a maximum). Many factors are taken into consideration in the application including insurable earnings, length of employment and the regional rate of unemployment. An ROE is issued once employment is terminated and Service Canada uses the information on the ROE to determine your earnings. ROEs are provided to Service Canada by your employer (or by a payroll service provider on behalf of the employer).
                                    </p>

                                    <Link to="/industry-news"><Button variant="primary" className='view-All-Post mt-2'><span>VIEW ALL POST</span></Button>{' '}</Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <Container className='InsdustryNewSce'>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div id='div6'>
                                    <img className="insNewsImg" src={Taxablebenefitscppscaled} />
                                    <h2 className='insNewsTitle'>Payroll Deduction: Canada Pension Plan (CPP)</h2>
                                    <p className='insNewsdetailsDesc'>
                                        (Article is based on information available at the time it was written: 2022-03-19)
                                    </p>
                                    <h3 className='insNewssubTitle'>What is CPP?</h3>
                                    <p className='insNewsdetailsDesc'>
                                        The Canada Pension Plan (CPP) is a taxable benefit that most Canadians are eligible to receive once they retire. The earliest age to start receiving the benefit is age 60. Most individuals start receiving it at age 65, some start receiving it at age 70. The CPP is administered throughout all provinces and territories in Canada except for Quebec (Quebec administers its own version called the Quebec Pension Plan –QPP).
                                    </p>
                                    <h3 className='insNewssubTitle'>How do I make contributions and how much should I contribute to CPP?</h3>
                                    <p className='insNewsdetailsDesc'>
                                        As an employee, you do not need to take any steps to contribute. CPP is a standard source deduction and the employer is required to withhold the CPP from their employee’s gross earnings when completing each payroll run and remit it to CRA. For 2022, the CPP rate is 5.70% and this amount is applied to the employee’s gross earnings. Employers are required to match the employees’ CPP contributions. For example, if an employee contributes $1,500 to CPP, the employer must also match that $1,500 contribution. The CPP rate is applied to any income earned over $3,500, up to a maximum of $64,900 for 2022. The maximum amount you can contribute to CPP in 2022 is $3,499.80. If you are self-employed you must contribute to CPP on your own and remit 11.4%. To summarize, the employer or payroll service provider (on behalf of an employer) will automatically make the CPP deductions/calculations and remit them to CRA.
                                    </p>
                                    <h3 className='insNewssubTitle'>Who needs to contribute to CPP?</h3>
                                    <p className='insNewsdetailsDesc'>

                                        While there are exceptions to who contributes to CPP, generally, any Canadian aged 18 to 65 makes the contribution through their employer/payroll service provider. You may contribute to CPP until the age of 70, after which you can no longer contribute, even if you continue earning “pensionable” income. You may elect to stop making CPP contributions at age 65 by completing the “CPT30” form and submitting it to CRA as well as to the employer/payroll service provider.
                                    </p>
                                    <h3 className='insNewssubTitle'>How much can I receive from the CPP benefit? </h3>
                                    <p className='insNewsdetailsDesc'>
                                        The amount you can expect to receive depends on a few factors including the age you start receiving the benefit, how much you contributed towards the CPP, how long you contributed to the CPP and your average earnings throughout your life. You must apply to receive this benefit as it is not administered automatically. The maximum a new recipient receiving the benefit at the age 65 in 2022 can be $1,253.59. The average amount that was paid to a new recipient at age 65 in October 2021 was $702.77. You can use the calculator in the link below to estimate how much you may receive:
                                    </p>
                                    <a>https://www.canada.ca/en/services/benefits/publicpensions/cpp/cpp-benefit/amount.html </a><br></br>

                                    <Link to="/industry-news"><Button variant="primary" className='view-All-Post mt-2'><span>VIEW ALL POST</span></Button>{' '}</Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <Container className='InsdustryNewSce'>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div id='div7'>
                                    <img className="insNewsImg" src={womanworkingondesktopScale} />
                                    <h2 className='insNewsTitle'>Income Tax For Individuals</h2>
                                    <p className='insNewsdetailsDesc'>
                                        (Article is based on information available at the time it was written: 2022-03-12)
                                    </p>
                                    <h3 className='insNewssubTitle'>What is income tax?</h3>
                                    <p className='insNewsdetailsDesc'>
                                        Income tax is a specific type of tax that is applied to the income earned by individuals across Canada. Taxes for individuals are broken down into 2 types. Federal income tax and provincial/territorial income tax. The federal income tax formula is based on a marginal tax rate, called “Progressive Taxation”. This means that everyone is taxed at the same rate for each dollar in a specific bracket. For example, in 2022 the federal tax for the first $50,197 of taxable income is taxed at 15%. The tax for every dollar above/between $50,197 to $100,392 is taxed at 20.5%. An individual who earns $40,000 annually and an individual who earns $80,000 annually pay the same 15% for the first $50,197. In this case the person making $80,000 would pay 20.5% of tax on the remaining $29,806 ($80,000 annual salary minus $50,197 15% tax bracket). Provincial/territorial income tax is similar, except the tax rate and brackets are different depending on the province/territory.
                                    </p>
                                    <h3 className='insNewssubTitle'>Why does income tax have to be paid?</h3>
                                    <p className='insNewsdetailsDesc'>
                                        In the late 1800s and early 1900s Canada’s national debt rose considerably and attempts at borrowing and raising funds could not significantly shorten the gap on the deficit. The early years of WWI put greater strain on the Canadian economy and so the “Income War Tax Act” was proposed as a temporary measure. The federal government began taxing individuals as a means to earn revenue to fund the war. From this, the “Income Tax Act” came to be and income tax became permanent. Canada had one of the lowest tax rates in the world prior to WWI.
                                    </p>
                                    <h3 className='insNewssubTitle'>Why do employers remit the income tax on behalf of employees? </h3>
                                    <p className='insNewsdetailsDesc'>
                                        Employers are legally obligated to collect and remit source deductions on remuneration paid. The mandatory source deductions include Income Tax, Canada Pension Plan Contributions & Employment Insurance Premiums. Employers may choose to outsource this function to a payroll service provider, who would then be responsible for remitting these deductions to the government on the employer’s behalf. For example, VPM (V-TAC Payroll Management) would collect and remit this on behalf of an employer.
                                    </p>
                                    <h3 className='insNewssubTitle'>What does the government do with the taxed money?</h3>
                                    <p className='insNewsdetailsDesc'>
                                        In 2017-2018 personal income tax accounted for 49% of the total revenue earned by the federal government. The government uses these funds, alongside other sources of revenue for various reasons, including but not limited to funding various programs/agencies/departments, managing debt and building infrastructure. The exact spending and prioritizing of funds depends on the policies of the government in place at any particular point in time. For example, in 2016-2017 $48 billion was spend on elderly benefits, $36 billion on Canada health transfer and $25 billion on national defense.
                                    </p>

                                    <Link to="/industry-news"><Button variant="primary" className='view-All-Post mt-2'><span>VIEW ALL POST</span></Button>{' '}</Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <Container className='InsdustryNewSce'>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div id='div8'>
                                    <img className="insNewsImg" src={workplacemeetingscaled} />
                                    <h2 className='insNewsTitle'>COVID-19 Guidance for Canadian Workplace (FAQ)</h2>
                                    <p className='insNewsdetailsDesc'>
                                        With the latest update on COVID-19 restrictions, here is a compilation of up to date information and resources relating to COVID-19 in the workplace for employers in Ontario.
                                    </p>
                                    <ul>
                                        <li>COVID-19 Workplace Safety Plan Checklist</li>
                                        <li>Business Eligibility for free self test kits </li>
                                        <li>Downloadable Posters & Graphics </li>
                                        <li>Information on Booster shots & enhanced vaccination certificate</li>

                                    </ul>
                                    <h3 className='insNewssubTitle'>COVID-19 Guidance for Employers, Workplaces & Businesses </h3>
                                    <p className='insNewsdetailsDesc'>
                                        As of December 19th 2021, the Government of Ontario has updated the public health and workplace safety measures including capacity, and social gathering limits. For indoors the capacity is limited to 25% and for outdoor gathering, it is limited to 75% with a minimum of 2 metres from every other person in the establishment. For more information on capacity limits for events and gathering visit:
                                    </p>
                                    <p className='insNewsdetailsDesc'> <a href='https://www.toronto.ca/home/covid-19/covid-19-reopening-recovery-rebuild/covid-19-reopening-guidelines-for-businesses-organizations/covid-19-guidance-indoor-outdoor-events/' target='_blank' className='industryLink'>Reopening Guidelines by City of Toronto here </a></p>
                                    <p className='insNewsdetailsDesc'>
                                        Owners, operators and employers of businesses, workplaces and organizations in the City of Toronto can use this safety plan checklist to document and <strong>serve as the required COVID-19 safety plan.</strong>
                                    </p>
                                    <p className='insNewsdetailsDesc'>
                                        The Safety checklist includes:
                                    </p>
                                    <ol className='insNewsdetailsDesc'>
                                        <li>Screening</li>
                                        <li>Physical Distancing</li>
                                        <li>Masks and Face Coverings</li>
                                        <li>Personal Protective Equipment (PPE)</li>
                                        <li>Hand Hygiene and Respiratory Etiquette</li>
                                        <li>Cleaning and Disinfection</li>
                                        <li>Preventing and Controlling Crowds</li>
                                    </ol>
                                    <p className='insNewsdetailsDesc'>
                                        Complete this document by downloading a copy here:<a className='industryLink' href="https://www.vtacpayroll.ca/wp-content/uploads/sites/46/2021/12/COVID-Workplace-Safety-Checklist-.pdf" target="_blank" download> download COVID-19 safety checklist</a>
                                    </p>
                                    <h3 className='insNewssubTitle'>Businesses eligibility for self test kits:</h3>
                                    <p className='insNewsdetailsDesc'>
                                        Small to medium sized organizations are now eligible to access rapid antigen test kits in Ontario, British Columbia, Alberta, Saskatchewan, and Manitoba.
                                    </p>
                                    <p className='insNewsdetailsDesc'>You may be eligible to receive rapid tests from pharmacies if you:</p>
                                    <ul className='insNewsdetailsDesc'>
                                        <li>are an incorporated business</li>
                                        <li>have 10 to 199 employees</li>
                                        <li>are in an industry that is currently open under provincial guidelines</li>
                                    </ul>
                                    <p>Free rapid tests are available for Canadian small and medium sized businesses with less than <a href='https://occ.ca/covid-19-rapid-screening-initiative-ontario/' target='_blank' className='industryLink'>150 employees</a>
                                        . A handling fee of $14 per kit for 25 may be applied by some participating pharmacies.</p>
                                    <ul className='insNewsdetailsDesc'>
                                        <li>Register to get rapid rests from participating neighbourhood pharmacies <a href='https://covid-testing-reporting.canada.ca/en/SignIn?ReturnUrl=%2Fsme-registration' target='_blank' className='industryLink'>here</a></li>
                                        <li>Register to get rapid tests from shopper drug mart near you <a href='https://www.federalrapidscreening.morewaystobenefit.ca/' target='_blank' className='industryLink'>here</a> </li>
                                    </ul>
                                    <h3 className='insNewssubTitle'>Downloadable Posters & Graphics </h3>
                                    <a href='https://www.toronto.ca/home/covid-19/covid-19-spread-the-word/' target='_blank'>
                                        <img className="insNewsImgMiddle" src={Covidposter} />
                                    </a>
                                    <p className='insNewsdetailsDesc'>
                                        Access all printable information poster, graphics and questionnaires approved and provided by City of Toronto including:
                                    </p>
                                    <ul className='insNewsdetailsDesc'>
                                        <li>Screening Posters </li>
                                        <li>Questionnaires</li>
                                        <li>Safety regulations</li>
                                        <li>COVID-19 spread related information</li>
                                    </ul>
                                    <p className='insNewsdetailsDesc'>
                                        Use this link to access them: https://www.toronto.ca/home/covid-19/covid-19-spread-the-word/
                                    </p>
                                    <h3 className='insNewssubTitle'>How many days after exposure do COVID-19 symptoms appear?</h3>
                                    <p className='insNewsdetailsDesc'>
                                        COVID-19 sufferers exhibit a range of mild to severe symptoms including fever or chills, cough, shortness of breath or difficulty breathing, fatigue, muscle or body aches, headache, new loss of taste or smell, sore throat, congestion or runny nose, nausea or vomiting, diarrhea. <a href='https://www.youtube.com/watch?v=F70BzSFAZfw' target='_blank' className='industryLink'>COVID-19 Symptoms</a> appear anywhere from 2 days to 14 days after someone is exposed to the virus.
                                    </p>
                                    <p className='insNewsdetailsDesc'>
                                        Source: https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/symptoms.html
                                    </p>
                                    <h3 className='insNewssubTitle'>Who is eligible for a booster shot in Ontario?</h3>
                                    <p className='insNewsdetailsDesc'>
                                        As of this week, all Ontarians over the age of 18 years are eligible for the booster shot. You can book an appointment using the <a href='https://covid19.ontariohealth.ca/' target='_blank' className='industryLink'>Ontario COVID-19 Vaccination portal.</a> Contact the provincial vaccine booking line at 1-833-943-3900 to make an appointment between 8 am to 8 pm.
                                    </p>
                                    <p className='insNewsdetailsDesc'>
                                        All the information shared here is derived directly from various federal, provincial and municipal guidelines provided form the Canadian government. We have tailored the content to provide helpful COVID-19 resources for Canadian business owners, employers and workplace safety operators.
                                    </p>
                                    <Link to="/industry-news"><Button variant="primary" className='view-All-Post mt-2'><span>VIEW ALL POST</span></Button>{' '}</Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <Container className='InsdustryNewSce'>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div id='div9'>
                                    <img className="insNewsImg" src={blocksScaled} />
                                    <h2 className='insNewsTitle'>How HCM Software Can Improve Your Canadian Business</h2>
                                    <p className='insNewsdetailsDesc'>
                                        In recent years, a crucial factor that differentiates companies from each other has become employee experience.
                                    </p>
                                    <p className='insNewsdetailsDesc'>
                                        In fact, through a global IBM survey completed by 1700 CEOs, about <a className='industryLink' href='https://hbr.org/2017/06/hr-must-make-people-analytics-more-user-friendly' target='_blank'>71% identified</a> human capital as a source of competitive advantage. However, investing in HR functions is still low on many companies’ list of priorities.
                                    </p>
                                    <p className='insNewsdetailsDesc'>
                                        Whether you’re preparing your employees for the return to office work or looking for what to do for your business next, investing in human capital management is a great option.
                                    </p>
                                    <p className='insNewsdetailsDesc'>
                                        Human capital management includes all the same processes as traditional human resources such as hiring, job and position management, compliance and reporting. Additionally, it also includes employee rewards and engagement such as benefits, compensation and payroll.
                                    </p>
                                    <Link to="/industry-news"><Button variant="primary" className='view-All-Post mt-2'><span>VIEW ALL POST</span></Button>{' '}</Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <Container className='InsdustryNewSce' id='div10'>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                
                                    <img className="insNewsImg" src={waitressScaled} />
                                    <h2 className='insNewsTitle'>Benefits of Using Payroll Management in the Hospitality Industry</h2>
                                    <p className='insNewsdetailsDesc'>
                                        The <a href='https://www.cvent.com/en/blog/hospitality/what-is-the-hospitality-industry' target='_blank' className='industryLink'>hospitality industry</a> covers a wide range of companies and services that include hotels, lodging, catering, event planning, entertainment, tourism and more!
                                    </p>
                                    <p className='insNewsdetailsDesc'>
                                        Companies within the hospitality industry have a unique set of needs when it comes to <a href='https://www.hotel-online.com/press_releases/release/the-components-of-payroll/' target='_blank' className='industryLink'>payroll management.</a> They usually have a wide variety of employees that all have different wages, benefits and tax needs and employment contracts.
                                    </p>
                                    <p className='insNewsdetailsDesc'>
                                        Due to these circumstances, it often becomes difficult for business owners to track all the payroll needs of their individual employees.
                                    </p>
                                    <p className='insNewsdetailsDesc'>
                                        Thanks to online payroll services, like V-Tac Payroll, the burden no longer has to fall on the shoulders of the owner.
                                    </p>
                               
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h3 className='insNewssubTitle'>Benefits of Using Online Payroll Management</h3>
                                <p className='insNewsdetailsDesc'>
                                    Simplify your life by using payroll management software and services! Focus on running your hospitality business instead of spending extra time trying to crunch the numbers. Here are a few benefits of using online payroll management:
                                </p></Col>
                            <Col><img className="insNewsImgMiddle float-right" src={chef} /></Col>
                        </Row>
                        <Row>
                            <Col>
                                <h3 className='insNewssubTitle'>Accurate Classification of Employees</h3>
                                <p className='insNewsdetailsDesc'>
                                    In the hospitality industry, it is common for <a href='https://www.employmentinnovations.com/knowledge-base-hr/modern-awards/hospitality-industry-award-summary/#:~:text=%C2%A0-,Types%20of%20Employment,-Full-time%3A' target='_blank' className='industryLink'>employment contracts</a> to differ greatly from one person to the next.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Some employees may be long-term permanent workers, casual event staff, contractors or seasonal workers. Each of these positions likely has <strong>different payroll reporting needs</strong> that include wages, service charge distributions, bonuses, taxes and more.
                                </p>
                                <p className='insNewsdetailsDesc'>

                                    Using online payroll management allows you to create employee classifications so that you can apply all the necessary reporting needs for each position. You can ensure that all of your staff is properly paid with no hassle!
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col><img className="insNewsImgMiddle float-left" src={hotelreception} />
                            </Col>
                            <Col>
                                <h3 className='insNewssubTitle'>Minimize Human Error & Manual Input</h3>
                                <p className='insNewsdetailsDesc'>
                                    The biggest issue hospitality businesses owner face is human error. When dealing with any system that requires manual calculations and input, there is always a high risk of making a mistake.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className='insNewsdetailsDesc'>Online payroll management systems take care of all the calculations for you, complicated or not. You’ll be able to easily track all schedules and ensure that you don’t have to double back and fix an error in your employees’ pay, saving you time and leaving your employees happy!</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h3 className='insNewssubTitle'>Accurate Taxes & Reporting for the Hospitality Industry</h3>
                                <p className='insNewsdetailsDesc'>Tax season can be incredibly stressful and even more so when you are trying to balance this with continuing to run your business.</p>
                                <p className='insNewsdetailsDesc'>Having a payroll management system that provides you with accurate and detailed reporting, will make your <a href='https://www.canada.ca/en/revenue-agency/services/forms-publications/publications/rc4036/gst-hst-information-travel-convention-industry.html' target='_blank' className='industryLink'>tax season</a> so much easier!</p>
                                <p className='insNewsdetailsDesc'>When choosing a payroll service to work with, pay attention to how they offer help with taxes and their level of compliance so that you know you won’t have to worry about fines or penalties.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h3 className='insNewssubTitle'>V-Tac Payroll-A Good Fit For Your Hospitality Business</h3>
                                <p className='insNewsdetailsDesc'>At V-Tac Payroll, we offer our payroll management services with the goal of helping the hospitality industry efficiently plan labour, stay up-to-date with legislation and maintain industry standards in Canada.</p>
                            </Col>
                            <Col>
                                <img className="insNewsImgMiddle float-left" src={comparingnotes} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className='insNewsdetailsDesc'>Running a business in the <Link to="/hospitality-payroll-software" className='industryLink'>hospitality industry</Link> comes with many day-to-day tasks and adding extra stress by manually managing your payroll is avoidable! With online payroll services, like V-Tac Payroll, we offer you assistance with payroll, taxes, calculating and distributing benefits, performance management and so much more.</p>
                                <p className='insNewsdetailsDesc'>No matter the size of your hospitality business, our team is here to help you create a payroll management system that is the perfect fit for your company.</p>
                                <p className='insNewsdetailsDesc'><Link to="/contact" className='industryLink'>Contact</Link> our team of specialists today to get started with our hospitality payroll services!</p>
                            </Col>
                        </Row>
                        <Link to="/industry-news"><Button variant="primary" className='view-All-Post mt-2'><span>VIEW ALL POST</span></Button>{' '}</Link>

                    </Container>

                    <Container className='InsdustryNewSce'>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div>
                                    <img className="insNewsImg" src={officeteamwithmasksScaled} />
                                    <h2 className='insNewsTitle'>How HR Will Change As the Workforce Returns to the Office</h2>
                                    <p className='insNewsdetailsDesc'>
                                        This past year has completely changed the way the modern workplace operates.
                                    </p>
                                    <p className='insNewsdetailsDesc'>
                                        If you were planning your HR activities in 2019, no one would have guessed that remote working and trying to juggle a proper work-life balance would be at the center of everything.
                                    </p>

                                    <p className='insNewsdetailsDesc'>
                                        As more people say goodbye to their work from home attire and return to the office, it has a lot of companies re-evaluating their HR practices.
                                    </p>
                                    <p className='insNewsdetailsDesc'>
                                        From the way employees are treated to employer expectations, HR holds more responsibility and accountability than ever for small- to medium-sized businesses across Canada.
                                    </p>
                                    <h3 className='insNewssubTitle'>Changes to HR as Employees Return to the Office</h3>
                                    <p className='insNewsdetailsDesc'>Although we are still not living in a post-pandemic world, we are already seeing some <a href='https://hrexecutive.com/a-year-after-lockdown-5-ways-the-world-of-work-has-changed-for-good/' target='_blank' className='industryLink'>major changes</a> to how business owners are treating their employees and handling HR. Here are a few to explore:</p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>

                                <h3 className='insNewssubTitle2'>Prioritize Employee Benefits & Well-being</h3>
                                <p className='insNewsdetailsDesc'>
                                    The role a company plays in employee well-being became very evident in the last year. Our society has been functioning in a mindset that <strong>prioritized overworking yourself</strong>. As a result, <a href='https://www.shrm.org/hr-today/news/hr-magazine/summer2020/pages/how-the-coronavirus-pandemic-will-change-the-way-we-work.aspx' target='_blank' className='industryLink'>employee well-being</a> took a back seat.
                                </p>
                            </Col>
                            <Col><img className="insNewsImgMiddle" src={changes1} /></Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className='insNewsdetailsDesc'>
                                    Over the last year, we saw how difficult it was for employees to balance their work and personal life when they weren’t physically leaving work at the end of the day.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    As the employees return to work, prioritizing your employees’ well-being will be crucial in the transition.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    As a company, you need to include benefits and provide HR support that places an emphasis on employee well-being. HR activities will focus on helping your employees to become more balanced workers so that burnout and stress leave are less likely to happen.
                                </p>
                            </Col>
                        </Row>
                        <Row>

                            <Col><img className="insNewsImgMiddle" src={changes2} /></Col>

                            <Col>
                                <h3 className='insNewssubTitle2'>Remote Hiring, Recruitment & Training</h3>
                                <p className='insNewsdetailsDesc'>
                                    <a href='https://www.jazzhr.com/blog/14-benefits-of-remote-working-and-hiring-and-how-to-do-each-successfully/' target='_blank' className='industryLink'>Remote hiring,</a> recruitment and training had started to become a common practice for a few companies long before the pandemic. Now, it has become <strong>standard practice</strong> to limit everyone’s interactions.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className='insNewsdetailsDesc'>
                                    However, as the workforce returns to the office, remote hiring, recruitment and training is something we will see more companies investing time and resources into.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    On both sides of the recruitment process, remote interviews made things more convenient, saved time and avoided any issues with travel arrangements. Which also allowed for HR to consider a larger applicant pool of people outside their immediate area.
                                </p>
                                <h3 className='insNewssubTitle'>New Clauses on Employee Contracts</h3>
                                <p className='insNewsdetailsDesc'>
                                    Having learned from this whole experience, future employee contracts will likely change to include clauses related to pandemics, natural disasters and other unavoidable events.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    For instance, depending on the industry, many workplaces require employees to undergo drug testing to be deemed fit for work. During the pandemic, we have seen that mandatory testing for COVID-19 has become a requirement for workers who interact with the public, like in food service and retail.
                                </p>
                                <p className='insNewsdetailsDesc'>

                                    As an employer, these clauses or requirements would be good to have present in employee contracts for workers who are coming into an office. This way, you will be able to <strong>maintain a safe workplace</strong> with employees who feel comfortable coming into the office.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h3 className='insNewssubTitle2'>V-Tac Payroll – Unified HR & Benefits </h3>
                                <p className='insNewsdetailsDesc'>
                                    Having proper HR management for your company is more important than ever. The workplace is no longer what it used to be and the changes won’t be stopping anytime soon.
                                </p>
                            </Col>
                            <Col><img className="insNewsImgMiddle" src={changes3} /></Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className='insNewsdetailsDesc'>
                                    At V-Tac Payroll, we offer unified HR & benefits through using our Human Capital Management (HCM) service.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    All of your crucial employee data, including payroll, can be found in one place.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    This convenient service will allow you to make better decisions and lead your company to grow.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Learn more about our unified HR & benefits service and keep up with HR-related changes as you and your team head back to the office. <Link to="/contact" className='industryLink'>Contact</Link> our team today!
                                </p>
                            </Col>
                        </Row>
                        <Link to="/industry-news"><Button variant="primary" className='view-All-Post mt-2'><span>VIEW ALL POST</span></Button>{' '}</Link>

                    </Container>

                    <Container className='InsdustryNewSce'>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <img className="insNewsImg" src={documentsScaled} />
                                <h2 className='insNewsTitle'>Canadian Payroll Compliance in 2021: What You Need to Know</h2>
                                <p className='insNewsdetailsDesc'>
                                    When you take the leap and decide to run a business, there are so many moving parts to manage to run successfully run your business.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Payroll is one of the most important parts.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Payroll compliance is a critical aspect of your business, however, not many business owners have the time or resources to fully understand what being payroll compliant means.
                                </p>

                            </Col>
                            <Col>
                                <img className="insNewsImgMiddle" src={compliance2} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className='insNewsdetailsDesc'>
                                    In this article, we’ll take a look at what payroll compliance is and everything you need to know about it in 2021.
                                </p>
                                <h3 className='insNewssubTitle'>What Is Payroll Compliance? </h3>
                                <p className='insNewsdetailsDesc'>
                                    <a href='https://blog.wagepoint.com/all-content/what-are-all-the-pieces-of-payroll-compliance' target='_blank' className='industryLink'>Payroll compliance</a> is a fancy industry term that just means following the rules and regulations set by the federal and provincial/territorial legislation relating to payroll, including the:
                                </p>
                                <ul className='insNewsdetailsDesc'>
                                    <li>Canada Pension Plan Act</li>
                                    <li>Employment Insurance Act</li>
                                    <li>Income Tax Act</li>
                                    <li>Employment/labour standards</li>
                                    <li>Worker’s compensation</li>
                                    <li>Quebec-specific legislation</li>
                                </ul>
                                <p className='insNewsdetailsDesc'>
                                    In order to be payroll compliant, you need to stay up-to-date with the changes to rules and legislations, both <strong>permanent and temporary</strong>, that affect your payroll.

                                </p>
                                <h3 className='insNewssubTitle'>Payroll Compliance in 2021</h3>
                                <p className='insNewsdetailsDesc'>
                                    Here are a few <a href='https://www.integrity-data.com/blog/payroll-and-hr-compliance-a-quick-guide-for-business-owners/' target='_blank' className='industryLink'>key areas to be aware of</a> to ensure you are payroll compliant in 2021:
                                </p>
                                <h3 className='insNewssubTitle2'>Ensure Accurate Employee Information </h3>
                                <p className='insNewsdetailsDesc'>
                                    This aspect of payroll compliance may seem like we’re stating the obvious, but that is just how important it is.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Whether it is regarding a change in address, a salary change or parental leave, you need to make sure it has been noted in your payroll, so that these changes are filed correctly.
                                    When using an online payroll service, like VPM, make sure you are reminding employees to keep their accounts updated with any personal information changes, especially if it affects the payroll.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <img className="insNewsImgMiddle" src={compliance1} />
                            </Col>
                            <Col>
                                <h3 className='insNewssubTitle'>Stay Up-to-date With Federal & Provincial/Territorial Legislation </h3>
                                <p className='insNewsdetailsDesc'>
                                    You will want to keep yourself updated regarding changes to legislation that may affect your payroll.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    For instance, due to <a href='https://www.canada.ca/en/revenue-agency/campaigns/covid-19-update/support-employers-cra-covid-19.html' target='_blank' className='industryLink'>COVID-19</a>, there were a number of changes to legislation that affected employers when it came to filing their 2020 taxes.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className='insNewsdetailsDesc'>
                                    At VPM, we work with established legal professionals to ensure that every aspect of our payroll service reflects the requirements outlined in Canadian payroll legislation.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Focus on your business and let us handle the <strong>ever-changing payroll landscape.</strong>
                                </p>
                                <h3 className='insNewssubTitle2'>Submit Payroll Information On Time  </h3>
                                <p className='insNewsdetailsDesc'>
                                    While your busy running your business, tax season deadlines tend to sneak up on you.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    If you are not submitting your payroll information on time and missing the important filing deadlines, you can face some pretty hefty fines. In Canada, the penalty is $100 or an amount calculated according to <a href='https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/payroll/penalties-interest-other-consequences/payroll-penalties-interest.html#:~:text=Legislated%20late-filing%20penalties' target='_blank' className='industryLink'>this chart</a> (whichever amount is greater).
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Take the time to mark your calendar with important filing dates, so you don’t risk getting a fine.
                                    VPM remits source deductions on your behalf so you don’t have to worry about paying any late penalties.
                                </p>
                            </Col>
                        </Row>
                        <Row>

                            <Col>
                                <h3 className='insNewssubTitle2'>VPM’s Fully Compliant Payroll Services </h3>
                                <p className='insNewsdetailsDesc'>
                                    Payroll can be one of the most complex aspects of running a business. This is why getting support from a payroll management company, like VPM, is important in ensuring that you are staying compliant.
                                </p>
                            </Col>
                            <Col>
                                <img className="insNewsImgMiddle" src={compliance3} />
                            </Col>
                        </Row>
                        <Row>

                            <Col>
                                <p className='insNewsdetailsDesc'>Our customizable payroll services are fully compliant with provincial and federal government regulations. We can handle all of your payroll needs so that you can focus on what you do best. </p>
                                <p className='insNewsdetailsDesc'>If you’re interested in working with VPM for secure payroll management, <Link to="/contact" className='industryLink'>Contact</Link> us today!</p>
                                <Link to="/industry-news"><Button variant="primary" className='view-All-Post mt-2'><span>VIEW ALL POST</span></Button>{' '}</Link>
                            </Col>
                        </Row>
                    </Container>

                    <Container className='InsdustryNewSce'>
                        <Row>
                            <Col>

                                <img className="insNewsImg" src={personwearingmaskScaled} />
                                <h2 className='insNewsTitle'>How Ontario’s New COVID-19 Paid Sick Days Affects Your Business’ Payroll</h2>
                                <p className='insNewsdetailsDesc'>
                                    The last year has been tough on both employees and employers across Ontario.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Employers have been faced with making hard decisions about their businesses and their employees without real knowledge of the long-term effects that it might have.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Paid sick days being one of the more controversial topics.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    The Ontario government has now enacted legislation that provides eligible employees three paid sick days for COVID-19 related reasons. Reimbursement would be given to the employer to <strong>relieve the financial burden</strong> that sick days can have on small businesses.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Details about this new legislation are scarce and has employers wondering how it is going to work and how this may affect their business’s payroll.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    V-Tac Payroll Management is staying up-to-date with how Ontario’s new COVID-19 paid sick days are going to affect your business’s payroll so that we can continue to provide you with smooth and reliable payroll management.
                                </p>
                                <h3 className='insNewssubTitle'>COVID-19 Paid Sick Days in Ontario</h3>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={8} md={6} sm={12}>

                                <p className='insNewsdetailsDesc'>
                                    Through the <a href='https://www.ontario.ca/page/covid-19-worker-income-protection-benefit' target='_blank' className='industryLink'>Ontario COVID-19 Worker Income Protection Benefit program</a>, the provincial government is providing paid infectious disease emergency leave to eligible employees.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    They have amended the Employment Standards Act, 2000 to require employers to provide employees with up to 3 days of paid infectious disease emergency leave.
                                </p>
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                                <img className="insNewsImgMiddle" src={sickdays1} />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} md={12} sm={12}>

                                <p className='insNewsdetailsDesc'>
                                    Paid infectious disease emergency leave is available for certain reasons related to COVID-19, such as:
                                </p>
                                <ul className='insNewsdetailsDesc'>
                                    <li>Going for a COVID-19 test</li>
                                    <li>Staying home awaiting the results of a COVID-19 test</li>
                                    <li>Being sick with COVID-19</li>
                                    <li>Going to get vaccinated</li>
                                    <li>Experiencing a side effect from a COVID-19 vaccination</li>
                                    <li>Having been advised to self-isolate due to COVID-19 by an employer, medical practitioner or other specified authority</li>
                                    <li>Providing care or support to certain relatives for COVID-19 related reasons, such as:<br></br>
                                        – An individual who is sick with COVID-19 or has symptoms of COVID-19<br></br>
                                        – An individual who is self-isolating due to COVID-19 on the advice of a medical practitioner or other specified authority
                                    </li>
                                </ul>
                                <p className='insNewsdetailsDesc'>
                                    As an employer, if you already have paid leave included in your existing employment contracts, then this new act only applies if the amount of pay under your employment contract is at least as much as what the employee would be entitled to under the <a href='https://www.ontario.ca/document/your-guide-employment-standards-act-0/sick-leave' target='_blank' className='industryLink'>Employment Standards Act.</a>
                                </p>
                                <h3 className='insNewssubTitle'>Employer Reimbursement for Paid Sick Leave</h3>
                                <p className='insNewsdetailsDesc'>Eligible employers are entitled to be reimbursed for the amount of infectious disease emergency leave pay they paid to their employees. </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4} md={6} sm={12}>
                                <img className="insNewsImgMiddle" src={sickdays2} />
                            </Col>
                            <Col lg={8} md={6} sm={12}>
                                <p className='insNewsdetailsDesc'>
                                    Employers are required to pay up to $200 per employee per day.
                                    An application for reimbursements to the <a href='https://www.wsib.ca/en' target='_blank' className='industryLink'>Workplace Safety & Insurance Board (WSIB)</a> must be made within 120 days of the date the employer paid the employee.
                                    Since information about this legislation is still being released, no specifics have been given about the <a href='https://mathewsdinsdale.com/ontario-enacts-legislation-providing-three-paid-sick-days-for-covid-19-related-reasons/' target='_blank' className='industryLink'>application process</a> (at the time of this article’s publishing).
                                </p>
                            </Col>
                            <Col lg={12} md={12} sm={12}>
                                <p className='insNewsdetailsDesc'>
                                    However, it would likely include the following:
                                </p>
                                <ul className='insNewsdetailsDesc'>
                                    <li>Application with all the details of the employer’s payment and the employee who is receiving the paid leave</li>
                                    <li>An attestation confirming:<br></br>
                                        – That the employer-provided paid leave to an employee<br></br>
                                        – The specific dates the leave was taken<br></br>
                                        – The date payment was made<br></br>
                                        – The amount of the payment<br></br>
                                        – That the employer was not otherwise required to pay the employee under the terms of an employment contract

                                    </li>
                                </ul>
                                <p className='insNewsdetailsDesc'>Based on the information provided, the WSIB will make a decision about whether to grant the reimbursement. </p>
                                <h3 className='insNewssubTitle'>How Does Paid Leave Affect Your Payroll </h3>
                                <p className='insNewsdetailsDesc'>
                                    Although the paid infectious disease emergency leave will be assessed and distributed by WSIB, paid leave will be treated separately from an employer’s WSIB account.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    There may be some payroll confusion if an employee receives WSIB benefits for the same day the paid leave was given. In this instance, the employer would have to repay WSIB for any paid leave reimbursements that they had received for the employee.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <h3 className='insNewssubTitle'>How Can V-Tac Payroll Management Help?</h3>
                            </Col>
                            <Col lg={8} md={6} sm={12}>
                                <p className='insNewsdetailsDesc'>At V-Tac Payroll we are committed to making your payment and payroll needs faster and easier to manage. We keep up to date with implementing provincial government compliance to ensure you are able to easily keep track of these changes to your payroll system. </p>

                                <p className='insNewsdetailsDesc'>
                                    COVID-19 has made this last year incredibly challenging for many small- to medium-sized businesses across Canada. With the introduction of mandatory paid sick leave for COVID-19 related reasons and reimbursement for employers, this can <a href='https://covid19-sciencetable.ca/sciencebrief/benefits-of-paid-sick-leave-during-the-covid-19-pandemic/' target='_blank' className='industryLink'>relieve some stress</a> around missing days of work and the financial burden for both employers and employees.
                                    <Link to="/contact" className='industryLink'> Contact</Link> V-Tac Payroll today to find out more about how we can help you navigate and manage the changes to your payroll associated with the COVID-19 paid sick leave.
                                </p>
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                                <img className="insNewsImgMiddle" src={sickdays3} />
                            </Col>


                            <Col lg={12} md={12} sm={12}>
                                <Link to="/industry-news"><Button variant="primary" className='view-All-Post mt-2'><span>VIEW ALL POST</span></Button>{' '}</Link>
                            </Col>

                        </Row>


                    </Container>

                    <Container className='InsdustryNewSce'>
                        <Row>
                            <Col lg={12} md={12} sm={12}>

                                <img className="insNewsImg" src={calculatorScaled} />
                                <h2 className='insNewsTitle'>How We Make Canadian Payroll Compliance Easy</h2>
                                <p className='insNewsdetailsDesc'>
                                    Managing a business in Canada has its ups and downs. One of the downs is staying compliant with Canadian payroll rules and regulations.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    However, once you find a reliable partner, you can even streamline payroll compliance hassle-free.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Here at V-Tac Payroll Management, we focus on delivering quick and reliable <a href='https://smallbiztrends.com/2014/11/outsourcing-payroll-tips.html' target='_blank' className='industryLink'>payroll services</a> in Canada to small-to-medium-sized businesses. Here’s how we make Canadian payroll compliance easy.
                                </p>
                                <h3 className='insNewssubTitle'>Efficient Payroll Outsourcing in Canada </h3>
                            </Col>
                            <Col lg={8} md={6} sm={12}>
                                <p className='insNewsdetailsDesc'>
                                    Outsourcing payroll compliance can appear to be a Herculean task.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    That’s why we have developed a unique work methodology to help <a href='https://www.cfib-fcei.ca/en/about-us' target='_blank' className='industryLink'>Canadian businesses.</a>
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    With this approach, we can empower you to use our payroll services within 48 hours, regardless of the size of your business and without any risks related to <strong>adhering to relevant legislation.</strong>

                                </p>
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                                <img className="insNewsImgMiddle" src={payrollcompliance1} />
                            </Col>
                            <Col lg={12} md={12} sm={12}>
                                <h3 className='insNewssubTitle'>Ensure Compliance With Built-in Options </h3>
                                <p className='insNewsdetailsDesc'>
                                    At V-Tac Payroll Management, we provide payroll service through our sophisticated payroll platform.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    We work hand in hand with established legal professionals to ensure that every aspect of our platform reflects the requirements outlined in Canadian payroll legislation.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Therefore, every built-in option and feature of our platform adheres to Canadian Revenue Agency (<a href='https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/payroll/payroll-works.html' target='_blank' className='industryLink'>CRA</a>) provincial and federal government legislation.
                                </p>
                                <h3 className='insNewssubTitle'>Error-Free Payroll Process at Your Service </h3>
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                                <img className="insNewsImgMiddle" src={payrollcompliance2} />
                            </Col>
                            <Col lg={8} md={6} sm={12}>
                                <p className='insNewsdetailsDesc'>
                                    Due to its complexity, errors can frequently occur in a self-managed payroll process.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    However, some of these errors can cause a business to face legal repercussions and severe penalties.
                                </p>
                                <p className='insNewsdetailsDesc'>

                                    V-Tac Payroll’s focus is on minimizing risks associated with the payroll process while reducing errors. We deliver guaranteed error-free payroll services.
                                </p>
                            </Col>
                            <Col lg={12} md={12} sm={12}>
                                <p className='insNewsdetailsDesc'>
                                    With our platform, you can rest assured that your employees are always <strong>paid correctly and on time.</strong>
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    If you have HR as part of your team, the basic HR reporting feature will delight them with actionable insights.
                                </p>
                                <p className='insNewsdetailsDesc'>

                                    Our human capital management system enables businesses to manage human resources and payroll from one place.
                                </p>
                                <h3 className='insNewssubTitle'>Access to Your Own Payroll Consultant </h3>
                                <p className='insNewsdetailsDesc'>
                                    With over 20 years of experience, we are aware that every business is unique in terms of payroll needs.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Chances are, at some point your business will need a custom payroll solution that reflects its unique payroll needs. Here at V-Tac Payroll Management, we can develop a <strong>customized solution</strong> to help you stay on top of your payroll process.
                                </p>
                            </Col>
                            <Col lg={8} md={6} sm={12}>
                                <p className='insNewsdetailsDesc'>
                                    To provide peace of mind and ease of use, we will match you with an <strong>experienced and professional payroll consultant.</strong>
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Should you have any questions and need professional advice, you’ll be able to contact your assigned payroll consultant within 30 minutes, no matter where you are.
                                </p>
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                                <img className="insNewsImgMiddle" src={payrollcompliance3} />
                            </Col>
                            <Col lg={12} md={12} sm={12}>
                                <h3 className='insNewssubTitle'>V-Tac Payroll Management’s Error & Omission Guarantee </h3>
                                <p className='insNewsdetailsDesc'>
                                    We had time to test and perfect our processes. That’s why we guarantee to pay all disbursements and taxes on time. Additionally, we guarantee to file all source deductions on time too.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    In our efforts to protect your bottom line and your business as a whole, we even went one step further.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    As a standard part of our payroll management contract, we carry comprehensive errors and omissions liability insurance. Additionally, if need be, V-Tac Payroll has a disaster recovery plan.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    If you want to streamline your payroll compliance, contact the V-Tac Payroll today to <Link to='/request-payroll-quote' className='industryLink'>get a free quote!</Link>
                                </p>
                                <Link to="/industry-news"><Button variant="primary" className='view-All-Post mt-2'><span>VIEW ALL POST</span></Button>{' '}</Link>
                            </Col>
                        </Row>
                    </Container>
                    <Container className='InsdustryNewSce'>
                        <Row>
                            <Col lg={12} md={12} sm={12}>

                                <img className="insNewsImg" src={momworkingfromhomeScaled} />
                                <h2 className='insNewsTitle'>Take Advantage of Savings With Canada’s New Work From Home Tax Credit Process</h2>
                                <p className='insNewsdetailsDesc'>
                                    Canada is known for its citizen-oriented policies and continually ranks as one of the world’s happiest countries. Contributing to this are some laws and regulations designed to make life a little better for the average person.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    The new <a href='https://www.canada.ca/en/revenue-agency/news/2020/12/simplifying-the-process-for-claiming-a-deduction-for-home-office-expenses-for-employees-working-from-home-due-to-covid-19.html' target='_blank' className='industryLink'>work-from-home tax credit</a> from the Canadian government is one of these policies. Many Canadians aren’t sure how they can claim these benefits – and that’s why we’re here to help.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Since many companies have been forced to shut down their offices or facilities at one point or another during the global COVID pandemic, this taxation policy is designed to help ensure that the employers are getting the breather they need.
                                </p>
                                <h3 className='insNewssubTitle'>What Is the New Policy? </h3>
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                                <img className="insNewsImgMiddle" src={advantage1} />
                            </Col>
                            <Col lg={8} md={6} sm={12}>
                                <p className='insNewsdetailsDesc'>The new policy will help taxpayers <strong>work from home with tax deductions.</strong></p>
                                <p className='insNewsdetailsDesc'>Since these are pretty tough times for all of us, both personally and economically, these tax deductions offer the much-needed wiggle room for both employers and the workforce, saving the country from an HR nightmare waiting to happen.</p>
                            </Col>

                            <Col lg={12} md={12} sm={12}>
                                <p>We here at V-TAC believe that this will have a significant, <strong>positive impact</strong> on almost all of Canada’s entrepreneurs, solopreneurs, and small businesses. </p>
                                <p>With almost <a href='https://bc.ctvnews.ca/nearly-5-million-more-canadians-are-working-from-home-and-many-like-it-surveys-1.4903045' target='_blank' className='industryLink'>five million more Canadians are working from home</a> due to the pandemic, a significant amount of the workforce has completely shifted location. </p>
                                <h3 className='insNewssubTitle'>How does it help employers and employees?</h3>
                            </Col>
                            <Col lg={8} md={6} sm={12}>
                                <p className='insNewsdetailsDesc'>
                                    Working from home isn’t as easy as working in the office, as you have to handle most things yourself, and there are some work-from-home <strong>expenses</strong>.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Most employers through that had to fill out T22001 forms to enable work-from-home expenses, which would cost more than C$194 million collectively, significantly burdening both employers and payroll workers working from home.
                                </p>
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                                <img className="insNewsImgMiddle" src={advantage2} />
                            </Col>

                            <Col lg={12} md={12} sm={12}>
                                <p className='insNewsdetailsDesc'>
                                    No one likes paying taxes, especially if they can’t hide behind a mega-corporation.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Small businesses are jeopardized as a result of the pandemic, and every tax relief is massively beneficial. Luckily, the government has announced that work-from-home employees will be able to <strong>claim up to C$400 for 2020</strong> without having to track detailed expenses.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    <a href='https://www.canada.ca/en/government/ministers/diane-lebouthillier.html' target='_blank' className='industryLink'>Diane Lebouthillier</a>, Minister of National Revenue, introduced this policy to streamline, simplify, and ease the burden on millions of Canadians’ shoulders.
                                </p>
                                <h3 className='insNewssubTitle'>How Can I Get In On This Money? </h3>
                                <p className='insNewsdetailsDesc'>
                                    Employees get a tax break, and employers won’t have to fill out <a href='https://www.canada.ca/en/revenue-agency/services/forms-publications/forms/t2201.html' target='_blank' className='industryLink'>complex and costly T22011 forms.</a>
                                </p>
                            </Col>
                            <Col lg={12} md={12} sm={12}>
                                <img className="ImgMiddleFullWidth" src={advantage3} />
                            </Col>
                            <Col lg={12} md={12} sm={12}>
                                <p className='insNewsdetailsDesc'>This still begs the question of how you, as a small business owner, employee or entrepreneur, can benefit from these tax reliefs. Well, you’ll need to fit the following criteria. </p>
                                <ul className='insNewsdetailsDesc'>
                                    <li>You would have had to work from home in 2020 due to the pandemic</li>
                                    <li>You had worked from home at least 50% of the time for at least four weeks in a row in 2020</li>
                                    <li>You’ve filled out and signed the T2200S or T2200 Form from your employer</li>
                                </ul>
                                <p className='insNewsdetailsDesc'>The expenses you’re trying to claim are used directly in your work during the timeframe</p>
                                <p className='insNewsdetailsDesc'>After you’re done with the paperwork, all you need to do is submit the application and enjoy the tax cuts. </p>
                                <h3 className='insNewssubTitle'>The Bottom Line </h3>
                                <p className='insNewsdetailsDesc'>
                                    The COVID-19 pandemic has certainly taken its toll. With the new federal tax credit for working from home, Canadian small businesses, entrepreneurs and solopreneurs can get a little help from the government.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    If you want to learn more about how you can gain a little back from this otherwise-unfortunate situation, and the measures put in place to relieve it, make sure to  <Link to="/contact" className='industryLink'> contact V-TAC Payroll Management</Link> today and find out more about what we can do to turn this corona-induced rough ride into smooth sailing.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    V-TAC Payroll Management can help small businesses and entrepreneurs alike with almost all their payment and payroll needs – and since the pandemic has wreaked havoc on more than a few small businesses, we believe our helping hand might just do the trick.
                                </p>
                            </Col>
                            <Col lg={12} md={12} sm={12}>
                                <Link to="/industry-news"><Button variant="primary" className='view-All-Post mt-2'><span>VIEW ALL POST</span></Button>{' '}</Link>
                            </Col>

                        </Row>
                    </Container>

                    <Container className='InsdustryNewSce'>
                        <Row>
                            <Col lg={12} md={12} sm={12}>

                                <img className="insNewsImg" src={officediscussionScaled} />
                                <h2 className='insNewsTitle'>How Changes to the Canada Labour Code Affect HR</h2>
                                <p className='insNewsdetailsDesc'>
                                    As of January 1st, 2021, the Canadian Labour Code has some new rules and legislations that are meant to improve workers’ rights.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    The changes and improvements are vital to keeping the workplace in Canada moving forward, and they reflect on a couple of different areas, like preventing <strong>workplace harassment</strong>, enhancing <strong>equality</strong> among workers and raising awareness of it in a place of work.
                                </p>

                                <p className='insNewsdetailsDesc'>
                                    To find out exactly what the changes are, and how they affected HR departments around the country, read on.
                                </p>
                                <h3 className='insNewssubTitle'>What Are the Changes to the Labour Code? </h3>
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                                <img className="insNewsImgMiddle" src={code1} />
                            </Col>
                            <Col lg={8} md={6} sm={12}>
                                <p className='insNewsdetailsDesc'>
                                    All the new additions to the labour code are meant to improve the safety and equality of all the workers.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    To go into a bit more detail, you can find the separated focus points that have been introduced and are currently already valid and necessary.
                                </p>
                            </Col>
                            <Col lg={12} md={12} sm={12}>
                                <h3 className='insNewssubTitle'>Increased Prevention of Harassment and Violence in the Workplace </h3>
                                <p className='insNewsdetailsDesc'>
                                    <a href='https://www.parl.ca/DocumentViewer/en/42-1/bill/C-65/royal-assent' target='_blank' className='industryLink'>Anti-harassment and violence legislation</a> is in place to further the reduction of workplace harassment and violence by finally recognizing the existence of these behaviours and strengthening the already existing network of approaches to combat them.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Most importantly, it focuses on <strong>sexual harassment</strong> and <strong>violence</strong> in the office, stating that any action that can reasonably cause harm, whether psychological or physical, will be <strong>addressed appropriately</strong> and <strong>investigated</strong> when brought forward.
                                </p>
                                <h3 className='insNewssubTitle'>Enhancing Awareness and Equality </h3>
                            </Col>

                            <Col lg={8} md={6} sm={12}>
                                <p className='insNewsdetailsDesc'>
                                    The <a href='https://canadagazette.gc.ca/rp-pr/p2/2020/2020-11-25/html/sor-dors236-eng.html' target='_blank' className='industryLink'>amendments to the Employment Equity Regulations</a> are meant to bring <strong>transparency</strong> to the <strong>wage gap</strong> that women, indigenous peoples, minorities, and people with disabilities may be experiencing and reduce the possibility of this gap even existing in the future.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    It also deals with workers’ salaries in the private sector and ensures that they are <strong>properly compensated </strong> for their work hours and any potential overtime.
                                </p>
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                                <img className="insNewsImgMiddle" src={code2} />
                            </Col>
                            <Col lg={12} md={12} sm={12}>
                                <h3 className='insNewssubTitle'>Improving the Employers Compliance With the Code </h3>
                                <p className='insNewsdetailsDesc'>
                                    Improving the employer’s <strong>compliance</strong> with the labour code is now enforced by monetary penalties.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Employers will have to pay <strong>fines</strong> if any of the new rules aren’t followed, and these penalties can go <strong>up to $250,000.</strong>
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    To allow employers ample time to adapt to the system and make <a href='https://laws-lois.justice.gc.ca/eng/acts/L-2/nifnev.html' target='_blank' className='industryLink'>necessary changes</a> the monetary penalties will not be imposed until January 1st of 2022.
                                </p>
                                <h3 className='insNewssubTitle'>How Will They Affect HR? </h3>
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                                <img className="insNewsImgMiddle" src={code3} />
                            </Col>
                            <Col lg={8} md={6} sm={12}>
                                <p className='insNewsdetailsDesc'>
                                    The question here is, how will this affect the HR departments across Canada?
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Well, the really significant change (apart from following the listed changes) is that the employers in federally regulated workplaces will have to conduct <strong>workplace assessments</strong> of their employees to make sure that everything is as it should be.
                                </p>
                                <p className='insNewsdetailsDesc'>They will also have to institute training that relates to harassment and violence, as well as create a detailed process for dealing with all sorts of incidents that can occur in the workplace. </p>
                            </Col>
                            <Col lg={12} md={12} sm={12}>
                                <p className='insNewsdetailsDesc'>
                                    That’s all new and will probably take some time to implement fully, but the points brought to attention are all very important to the representation and rights of the workers all across the country.
                                </p>
                                <h3 className='insNewssubTitle'>The 2021 Canadian HR Landscape </h3>
                                <p className='insNewsdetailsDesc'>
                                    You can always read up on the exact changes through the links in the text above, but hopefully, this was enough to get at least the basics of what these new rules and legislation bring to the table.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    With these changes, 2021 will be quite an important year for Canada when it comes to workers’ rights and equality in the workplace, which is a huge step in the right direction.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Make sure you know what needs to be done in your business to comply with these changes and keep your business compliant.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    To find out more, <Link to="/contact" className='industryLink'> contact us today.</Link>
                                </p>
                            </Col>
                            <Col lg={12} md={12} sm={12}>
                                <Link to="/industry-news"><Button variant="primary" className='view-All-Post mt-2'><span>VIEW ALL POST</span></Button>{' '}</Link>
                            </Col>
                        </Row>
                    </Container>

                    <Container className='InsdustryNewSce'>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <img className="insNewsImg" src={businessmanonmobileScaled} />
                                <h2 className='insNewsTitle'>Canada Emergency Business Account: $40k Loan for Companies</h2>
                                <p className='insNewsdetailsDesc'>
                                    The Government of Canada had recently announced its Canada Emergency Business Account to deliver much-needed financial relief by providing eligible businesses with access to a $40,000 loan.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Your organization may be eligible to enroll in the Canada Emergency Business Account through your bank. Here’s a quick overview, for more details please contact your bank:
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Features of the Loan:
                                </p>
                                <ul>
                                    <li>This program will provide $25 billion in total funding consisting of interest-free, partially forgivable loans of up to $40,000 to small businesses and not-for-profits in all sectors and regions of Canada.</li>
                                    <li>To qualify, organizations will need to demonstrate they paid between $50,000 and $1 million in total payroll in 2019.</li>
                                    <li>To avail the loan, your business should not be a holding company</li>
                                    <li>It’s a $40,000 loan to help eligible businesses pay for operating expenses, payroll and other non-deferrable expenses which are critical to sustain business continuity.</li>
                                    <li>No interest may apply until January 1, 2023.</li>
                                    <li>Until December 31, 2020, the Canada Emergency Business Account will be funded as a revolving line of credit for $40,000.</li>
                                    <li>You have to submit your organization’s 2019 T4 Summary of Remuneration Paid to the Bank</li>
                                    <li>If you have more than one business owner, your bank may ask for more documents</li>
                                </ul>
                                <p className='insNewsdetailsDesc'>
                                    To apply and for more information on this loan,<strong>please talk to your bank.</strong>
                                </p>
                                <Link to="/industry-news"><Button variant="primary" className='view-All-Post mt-2'><span>VIEW ALL POST</span></Button>{' '}</Link>
                            </Col>
                        </Row>
                    </Container>

                    <Container className='InsdustryNewSce'>
                        <Row>
                            <Col lg={12} md={12} sm={12}
                            >
                                <img className="insNewsImg" src={personwithmask1} />
                                <h2 className='insNewsTitle'>How to Claim Your 75% Canadian Wage Subsidy</h2>
                                <p className='insNewsdetailsDesc'>
                                    COVID-19 continues to impact business owners and employees.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    To help businesses stay afloat during quarantine and to make it easier for Canadian companies to resume normal operations when the pandemic ends, the federal government has revealed that it will provide employers with a 75% wage subsidy, termed the Canada Emergency Wage Subsidy.
                                </p>

                                <p className='insNewsdetailsDesc'>
                                    If your business is eligible, you can reduce the amount of payroll deductions required to be remitted to the Canada Revenue Agency (CRA).
                                </p>
                                <h3 className='insNewssubTitle'>What you need to know: </h3>
                                <ul className='insNewsdetailsDesc'>
                                    <li>Companies of all sizes that have had a reduction in revenue of 30% will be eligible regardless of employee count</li>
                                    <li>The subsidy will apply to 75% of the first $58,700 earned</li>
                                    <li>Employers are encouraged to top up their employee’s wages with the remaining 25% of their salaries</li>
                                    <li>Payments will be retroactive to March 15, 2020</li>
                                    <li>The program will be in place for 12 weeks, from Mach 15 to June 6, 2020</li>
                                </ul>
                                <h3 className='insNewssubTitle'>What if my business doesn’t qualify?</h3>
                                <p className='insNewsdetailsDesc'>
                                    Companies that don’t meet the requirements for the Canada Emergency Wage Subsidy may continue to qualify for the previously announced wage subsidy of 10% of remuneration from March 18 to June 20, 2020, up to a maximum of $1,375 per employee and $25,000 per employer.
                                </p>
                                <h3 className='insNewssubTitle'>Claiming Periods:</h3>
                                <ul className='insNewsdetailsDesc'>
                                    <li>Period 1: March 15 – April 11 (reference period for eligibility will be March 2020 over March 2019)</li>
                                    <li>Period 2: April 12 – May 9 (reference period for eligibility will be April 2020 over April 2019)</li>
                                    <li>Period 3: May 10 – June 6 (reference period will be May 2020 over May 2019)</li>
                                </ul>
                                <h3 className='insNewssubTitle'>How to Apply:</h3>
                                <p className='insNewsdetailsDesc'>
                                    To apply for this program, business owners will need to access their Canada Revenue Agency’s <a href='https://www.canada.ca/en/revenue-agency/services/e-services/e-services-businesses/business-account.html' target='_blank' className='industryLink'>My Business Account</a> and a web-based application. Employers need to keep documentation demonstrating their reduction in arm’s-length revenues and remuneration paid to employees.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    More information on the application dates will be released shortly by the government; please stay tuned.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Please <a href='https://www.canada.ca/en/department-finance/news/2020/04/the-canada-emergency-wage-subsidy.html' target='_blank' className='industryLink'>click here</a> for more details on the Canada Emergency Wage Subsidy program.
                                </p>
                                <h3 className='insNewssubTitle'>Free Emergency Wage Subsidy Advice For Businesses:</h3>
                                <p className='insNewsdetailsDesc'>We’re here to assist you during this difficult time. Please call us at 1-866-315-8822 between 9 am – 5 pm if you need any help at all.</p>
                                <Link to="/industry-news"><Button variant="primary" className='view-All-Post mt-2'><span>VIEW ALL POST</span></Button>{' '}</Link>
                            </Col>
                        </Row>
                    </Container>

                    <Container className='InsdustryNewSce'>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <img className="insNewsImg" src={hrteam1} />
                                <h2 className='insNewsTitle'>The Benefits of Outsourcing HR Functions</h2>
                                <p className='insNewsdetailsDesc'>
                                    Nowadays, more and more business owners are joining the HR outsourcing bandwagon, and this can only mean one thing: it works!
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    As a small business owner, it may never have crossed your mind to outsource the HR functions of your company. This is understandable. You probably have so lean a team that you know the shoe sizes of each of your employees.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    However, fulfilling an entire department’s function can become difficult once legalities, forms, and procedures come into play, for example. Imagine getting notified about simultaneous sick days, accidents, benefits, insurance claims, and a load of other issues, and you should begin to feel the difficulty of handling HR.
                                </p>
                                <h3 className='insNewssubTitle'>Benefits of Outsourcing HR </h3>
                                <p className='insNewsdetailsDesc'>
                                    Here are the <a href='https://www.lbmc.com/blog/advantages-hr-outsourcing/' target='_blank' className='industryLink'>advantages</a> of outsourcing your HR functions and why you should work on finding an online HR service provider stat.Improves Efficiency
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    When you outsource your HR functions to people who specialize in them, your workers are left to accomplish tasks that they are good at. The technology used by HR service providers is mainly responsible for the remarkable <a href='https://benefitcorp.com/how-hr-outsourcing-solutions-help-improve-efficiency/' target='_blank' className='industryLink'>increase in productivity</a> caused by outsourcing HR functions.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Having everything automated or cloud-based saves so much paper, time, money and effort. Consequently, your workforce is <strong>much more productive</strong> and profits increase.

                                </p>
                                <h3 className='insNewssubTitle'>Keep Your Costs Down</h3>
                            </Col>
                            <Col lg={8} md={6} sm={12}>
                                <p className='insNewsdetailsDesc'>
                                    An HR department typically has high overhead costs due to education and training, payroll, information, and other related systems and technologies and more.
                                </p>
                                <p className='insNewsdetailsDesc'>

                                    You have to constantly send your HR staff to seminars and training sessions to ensure that they are up-to-date with changes in legislation, trends and other industry standards. You also have to have automated workforce management solutions, information systems and payroll systems.
                                </p>
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                                <img className="insNewsImgMiddle" src={outsourcing1} />
                            </Col>

                            <Col lg={12} md={12} sm={12}>
                                <p className='insNewsdetailsDesc'>
                                    All of these costs add up; for a small company, these are too much and far outweigh the benefits they eventually bring in. Simply put, it is not cost-effective!
                                </p>
                                <p className='insNewsdetailsDesc'>

                                    By outsourcing your HR functions, you get to take advantage of the expertise, updated technology and other resources for a <strong>much lower fee</strong> than if you had to establish them yourself in-house from scratch
                                </p>
                                <h3 className='insNewssubTitle'>Business as Usual Operations</h3>
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                                <img className="insNewsImgMiddle" src={outsourcing2} />
                            </Col>
                            <Col lg={8} md={6} sm={12}>
                                <p className='insNewsdetailsDesc'>
                                    From time to time, a small firm encounters a sudden influx of information, requests and other matters related to HR management. These scenarios tend to create a bottleneck in the business, temporarily occupying the attention of workers and thereby decreasing final output and productivity.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    When you have a trustworthy HR management partner, you can simply turn the unusually large amount of information over to them and let them handle it. In the meantime, you and your employees can return to minding your core business.
                                </p>
                            </Col>
                            <Col lg={12} md={12} sm={12}>
                                <h3 className='insNewssubTitle'>Focus on Honing Your Workers’ Skills </h3>
                                <p className='insNewsdetailsDesc'>
                                    Good HR service providers include employee development programs in their packages. These programs help business owners like you develop and manage the performance and development of your employees.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    They facilitate coaching, training and meetings to talk about performance evaluations, milestones, key strengths and challenges. These help your employees become better at what they do and strive to <strong>attain their professional goals</strong> much faster.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    HR service providers also help ensure that your workers comply with the procedures and policies of your company. This lets you see if everyone shares the goals and values that you set for your business.
                                </p>
                                <h3 className='insNewssubTitle'>Avoid Payroll-related Issues</h3>
                                <p className='insNewsdetailsDesc'>
                                    Regardless of the nature of your small business, it is always wiser to outsource your payroll needs, especially if you do not have any appropriate technology or specially <a href='https://www.baass.com/blog/7-common-payroll-challenges' target='_blank' className='industryLink'>trained payroll staff</a>.
                                    Vendors offering payroll assistance have auxiliary services in place as well, such as providing advice about tax and benefits.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Payroll is a sensitive process that should always be left to professionals with the right background and know-how.
                                </p>
                                <h3 className='insNewssubTitle'>Eliminate the Costs & Work Associated With Hiring an Entire HR Department</h3>
                                <p className='insNewsdetailsDesc'>Labour and staffing costs are among the biggest overhead costs your business can ever have. When you hire, you have to mind overhead costs attached to taxes and payroll, insurance, recruitment fees, health insurance premiums, legal costs and more.</p>
                                <p className='insNewsdetailsDesc'>The wonderful thing about contracting an HR service provider is that they give you the <strong>full benefit</strong> of having your HR department minus the associated costs.</p>
                                <h3 className='insNewssubTitle'>View Your Company From a Different Perspective</h3>
                                <p className='insNewsdetailsDesc'>One of the best things about outsourcing your HR functions (or any aspect of your business, for that matter) is that you get to hear advice from outsiders, that is, a fresh perspective. Looking at your firm from a different angle can make you see things you never noticed before but are affecting your workforce.</p>
                                <p className='insNewsdetailsDesc'>“New” people can also introduce strategies and systems you never thought could help you <strong>improve your workforce.</strong></p>
                                <h3 className='insNewssubTitle'>Give Your Staff Additional Benefits</h3>
                            </Col>

                            <Col lg={8} md={6} sm={12}>
                                <p className='insNewsdetailsDesc'>
                                    Although your company is small, that does not mean that you cannot give your workers <a href='https://www.olympiabenefits.com/blog/the-importance-of-employee-benefits-for-a-small-business' target='_blank' className='industryLink'>benefits</a>, such as health insurance and medical plans. Many HR service providers allow you to give incentive packages and related benefits to your employees.
                                </p>
                                <p className='insNewsdetailsDesc'>

                                    This feature used to be available only to big firms. Luckily, HR vendors included benefit packages as they scaled their services for small and medium enterprises.
                                </p>
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                                <img className="insNewsImgMiddle" src={outsourcing3} />
                            </Col>
                            <Col lg={12} md={12} sm={12}>
                                <h3 className='insNewssubTitle'>Minimize Your Risk</h3>
                                <p className='insNewsdetailsDesc'>
                                    One of the main sources of risk in HR management is the failure to comply with labour and taxation laws. Unfortunately, these laws are constantly changing, and keeping abreast of new policies takes up a lot of time, effort, and even cash in some cases.A good HR service provider has individuals whose main function is to be updated about these changes in legislation. By working with these people, you <strong>get to take advantage of their knowledge</strong> without spending a considerable amount of resources.
                                </p>
                                <h3 className='insNewssubTitle'>V-Tac Payroll Management – Helping Clients Focus on The Business They Love</h3>
                                <p className='insNewsdetailsDesc'>
                                    Handling the HR functions of your company while running your day-to-day operation is daunting. Fortunately, HR management service providers are now around to help you with just that exactly. These vendors offer assistance with payroll, tax advice, benefits packages, employee engagement initiatives, performance evaluations and many other aspects of HR. No matter the size of your firm, you stand to benefit from using this cost-effective solution.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    At V-tac Payroll, we specialize in working with small to medium-sized businesses. You can trust that our HR service will meet the needs of your business so that you can offer your employees the <strong>best experience possible.</strong>
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    If you’re interested in working with our team to outsource your HR functions, <Link to="/contact" className='industryLink'> contact</Link> us today!
                                </p>

                                <p className='insNewsdetailsDesc'></p>
                            </Col>
                            <Col lg={12} md={12} sm={12}>
                                <Link to="/industry-news"><Button variant="primary" className='view-All-Post mt-2'><span>VIEW ALL POST</span></Button>{' '}</Link>
                            </Col>
                        </Row>
                    </Container>

                    <Container className='InsdustryNewSce'>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <img className="insNewsImg" src={calculatorScaled} />
                                <h2 className='insNewsTitle'>How Payroll Management Works</h2>
                                <p className='insNewsdetailsDesc'>
                                    In a nutshell, payroll management is all about paying your employees (and the Canadian government!) the right amount at the right time. It sounds utterly simple when put that way, but it is actually a highly complicated process.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    This is why so many employers, including some small business owners, choose to just outsource payroll management. However, regardless of whether you do it in-house or by a third party, it is important that you know how it works. In case any of your employees comes knocking on your door with a question (and it will happen), you can at least provide even a general explanation.
                                </p>
                                <h3 className='insNewssubTitle'>The Payroll Management Process: </h3>
                                <p className='insNewsdetailsDesc'>
                                    Payroll management covers everything about wages and taxes. That means, it is concerned with tracking hours, calculating salaries on the basis of hours worked, and withholding and paying taxes and other pertinent deductions, such as various premiums and contributions.
                                </p>
                                <h3 className='insNewssubTitle'>The Payroll Management Process: </h3>
                                <h3 className='insNewssubTitle2'>First Things First</h3>
                                <p className='insNewsdetailsDesc'>
                                    Payroll management begins the moment you hire an employee. The first thing you have to do as an employer is to open an account with the Canadian Revenue Agency (CRA) using your registered business number. This is the account that will be used to transfer salaries.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Collate the SINs and federal and provincial TD1 forms or personal tax credit returns from your employees because these are needed for the next step.
                                </p>
                                <h3 className='insNewssubTitle2'>Calculate Your Employees’ Salaries</h3>
                                <p className='insNewsdetailsDesc'>
                                    To put it simply, total pay is equal to total salary minus total deductions. By total salary, we mean the regular salaries of your employees and all other taxable benefits that each employee is entitled to.
                                </p>
                                <h3 className='insNewssubTitle2'>Calculate the Deductions</h3>
                                <p className='insNewsdetailsDesc'>Find out what deductibles apply to each of your employees each pay cycle and deduct those from their total pay. Take note that deductions are differently calculated depending on the territory or province that your business is located or your employee reports.</p>
                                <p className='insNewsdetailsDesc'>The basic deductions are EI, CPP or QPP, and income tax. The great thing about Canada is that there are plenty of helpful resources available online. You can easily see which employees are eligible for which benefit or should be deducted for a certain premium.</p>
                                <p className='insNewsdetailsDesc'>Changes in policies or regulations are also quickly published online to guide business owners in adjusting payments.</p>
                                <p className='insNewsdetailsDesc'>Most importantly, the Canadian government has a handy online calculator that does all of the computation for you. Just make sure to input all information correctly to get accurate figures.</p>
                                <h3 className='insNewssubTitle2'>Use the PDOC</h3>
                                <p className='insNewsdetailsDesc'>You can use that calculator by clicking here. Take note, though, that this payroll deduction online calculator (PDOC) is used “to calculate federal, provincial (except for Quebec), and territorial payroll deductions.”</p>
                                <h3 className='insNewssubTitle2'>Remit the Deductions</h3>
                                <p className='insNewsdetailsDesc'>When remitting payments to the CRA for the first time, you will have to send it by check or money order accompanied with some details about your business, including a notice that you are a new remitter. You also have to include the remittance period that those payments are for. The CRA will regularly send you forms for your subsequent payments.</p>
                                <h3 className='insNewssubTitle2'>Do the Reports</h3>
                                <p className='insNewsdetailsDesc'>Your last step is accomplishing T4 or T4A slips, which are comparable to summaries of the incomes and deductions of your employees. These go with an information return and T4 summary. Finally, make sure to keep all these documents in a well-organized manner in a safe place for at least six years after submission.</p>
                                <h3 className='insNewssubTitle2'>Conclusion</h3>
                                <p className='insNewsdetailsDesc'>Payroll management is one of the most boring and annoying tasks you have to do as an employer. However, it is also one of the most crucial. Make sure to do it correctly and promptly because the smallest errors can have big repercussions in the end.</p>
                                <Link to="/industry-news"><Button variant="primary" className='view-All-Post mt-2'><span>VIEW ALL POST</span></Button>{' '}</Link>

                            </Col>
                        </Row>
                    </Container>

                    <Container className='InsdustryNewSce'>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <img className="insNewsImg" src={dentistatwork1} />
                                <h2 className='insNewsTitle'>Payroll Management For Your Dental Practice – Simplifying The Whole Process</h2>
                                <p className='insNewsdetailsDesc' style={{ fontStyle: "italic" }}>
                                    Let a payroll management system take care of simplifying everything else required of your business.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    In order to run a dental firm successfully, there are a lot of things that you need to do. However, among the most important aspects that you need to give considerable attention is staff management.

                                    Your employees are the front line of your profession, which means that they need to treat your patients well. In order to have an in-house pool of satisfied employees, you need to keep them motivated, while making sure that they are appropriately compensated.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    One of your roles as the employer is to communicate to your employees their benefits, including making sure that they receive their payments accurately and regularly. More than over, owners of small businesses, including dentists, are now turning their attention to online service providers when it comes to simplifying their payroll processes. This aspect can be particularly complicated and time consuming.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    As a business owner, you are the one who has the responsibility to withhold the right amount of taxes, and paying other third party requirements. You also have to deal with bonuses, unemployment taxes, part-time workers, paid time off, and many more.
                                </p>
                                <h3 className='insNewssubTitle'>Why Simplify Payroll Management? </h3>
                                <p className='insNewsdetailsDesc'>
                                    These days, several business owners are comfortable completing their own payroll. There are even some who write out checks physically instead of taking advantage of direct deposit. Others, also, ask the help of an accountant. These are methods that are considered as valid.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    However, as businesses tend to evolve with time, time has become more challenging to control. In our busy lives, dental facility owners face more demands more than ever. While there is a desire to manually perform things, the lack of time may pose some challenges and risks which can turn out to be dangerous in the long run.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Online payroll management systems provide a solution to answer these needs. Most often, it’s just a matter of data entry, providing information on hours and salary numbers, previewing the amount that will be taken from the company account, and sending the payroll.
                                </p>
                                <p className='insNewsdetailsDesc'>

                                    Aside from the benefit of being automated, this process also allows you to keep a close monitoring of the payroll in order to ensure that everything is done correctly.
                                </p>
                                <p className='insNewsdetailsDesc'>

                                    If you want to be involved in your company’s payroll, embracing an effective payroll management system is better compared to sending it off easily to an accountant. It also involves lesser work compared to managing everything by yourself using a spreadsheet.
                                </p>
                                <h3 className='insNewssubTitle'>Benefits to Expect</h3>
                                <p className='insNewsdetailsDesc'>Payroll software solutions can provide you with a number of advantages, not just for you as the employer, but for the employees as well. Since these solutions typically come with features such as employee calendars, it becomes easier for you to manage absences, sick leaves, as well as overtime. In just a quick glance, you can see the length of time an employee is away, whether they need to be at work, and the type of leave that they are currently availing. Planning becomes easier this way.</p>
                                <p className='insNewsdetailsDesc'>As you take control of payroll by yourself anytime and anywhere, you have the potential to save a huge amount of money, particularly when you use the system efficiently and quickly. Doing it by yourself alone may pose some risks, but getting a payroll management system designed for this particular aspect at work will diminish all of these issues.</p>
                                <p className='insNewsdetailsDesc'>Quite obviously, managing the payroll of a business can take extra resources and time. By having the power to do all of these things without having to stay in front of your computer at a single time, being able to access the needed data using any mobile device, you can give yourself better control over things, giving yourself the opportunity to focus on what’s more important than simply being tied to this task alone.</p>
                                <h3 className='insNewssubTitle'>How Much Does it Cost?</h3>
                                <p className='insNewsdetailsDesc'>Perhaps you are wondering whether you need to spend a lot of money just to embrace a payroll management system that can make the entire process simpler. The thing is, the amount of money that you need to pay highly depends on the number of employees that you have, as you may also have an additional fee for every employee aside from the flat rate.</p>
                                <p className='insNewsdetailsDesc'>At the same time, due to the presence of different service providers, make sure that you do your own research in order to make sure that you are looking into, not just the price, but the overall credibility of the company.</p>
                                <h3 className='insNewssubTitle'>Getting the Most out of It</h3>
                                <p className='insNewsdetailsDesc'>A cloud based payroll management system is a solution to making things simple inside your dental facility. You can now expect better handling of your payroll each and every month, thus increasing employee satisfaction. If you already have one, how can make the most out of your system?</p>
                                <p className='insNewsdetailsDesc'>Most likely, you have invested a good amount of time and money getting the best system available. Learning how to use it may take a bit of your time, but as you get familiar with it even better, it’s now time for you to expect a return on your investment.</p>
                                <p className='insNewsdetailsDesc'>As you start using cloud based services for your payment management, you can now spend more time in running your business, not just your payroll. After all, these types of services are usually designed to make things simple, especially with regard to paying your employees, attracting and onboarding new hires, tracking time and attendance, as well as managing your HR data.</p>
                                <p className='insNewsdetailsDesc'>These payroll management solutions can be tailored according to your business requirements. As a result, you can expect an overall improvement to your company’s efficiency, while reducing concerns related to compliance at the same time.</p>
                                <h3 className='insNewssubTitle'>Bottom Line</h3>
                                <p className='insNewsdetailsDesc'>Being in the dental profession already requires your full attention. Rather than worry about aspects in your business such as payroll, why not focus on more important things such as making sure that all of your patients are satisfied with the services provided? Let a payroll management system take care of simplifying everything else required of your business.</p>
                                <Link to="/industry-news"><Button variant="primary" className='view-All-Post mt-2'><span>VIEW ALL POST</span></Button>{' '}</Link>
                            </Col>
                        </Row>
                    </Container>

                    <Container className='InsdustryNewSce'>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <img className="insNewsImg" src={vpminterface1} />
                                <h2 className='insNewsTitle'>How to Calculate Employee Payroll</h2>
                                <p className='insNewsdetailsDesc' style={{ fontStyle: "italic" }}>
                                    As a responsible employer, you should have at least a rudimentary understanding of the process to be able to answer them comprehensively each time.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    It is important that you understand payroll in Canada. That is not to say that Canadian laws are not to be trusted. In fact, there are quite a number of regulations and regulatory bodies in the country that ensure that businesses do not end up overpaying and employees are not underpaid.
                                </p>

                                <p className='insNewsdetailsDesc'>
                                    However, knowing how payroll works is still beneficial regardless of the size of your business. Although payroll systems are all automated nowadays, you are bound to encounter questions from your employees every now and then about what goes into and what gets taken out of their pay.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    As a responsible employer, you should have at least a rudimentary understanding of the process to be able to answer them comprehensively each time. We have put together this brief guide about employee payroll in Canada to hopefully assist you in addressing such inquiries from your workers.
                                </p>
                                <h3 className='insNewssubTitle'>What You Need </h3>
                                <p className='insNewsdetailsDesc'>
                                    You do not need much other than the following:
                                </p>
                                <ul className='insNewsdetailsDesc'>
                                    <li>Payslips</li>
                                    <li>Statements of earnings</li>
                                    <li>Your books</li>
                                    <li>The payroll deduction online calculator (PDOC)</li>
                                </ul>
                                <p className='insNewsdetailsDesc'>
                                    You can always use sample figures when doing calculations, but having your company’s actual documents handy means that you get realistic figures each time. Also, by using your own numbers, you can immediately tell if there is anything off with your calculations.
                                </p>
                                <h3 className='insNewssubTitle'>Step-by-Step Instructions</h3>
                                <h3 className='insNewssubTitle2'>#1 Input general information</h3>
                                <p className='insNewsdetailsDesc'>The system will ask for your employee’s name, your business name, province or territory, pay date, and pay period frequency. The employee and employer name fields are optional. The rest are required fields.</p>
                                <p className='insNewsdetailsDesc'>The location is where your business is located or where your employee reports for work. It is important that you get this right because of applicable regulations. Needless to say, the pay date and frequency are of utmost importance because they also have a direct effect on your worker’s pay.</p>
                                <h3 className='insNewssubTitle2'>#2 Enter the gross income</h3>
                                <p className='insNewsdetailsDesc'>Input the salary or wages income without deductions (required field). Also, enter vacation pay, if any, for that period. There are additional options in the form, which are bonus and retroactive payments, and no bonus and retroactive payment options. There is a list of other taxable income, benefits, and deductions. Select all that apply for the period you are calculating for.</p>
                                <p className='insNewsdetailsDesc'>Unfortunately, only one calculation can be done at the same time by the calculator. If your employee has a bonus and a retroactive payment, you will have to exit and go back in.</p>
                                <h3 className='insNewssubTitle2'>#3 Enter supporting information</h3>
                                <p className='insNewsdetailsDesc'>The form branches out at this point depending on what you selected in the previous screen. This is where you are going to need all the documents you prepared beforehand. Make sure to enter all numbers accurately. Paying incorrect salary can have serious consequences, and the reliability of the online calculator is just as good as the accuracy of your figures.</p>
                                <h3 className='insNewssubTitle2'>#4 Supply other information</h3>
                                <p className='insNewsdetailsDesc'>The form will now ask for information related to provincial tax credit return, CPP or QPP, and EI. like in the previous step, make sure to check your numbers thoroughly and enter them accurately to avoid mistakes.</p>
                                <h3 className='insNewssubTitle2'>#5 Get the result</h3>
                                <p className='insNewsdetailsDesc'>The calculator will now display results showing payroll deductions and employer remittances separately. The files can be saved as PDFs.</p>
                                <h3 className='insNewssubTitle2'>#6 Do it again</h3>
                                <p className='insNewsdetailsDesc'>If you need to make changes, make a different calculation type, or calculate the salary of another employee, select “Modify the current calculation,” “Start over,” and “Next calculation,” respectively.</p>
                                <h3 className='insNewssubTitle2'>Conclusion</h3>
                                <p className='insNewsdetailsDesc'>If you are not a fan of math, you can always just buy payroll software for your company. There are plenty of options on the market today, and applications are becoming cheaper as technology advances. However, you should not fully rely on computers.</p>
                                <p className='insNewsdetailsDesc'>Knowing how to calculate your employees’ payroll yourself is useful because it gives you an idea of what goes in and out of their pay and can explain it to them. It is also a great way to check if your numbers are adding up.</p>
                                <p className='insNewsdetailsDesc'>This wraps up our introduction to calculating employee payroll in Canada. We hope you found this review useful, and we highly encourage you to check out the resources we have linked out to in this article. Feel free to share this with other business owners you know!</p>
                                <Link to="/industry-news"><Button variant="primary" className='view-All-Post mt-2'><span>VIEW ALL POST</span></Button>{' '}</Link>
                            </Col>
                        </Row>
                    </Container>

                    <Container className='InsdustryNewSce'>
                        <Row>
                            <Col lg={12} md={12} sm={12}>

                                <img className="insNewsImg" src={binders1} />
                                <h2 className='insNewsTitle'>Payroll Tax – Common Issues That You Should Get Out Of</h2>
                                <p className='insNewsdetailsDesc' style={{ fontStyle: "italic" }}>
                                    As an employer, you have a long list of obligations to your employees. This includes payroll, which is essential to them, to you, and to the Canadian government.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    You have four main responsibilities related to payroll aside from paying your employees. There are deductions you have to take out of your employees’ paychecks and remit to the CRA. These include taxes on your and your employees’ earnings.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Unfortunately, many business owners make the mistake of failing to fulfill these obligations or doing them incorrectly. There can be plenty of consequences in such cases. Here are the common issues you need to get out of.
                                </p>
                                <h3 className='insNewssubTitle'>#1 Failing to deduct the required tax </h3>
                                <p className='insNewsdetailsDesc'>
                                    You need to deduct a required amount of tax from your employees’ paychecks depending on how much they are paid. Otherwise, the CRA may charge a penalty of at least 10% of the amount of tax that should have been deducted.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    There are ways out of this problem, though, but you need to do them as soon as you realize your error. The first thing you can do is tell your employee about what happened. That way, they can pay the required amount when it is time to file income taxes and benefit returns. You can also just take out more tax at source as a workaround.
                                </p>
                                <h3 className='insNewssubTitle'>#2 Remitting late or failing to remit altogether </h3>
                                <p className='insNewsdetailsDesc'>
                                    Payroll deductions do not only have to be done correctly but also remitted properly and on time. Otherwise, the CRA will charge you at least 3% of the amount in question. This percentage can go up to 20% depending on how many days you are late (or if you did not remit at all) and how many times you have committed the same error in the current calendar year.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    You can bookmark this page on the CRA website to check when your remittance due date is. When remitting payments and your due date happens to fall on a weekend or a CRA-recognized public holiday, the last day you can remit without being late is the business day right after the exact due date.
                                </p>
                                <h3 className='insNewssubTitle'>#3 Failing to file information returns on time </h3>
                                <p className='insNewsdetailsDesc'>
                                    To the CRA, each slip is an information return, and you are penalized separately for each information return that is filed late. The penalty is at least $100, and it can increase depending on how many information returns are late and the number of days they are late.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    If you have 50 employees or less, your maximum penalty is $1,000. However, if you have a large business that employs more than 10,000 people, that penalty can be as high as $7,500.
                                </p>
                                <p className='insNewsdetailsDesc'>
                                    Avoid this problem by filing your information returns on time. You can check the due date here.
                                </p>
                                <h3 className='insNewssubTitle'>Conclusion </h3>
                                <p className='insNewsdetailsDesc'>
                                    Payroll can be a very mundane task, but it involves tasks that can get you in trouble if not done right and on time. The key to avoiding these issues is looking up the right resources and keeping everything organized. Keep your employees and your obligations to the CRA covered, and your business will not be in any trouble.
                                </p>
                                <Link to="/industry-news"><Button variant="primary" className='view-All-Post mt-2'><span>VIEW ALL POST</span></Button>{' '}</Link>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Fragment>
        );
    }
}

export default AllIndustryNews;