import React, { Fragment } from 'react';
import TopHead from '../../components/TopHead/TopHead';
import TopNavigation from '../../components/TopNavigation/TopNavigation';
import TradePart from '../../components/TradePart/TradePart';
import QuoteComponentCyan from '../../components/QuoteComponentCyan/QuoteComponentCyan';
import Footer from '../../components/Footer/Footer';
import IndustryNewsBanner from '../../components/IndustryNewsBanner/IndustryNewsBanner';
import ManagingEmployee from '../../components/Blogs/ManagingEmployee';
import { Container } from 'react-bootstrap';


const Blog2Page = () => {
    return (
        <Fragment>
            <Container fluid="true" className='accContainer'>
                <TopHead />
                <TopNavigation title="Managing Employee Resignations: Best Practices | V-TAC Payroll Management" />
                <IndustryNewsBanner />
                <ManagingEmployee />
                <QuoteComponentCyan />
                <TradePart />
                <Footer />
            </Container>
        </Fragment>
    );
};

export default Blog2Page;