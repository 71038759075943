import React, { Fragment } from 'react';
import TopHead from '../../components/TopHead/TopHead';
import TopNavigation from '../../components/TopNavigation/TopNavigation';
import TradePart from '../../components/TradePart/TradePart';
import QuoteComponentCyan from '../../components/QuoteComponentCyan/QuoteComponentCyan';
import Footer from '../../components/Footer/Footer';
import IndustryNewsBanner from '../../components/IndustryNewsBanner/IndustryNewsBanner';
import { Container } from 'react-bootstrap';
import EffectiveWays from '../../components/Blogs/EffectiveWays';

const Blog3Page = () => {
    return (
        <Fragment>
            <Container fluid="true" className='accContainer'>
                <TopHead />
                <TopNavigation title="7 Effective Ways to Retain Top Talent in Your Organization | V-TAC Payroll Management" />
                <IndustryNewsBanner />
                <EffectiveWays />
                <QuoteComponentCyan />
                <TradePart />
                <Footer />
            </Container>
        </Fragment>
    );
};

export default Blog3Page;