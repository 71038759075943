import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class QuoteComponentGray extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className='quoteComponentGray text-center'>
                    <div>
                        <Container>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <h2 className='qoutetopTitle'>GET A TABLET & FIRST MONTH <span className='spancolor'> FREE!</span></h2>
                                    <h4 className='qoutetopSubTitle'>Transfer your payroll to VPM today and get a tablet & the first-month <span style={{ "color": "#E6001F" }}> FREE*</span> of payroll services! Start the process with a quick quote.</h4>
                                    {/* <Link to="/promotional-conditions"><a href="/promotional-conditions/" className='joinNowLink'>*some conditions apply</a></Link> */}
                                    <Link to="/promotional-conditions"><p className='quoteCondition'>*some conditions apply</p></Link>

                                    <Link to="/request-payroll-quote"><Button className='quotebutton' ><span onClick={() => {
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }} >GET A QUOTE</span></Button></Link>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </Fragment>
        );
    }
}

export default QuoteComponentGray;