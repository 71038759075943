import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class CareerBanner extends Component {
    componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="CareerTopBanner  p-0">
                    <div className='CareerTopBannerOverly'>
                        <Container className="topContent text-center">
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <h1 className='CareertopTitle'>
                                        CAREERS
                                    </h1>
                                </Col>

                            </Row>
                        </Container>

                    </div>

                </Container>

            </Fragment>
        );
    }
}

export default CareerBanner;