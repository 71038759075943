import React, { Component, Fragment } from 'react';
import TopHead from '../components/TopHead/TopHead';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import QuoteComponentCyan from '../components/QuoteComponentCyan/QuoteComponentCyan';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import GlobalImagingBanner from '../components/GlobalImagingBanner/GlobalImagingBanner';
import GlobalImagingSec1 from '../components/GlobalImagingSec1/GlobalImagingSec1';


class GlobalImagingPage extends Component {
    render() {
        return (
            <Fragment>
                <TopHead />
                <TopNavigation title="Global Imaging | V-TAC Payroll Management" />
                <GlobalImagingBanner />
                <GlobalImagingSec1 />
                <QuoteComponentCyan />
                <TradePart />
                <Footer />
            </Fragment>
        );
    }
}

export default GlobalImagingPage;