import React, { Fragment, useState, useEffect } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import axios from "axios";
import { fetchData } from '../../APIRequest/APIRequest';
import Table from 'react-bootstrap/Table';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Label } from 'recharts';

var fedralCodes = [];

const PayrollCalculator = () => {


    const [taxYear, setTaxYear] = useState([]);
    const [yearValue, setYearValue] = useState('');

    const [country, setCountry] = useState([]);
    const [countryValue, setCountryValue] = useState('');

    const [province, setProvince] = useState([]);
    const [provinceValue, setProvinceValue] = useState('');
    const [txtRateValue, setTxtRateValue] = useState('');

    // const [otherTaxableIncomePerPay, setOtherTaxableIncomePerPay] = useState('');
    // const [otherDeductionPerPay, setOtherDeductionPerPay] = useState('');

    const [payrollType, setPayrollType] = useState([]);
    const [payrollTypevalue, setPayrollTypevalue] = useState('');

    const [federalCodes, setFederalCodes] = useState([]);
    const [federalValue, setFederalValue] = useState('');

    const [provincialCodes, setProvincialCodes] = useState([]);
    const [provincialCodeValue, setProvincialCodeValue] = useState('');

    const [taxResult, setTaxResult] = useState({});



    const [errors, setErrors] = useState({});


    // ----------------------------------------------------------------

    const [txtFedAmount, setTxtFedAmount] = useState(false);
    const [txtProAmount, setTxtProAmount] = useState(false);
    const IsFedOverride = () => {
        setTxtFedAmount(!txtFedAmount);
    };
    const IsProOverride = () => {
        setTxtProAmount(!txtProAmount);
    };

    // ----------------------------------------------------------------


    useEffect(() => {
        fetchTaxYearFromAPI();
        fetchCountryFromAPI();
        fetchPayrollTypeFromAPI();
        window.scrollTo({ top: 0, behavior: 'smooth' });
        AOS.init();
    }, []);

    //get TaxYear from API
    const fetchTaxYearFromAPI = async () => {
        const apiData = await fetchData("/FiscalYear/ListOfActiveFiscalYears");
        if (apiData) {
            setTaxYear(apiData);
        }
    }

    //get country from API
    const fetchCountryFromAPI = async () => {
        const apiData = await fetchData("/Country/ListOfActiveCountries");
        if (apiData) {
            setCountry(apiData);
        }
    }
    //get payroll Types /pay period frequency from API
    const fetchPayrollTypeFromAPI = async () => {
        const apiData = await fetchData("/PayrollType/ListOfPayrollTypes");
        if (apiData) {
            setPayrollType(apiData);

        }
    }
    const changePayrollType = async (event) => {
        try {
            const id = document.getElementById("ddlFrequency").value;
            setPayrollTypevalue(event.target.value);
            clearError('payrollTypevalue');
            const obj = await fetchData("/PayrollType/GetPayrollTypeById?id=" + id, "");
            if (obj) {
                document.getElementById("txtPayPeriods").value = obj.PeriodInYear;
                document.getElementById("txtHoursPerWeek").value = obj.DefaultWorkingHour;
            }
        } catch (error) {
            // Handle the error here
            console.error(error);

        }
    }

    const changetxtRate = (event) => {
        setTxtRateValue(event.target.value);
        clearError('txtRateValue');
    };
    // const changeOtherDeduction = (event) => {
    //     setOtherDeductionPerPay(event.target.value);
    // };

    const changeYear = (event) => {
        setYearValue(event.target.value);
        clearError('yearValue');
    };

    const changeFederalTD = (event) => {

        const federalValue = document.getElementById("ddlFederal").value;
        setFederalValue(event.target.value);
        clearError('federalValue');

        for (let i = 0; i < fedralCodes.length; i++) {
            if (fedralCodes[i].TaxClaimCodeDetailsId == federalValue) {
                var obj = fedralCodes[i];
                if (obj) {
                    document.getElementById("txtFedAmount").value = obj.ToValue;
                }

                break;
            }
        }
        // var obj=fedralCodes.find(item => item.TaxClaimCodeDetailsId === selectedValue);

    };
    const changeProvincialTD = (event) => {

        const provincialCodeValue = document.getElementById("ddlProvincial").value;
        setProvincialCodeValue(event.target.value);
        clearError('provincialCodeValue');

        for (let i = 0; i < provincialCodes.length; i++) {
            if (provincialCodes[i].TaxClaimCodeDetailsId == provincialCodeValue) {
                var obj = provincialCodes[i];
                if (obj) {
                    document.getElementById("txtProvinceAmount").value = obj.ToValue;
                }

                break;
            }
        }


    };

    const changeProvince = async (event) => {
        const ficalYearId = document.getElementById("fiscalYear").value;
        const provinceId = document.getElementById("ddlProvince").value;
        setProvinceValue(event.target.value);
        clearError('provinceValue');

        var url = "/TaxClaimCode/GetProvincialClaimCodes?ficalYearId=" + ficalYearId + "&provinceId=" + provinceId;
        const provincialCodes = await fetchData(url);
        if (provincialCodes) {
            setProvincialCodes(provincialCodes)
        }

    };

    const changeCountry = async (event) => {
        const ficalYearId = document.getElementById("fiscalYear").value;
        var countryId = event.target.value;
        setCountryValue(event.target.value);
        var url = "/Province/ListOfProvincesByCountryId?countryId=" + countryId;
        const apiData = await fetchData(url);
        if (apiData) {
            setProvince(apiData);
            clearError('countryValue');
        }

        var url = "/TaxClaimCode/GetFederalCodes?ficalYearId=" + ficalYearId + "&countryId=" + countryId;
        const federalCodes = await fetchData(url);
        if (federalCodes) {
            setFederalCodes(federalCodes)
            fedralCodes = federalCodes;
        }


    };

    //Income type Label change event
    const changeIncomeType = () => {
        var lbl = document.getElementById("IsFixedAmount").checked;
        var rad = document.querySelector('input[name="formHorizontalRadios"]:checked').value;
        HideControls(rad);
    }
    const HideControls = (id) => {

        if (id == 1) {
            document.getElementById("btnCalculate").innerHTML = "Calculate Net Pay";
            var lbl = document.getElementById("IsFixedAmount").checked;
            if (lbl) {

                //document.getElementById("grossPayLabel").textContent = "Gross/Hours";
                document.getElementById("grossPayLabel").innerHTML = "Gross/Hours<span style='color: red;'>*</span>";

            }
            else {
                document.getElementById("grossPayLabel").innerHTML = "Gross/Period<span style='color: red;'>*</span>";

                // document.getElementById("grossPayLabel").textContent = "Gross/Period";

            }
        }
        else if (id == 2) {
            document.getElementById("btnCalculate").innerHTML = "Calculate Gross Pay";
            var lbl = document.getElementById("IsFixedAmount").checked;
            if (lbl) {
                document.getElementById("grossPayLabel").innerHTML = "Net/Hours<span style='color: red;'>*</span>";
            }
            else {
                document.getElementById("grossPayLabel").innerHTML = "Net/Period<span style='color: red;'>*</span>";
            }

        }
    }
 

    //    validation
    const validate = () => {
        const newErrors = {};
        if (!yearValue) {
            newErrors.yearValue = 'Please provide tax year';
        }
        if (!countryValue) {
            newErrors.countryValue = 'Please provide country';
        }
        if (!provinceValue) {
            newErrors.provinceValue = 'Please provide province';
        }
        if (!txtRateValue) {
            newErrors.txtRateValue = 'Please provide pay rate';
        }
        if (!payrollTypevalue) {
            newErrors.payrollTypevalue = 'Please provide pay frequency';
        }
        if (!federalValue) {
            newErrors.federalValue = 'Please provide federal code';
        }
        if (!provincialCodeValue) {
            newErrors.provincialCodeValue = 'Please provide provincial code';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmitForNet = async (event) => {
        var rad = document.querySelector('input[name="formHorizontalRadios"]:checked').value;
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const data = {
            "Type": rad,
            "TaxYearId": document.getElementById('fiscalYear').value,
            "CountryId": document.getElementById('ddlCountry').value,
            "ProvinceId": document.getElementById('ddlProvince').value,
            "PayRate": document.getElementById('txtRate').value,
            "IsFixedAmount": document.getElementById("IsFixedAmount").checked == true ? false : true,
            "PayPeriodsInYear": document.getElementById("txtPayPeriods").value,
            "HoursPerWeek": document.getElementById("txtHoursPerWeek").value,
            "OtherTaxableIncomePerPay": 0,
            "OtherDeductionPerPay": 0,
            "grossPay": 0,
            "FedExamptedTax": document.getElementById("txtFedAmount").value,
            "ProvExamptedTax": document.getElementById("txtProvinceAmount").value,
            "IsCppExampted": document.getElementById("IsCppExampt").checked,
            "IsEiExampted": document.getElementById("IsEiExampt").checked

        }
        // alert(data.IsFixedAmount)
        event.preventDefault();

        try {
            const isValid = validate();
            if (isValid) {
                // Make the POST request using Axios
                const response = await axios.post('https://vtacpayroll.cloud:444/Calculator/CaculatePayroll', data);

                setTaxResult(response.data.Response);
                //    alert(response.data.Response.NetPayPerYear)
            } else {
                return false;
            }
        } catch (error) {
            console.error(error);
        }
    };



    const clearError = (field) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: '',
        }));
    };

    // const handleRefresh = () => {
    //     window.location.reload();
    // }

    //  it for chart view
    const data = [
        // { name: 'Salary/Pay Rate', value: taxResult.PayRate },
        // { name: 'Gross Income', value: taxResult.GrossIncomePerPay },
        { name: 'Fedaral Tax', value: taxResult.FedaralTaxPerPay },
        { name: 'Provincial Tax', value: taxResult.ProvincialTaxPerPay },
        // { name: 'Tax Deduction', value: taxResult.TotalTaxDeductionPerPay },
        { name: 'CPP', value: taxResult.CppDeductionPerPay },
        { name: 'EI', value: taxResult.EIDeductionPerPay },
        // { name: 'Total Income Deduction', value: taxResult.TotalIncomeDeductionPerPay },
        { name: 'Net Pay', value: taxResult.NetPayPerPay },
        // Add more data points as needed
    ];

    const colors = ['#E6001F', '#8884d8', '#82ca9d', '#ffc658', '#ff8042']
    return (

        <Fragment>
            <Container className='SectionBetween'>
                <Row>
                    <Col lg={7} md={6} sm={12}  >
                        <div className="calculator-card" id="RQForm" data-aos="fade-right"
                            data-aos-anchor="#example-anchor"
                            data-aos-offset="500"
                            data-aos-duration="2000">
                            <h3 className="quotationTitle mb-5">Payroll Calculator</h3>
                            <Form>
                                <Form.Group as={Row} className="mb-5" controlId="formHorizontalEmail">
                                    <Form.Label column sm={2}>

                                    </Form.Label>
                                    <Col sm={10}>

                                        <Row>
                                            <Col><Form.Check
                                                type="radio"
                                                label="Gross to Net Pay"
                                                name="formHorizontalRadios"
                                                id="formHorizontalRadios1"
                                                defaultChecked={true}
                                                value={1}
                                                onClick={HideControls.bind(null, 1)}
                                            /></Col>
                                            <Col>  <Form.Check
                                                type="radio"
                                                label="Net to Gross Pay"
                                                name="formHorizontalRadios"
                                                id="formHorizontalRadios1"
                                                value={2}
                                                onClick={HideControls.bind(null, 2)}
                                            /></Col>
                                        </Row>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={3}>
                                        Tax Year<span style={{ color: 'red' }}>*</span>
                                    </Form.Label>
                                    <Col sm={6}>
                                        <span style={{ color: 'red' }}>{errors.yearValue}</span>
                                        <select id="fiscalYear" className='calculator-dropdown' name='taxYear' value={yearValue} onChange={changeYear} isInvalid={!!errors.netIncomeValue}>
                                            <option value="">&nbsp; Select Fiscal Year</option>
                                            {taxYear.map((item) => (
                                                <option key={item.FiscallYearId} value={item.FiscallYearId} >
                                                    &nbsp;{item.FiscallYearName}
                                                </option>
                                            ))}
                                        </select>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={3}>
                                        Country<span style={{ color: 'red' }}>*</span>
                                    </Form.Label>
                                    <Col sm={6}>
                                        <span style={{ color: 'red' }}>{errors.countryValue}</span>
                                        <select id='ddlCountry' className='calculator-dropdown' value={countryValue} onChange={changeCountry} >
                                            <option value={""}>&nbsp;Select Country</option>
                                            {country.map((item) => (
                                                <option key={item.CountryId} value={item.CountryId} >
                                                    &nbsp;{item.CountryName}
                                                </option>
                                            ))}
                                        </select>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={3}>
                                        Province<span style={{ color: 'red' }}>*</span>
                                    </Form.Label>
                                    <Col sm={6}>
                                        <span style={{ color: 'red' }}>{errors.provinceValue}</span>
                                        <select id='ddlProvince' className='calculator-dropdown' value={provinceValue} onChange={changeProvince}>
                                            <option value={""}>&nbsp;Select Province</option>
                                            {province.map((item) => (
                                                <option key={item.ProvinceId} value={item.ProvinceId} >
                                                    &nbsp;{item.ProvinceName}
                                                </option>
                                            ))}
                                        </select>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label id='grossPayLabel' column sm={3}>
                                        Gross/period<span style={{ color: 'red' }}>*</span>
                                    </Form.Label>
                                    <Col sm={6}>
                                        <span style={{ color: 'red' }}>{errors.txtRateValue}</span>
                                        <Form.Control id='txtRate' type="number" className='calculator-dropdown' placeholder="Pay rate / Amount" onChange={changetxtRate} />
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Check id='IsFixedAmount' type="checkbox" className='checkboxForm' label="Hourly Income" onClick={changeIncomeType}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={3}>
                                        Pay Frequency<span style={{ color: 'red' }}>*</span>
                                    </Form.Label>
                                    <Col sm={6}>
                                        <span style={{ color: 'red' }}>{errors.payrollTypevalue}</span>
                                        <select id='ddlFrequency' className='calculator-dropdown' value={payrollTypevalue} onChange={changePayrollType} >
                                            <option value={""}>&nbsp;Pay Period Frequency</option>
                                            {payrollType.map((item) => (
                                                <option key={item.PayrollTypeId} value={item.PayrollTypeId} >
                                                    &nbsp;{item.PayrollTypeName}
                                                </option>
                                            ))}
                                        </select>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={3}>
                                        Periods / Year<span style={{ color: 'red' }}>*</span>
                                    </Form.Label>
                                    <Col sm={6}>
                                        <Form.Control id='txtPayPeriods' className='calculator-dropdown' type="number" placeholder="Periods Per Year" disabled />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={3}>
                                        Hours / Period<span style={{ color: 'red' }}>*</span>
                                    </Form.Label>
                                    <Col sm={6}>
                                        <Form.Control id='txtHoursPerWeek' className='calculator-dropdown' type="number" placeholder="Hours Per Week" disabled />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={3}>
                                    </Form.Label>
                                    <Col sm={3}>
                                        <Form.Check id='IsCppExampt' type="checkbox" className='checkboxForm' label="CPP Exempt" value={0} />
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Check id='IsEiExampt' type="checkbox" className='checkboxForm' label="EI Exempt" value={0} />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={3}>
                                        Federal TD1<span style={{ color: 'red' }}>*</span>
                                    </Form.Label>
                                    <Col sm={6}>
                                        <span style={{ color: 'red' }}>{errors.federalValue}</span>
                                        <select id='ddlFederal' className='calculator-dropdown' value={federalValue} onChange={changeFederalTD} >
                                            <option value={""}>&nbsp;Select Federal Code</option>
                                            {federalCodes.map((item) => (
                                                <option key={item.TaxClaimCodeDetailsId} value={item.TaxClaimCodeDetailsId} >
                                                    &nbsp;{item.ClaimCode}
                                                </option>
                                            ))}
                                        </select>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={3}>

                                    </Form.Label>
                                    <Col sm={9}>

                                        <Form.Check id='IsFedOverride' type="checkbox" className='checkboxForm' label="Override Federal TD1 " checked={txtFedAmount}
                                            onClick={IsFedOverride} />

                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={3}>
                                        Fed Amount
                                    </Form.Label>
                                    <Col sm={6}>
                                        <Form.Control type="number" className='calculator-dropdown' id='txtFedAmount' placeholder="Federal Amount" disabled={!txtFedAmount} />
                                    </Col>
                                </Form.Group>


                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={3}>
                                        Provincial TD1<span style={{ color: 'red' }}>*</span>
                                    </Form.Label>
                                    <Col sm={6}>
                                        <span style={{ color: 'red' }}>{errors.provincialCodeValue}</span>
                                        <select id='ddlProvincial' className='calculator-dropdown' value={provincialCodeValue} onChange={changeProvincialTD} >
                                            <option value={""}>&nbsp;Select Provincial Code</option>
                                            {provincialCodes.map((item) => (
                                                <option key={item.TaxClaimCodeDetailsId} value={item.TaxClaimCodeDetailsId} >
                                                    &nbsp;{item.ClaimCode}
                                                </option>
                                            ))}
                                        </select>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={3}>

                                    </Form.Label>
                                    <Col sm={6}>

                                        <Form.Check id='IsProOverride' type="checkbox" className='checkboxForm' label="Override Provincial TD1" checked={txtProAmount}
                                            onClick={IsProOverride} />

                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={3}>
                                        Prov Amount
                                    </Form.Label>
                                    <Col sm={6}>
                                        <Form.Control id='txtProvinceAmount' className='calculator-dropdown' type="number" placeholder="Provincial Amount" disabled={!txtProAmount} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={3}></Col>
                                    <Col sm={5}>
                                        <Button id='btnCalculate' name='submitBtn' className="calculator-btn" variant="primary" onClick={handleSubmitForNet} >
                                            <span>Calculate Net Pay</span>
                                        </Button>
                                      
                                    </Col>
                                    {/* <Col sm={4}> <button className='calculator-btn ' onClick={handleRefresh}>Refresh</button></Col> */}

                                </Form.Group>
                            </Form>
                        </div>
                    </Col>
                    <Col lg={5} md={6} sm={12}>
                        <div className="calculator-resultcard" data-aos="fade-left"
                            data-aos-anchor="#example-anchor"
                            data-aos-offset="500"
                            data-aos-duration="2000">

                            <p className="quotationTitle">Payroll structure</p>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Cost</th>
                                        <th>Per Pay</th>
                                        <th>Per Year</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{ color: "indigo" }}>
                                        <td>Salary/Pay Rate</td>
                                        <td colspan="2">{taxResult.PayRate}</td>
                                    </tr>
                                    <tr style={{ color: "red" }}>
                                        <td>Gross Income</td>
                                        <td><span>{taxResult.GrossIncomePerPay}</span></td>
                                        <td><span>{taxResult.GrossIncomeInYear}</span></td>

                                    </tr>


                                    <tr>
                                        <td>Fedaral Tax</td>
                                        <td><span>{taxResult.FedaralTaxPerPay}</span></td>
                                        <td><span>{taxResult.FedaralTaxPerYear}</span></td>
                                    </tr>
                                    <tr>
                                        <td>Provincial Tax</td>
                                        <td><span id="ProvincialTaxPerPay">{taxResult.ProvincialTaxPerPay}</span></td>
                                        <td><span id="ProvincialTaxPerYear">{taxResult.ProvincialTaxPerYear}</span></td>
                                    </tr>
                                    <tr>
                                        <td>Total Tax Deduction</td>
                                        <td><span >{taxResult.TotalTaxDeductionPerPay}</span></td>
                                        <td><span >{taxResult.TotalTaxDeductionPerYear}</span></td>
                                    </tr>
                                    <tr>
                                        <td>CPP Deduction</td>
                                        <td><span>{taxResult.CppDeductionPerPay}</span></td>
                                        <td><span>{taxResult.CppDeductionPerYear}</span></td>
                                    </tr>
                                    <tr>
                                        <td>EI Deduction</td>
                                        <td><span>{taxResult.EIDeductionPerPay}</span></td>
                                        <td><span>{taxResult.EIDeductionPerYear}</span></td>
                                    </tr>
                                    <tr>
                                        <td>Total Income Deduction</td>
                                        <td><span>{taxResult.TotalIncomeDeductionPerPay}</span></td>
                                        <td><span>{taxResult.TotalIncomeDeductionPerYear}</span></td>
                                    </tr>
                                    <tr style={{ color: "red" }}>
                                        <td>Net Pay</td>
                                        <td><span>{taxResult.NetPayPerPay}</span></td>
                                        <td><span>{taxResult.NetPayPerYear}</span></td>
                                    </tr>
                                </tbody>
                            </Table>
                            <div>

                                <ResponsiveContainer width="100%" height={405}>
                                    <PieChart>
                                        <Pie

                                            data={data}
                                            dataKey="value"
                                            nameKey="name"
                                            cx="50%"
                                            cy="50%"
                                            innerRadius={80} // Adjust this value to control the size of the inner circle (donut hole)
                                            outerRadius={100} // Adjust this value to control the size of the outer circle (donut)
                                            fill='#8884d8'
                                            label={(entry) => entry.name}

                                        >
                                            {data.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                            ))}
                                            <Label style={{ fontSize: "16", fontWeight: "600" }} value={taxResult.NetPayPerPay} position="center" />
                                        </Pie>
                                        <Tooltip />
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default PayrollCalculator;