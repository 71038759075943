import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import s2 from '../../asset/images/s2.png';
import S1 from '../../asset/images/S1.png';


class PayrollServicesSisPart extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className='SectionBetween sixSectionContainer'>
                    <div>
                        <Container>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <div className='text-center container' style={{ "width": "70%" }} >
                                        <h2 className='sixSectionContainerTitle '><span className='spancolor'>SUBMIT PAYROLL</span> VIA VPM’S ONLINE PLATFORM OR BY EMAIL</h2>

                                        <p className='sixSectionContainerPra'>Get the same robust suite of payroll reporting with less complexity and risk by using our online web technology or through email. Save time with technology and have it work for you in a way that respects your time.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                <div className='text-center'>  <img className="securityEmailImg" src={S1} alt="online" /></div>
                                    <h2 className='helpSmallBusinessTitle mt-3 text-center'><strong>Use our online platform</strong></h2>
                                    <p className='desc'>VPM is a secure propriety payroll system that gives your managers and employees easy online access to a web-based portal for self-serve reporting of pay stubs, and a suite of payroll reports.</p>
                                    <p className='desc'>Employees can securely access their accounts, and designated company managers can access payroll reports for every employee and the organization.</p>
                                </Col>

                                <Col lg={6} md={6} sm={12}>
                                <div className='text-center'>  <img className="securityEmailImg"  src={s2} alt="secure-email" /></div>
                                    <h2  className='helpSmallBusinessTitle mt-3 text-center'><strong>Send files via secure email</strong></h2>
                                    <p className='desc'>Employees and managers can receive all the same payroll reports by emailing in automated password-protected PDF files.</p>
                                    <p className='desc'>Save time each pay period and eliminate the need to login to any system for routine and regularly occurring reporting.</p>
                                </Col>
                            </Row>

                        </Container>
                    </div>



                </Container>
            </Fragment>
        );
    }
}

export default PayrollServicesSisPart;