import React, { Component, Fragment } from 'react';
import { Container } from 'react-bootstrap';
import '../../asset/css/custom.css';
import '../../asset/css/bootstrap.min.css';


class FAQNavigation extends Component {

    componentDidMount() {
        document.getElementById("div1").style.display = "block";
        document.getElementById("div2").style.display = "none";
        document.getElementById("div3").style.display = "none";
        document.getElementById("div4").style.display = "none";
        document.getElementById("div5").style.display = "none";
    }

    FAQnavHandleClick(id) {

        if (id === "nav1") {
            document.getElementById("div1").style.display = "block";
            document.getElementById("div2").style.display = "none";
            document.getElementById("div3").style.display = "none";
            document.getElementById("div4").style.display = "none";
            document.getElementById("div5").style.display = "none";


        }
        else if (id === 'nav2') {
            document.getElementById("div1").style.display = "none";
            document.getElementById("div2").style.display = "block";
            document.getElementById("div3").style.display = "none";
            document.getElementById("div4").style.display = "none";
            document.getElementById("div5").style.display = "none";


        }
        else if (id === 'nav3') {

            document.getElementById("div3").style.display = "block";
            document.getElementById("div1").style.display = "none";
            document.getElementById("div2").style.display = "none";
            document.getElementById("div4").style.display = "none";
            document.getElementById("div5").style.display = "none";


        }
        else if (id === 'nav4') {
            document.getElementById("div1").style.display = "none";
            document.getElementById("div2").style.display = "none";
            document.getElementById("div3").style.display = "none";
            document.getElementById("div4").style.display = "block";
            document.getElementById("div5").style.display = "none";


        }
        else if (id === 'nav5') {
            document.getElementById("div1").style.display = "none";
            document.getElementById("div2").style.display = "none";
            document.getElementById("div3").style.display = "none";
            document.getElementById("div4").style.display = "none";
            document.getElementById("div5").style.display = "block";


        }
    }

    handleClick(id) {
        var coll = document.getElementById(id);
        coll.classList.toggle("Nactive");
        var content = coll.nextElementSibling;
        if (content.style.maxHeight) {
            content.style.maxHeight = null;
        } else {
            content.style.maxHeight = content.scrollHeight + "px";
        }
    }

    render() {
        return (
            <Fragment>
                <Container fluid={true} className="SectionBetween" >
                    <div className='container' style={{ width: "80%" }}>
                        <div className='faqnavbar' >
                            <a className='active' id='nav1' onClick={() => this.FAQnavHandleClick('nav1')}>Payroll Services</a>
                            <a id='nav2' onClick={() => this.FAQnavHandleClick('nav2')}>Technology</a>
                            <a id='nav3' onClick={() => this.FAQnavHandleClick('nav3')}>Switching to VPM</a>
                            <a id='nav4' onClick={() => this.FAQnavHandleClick('nav4')}>Pricing</a>
                            <a id='nav5' onClick={() => this.FAQnavHandleClick('nav5')}>About VPM</a>
                        </div>
                        <div className='Ncard mt-3' id='div1'>
                            <div className=" mt-3"><h2 className='NavFAQTitle' >PAYROLL SERVICES
                            </h2>
                            </div>

                            <button class="Ncollapsible" id='b1' onClick={() => this.handleClick('b1')}>Can VPM track and report payroll by department?</button>
                            <div class="Ncontent">
                                <p className='FaqDescription'>Yes. As part of the on-boarding process, we will ensure our systems are set up to reflect how your organization is structured in order to meet your departmental reporting needs.</p>
                            </div>
                            <button class="Ncollapsible" id='b2' onClick={() => this.handleClick('b2')}>How does VPM pay a client’s taxes?</button>
                            <div class="Ncontent">
                                <p className='FaqDescription'>Taxes are submitted to the government electronically, with source deductions (CPP, EI, and Tax – Federal & Provincial including EHT in Ontario) submitted on your behalf.</p>
                            </div>
                            <button class="Ncollapsible" id='b3' onClick={() => this.handleClick('b3')}>Does VPM stay up-to-date with new payroll rules and legislations?</button>
                            <div class="Ncontent">
                                <p className='FaqDescription'>Yes. We follow all federal and provincial government changes affecting payroll. We add additional value to some client businesses by privately advising when a client might be offside on any legal obligation with their employees as well.</p>
                            </div>
                            <button class="Ncollapsible" id='b4' onClick={() => this.handleClick('b4')}>Can you direct deposit payroll into our employees accounts?</button>
                            <div class="Ncontent">
                                <p className='FaqDescription'>Yes, we can do that.</p>
                            </div>
                            <button class="Ncollapsible" id='b5' onClick={() => this.handleClick('b5')}>Do you offer payroll services to individuals or families?</button>
                            <div class="Ncontent">
                                <p className='FaqDescription'>Yes. For example, we can manage payroll for a family that has hired a nanny and does not want to manage their payroll and related reporting requirements.</p>
                            </div>
                            <button class="Ncollapsible" id='b6' onClick={() => this.handleClick('b6')}>What reports will I get as a new client?</button>
                            <div class="Ncontent">
                                <p className='FaqDescription'>We offer a robust suite of payroll reports for you and your business, such as employee paystubs and direct deposit statements and summaries, timesheet income listing for verification, the current payroll register, GL reporting for any accounting system reporting requirements, and more. Additionally, of course, all government and CRA required reports are sent to you in copy as well for your records.</p>
                            </div>
                            <button class="Ncollapsible" id='b7' onClick={() => this.handleClick('b7')}>Are you able to process payroll in Quebec?</button>
                            <div class="Ncontent">
                                <p className='FaqDescription'>Yes. Please contact us for more details.</p>
                            </div>
                            <button class="Ncollapsible" id='b8' onClick={() => this.handleClick('b8')}>Can you provide services on remittance of statutory deductions (Income Tax, CPP, EI, WSIB)?</button>
                            <div class="Ncontent">
                                <p className='FaqDescription'>Yes. We perform all regulatory reporting and CRA remittances for Income Tax, CPP, EI, WSIB, Health Tax, Garnishments, Union Dues, and Statistics Canada submissions.</p>
                            </div>

                            <button class="Ncollapsible" id='b9' onClick={() => this.handleClick('b9')}>Can I get a copy of sample payroll reports?</button>
                            <div class="Ncontent">
                                <p className='FaqDescription'>Yes, please contact us and we’ll send you a copy.</p>
                            </div>
                            <button class="Ncollapsible" id='b10' onClick={() => this.handleClick('b10')}>Can your system help manage HR?</button>
                            <div class="Ncontent">
                                <p className='FaqDescription'>It really depends on the business, but for most cases no. Human resources management is a very large function, and while we track all employee information needed for payroll, this information may or may not satisfy typical HR management business needs.</p>
                            </div>
                        </div>

                        <div className='Ncard mt-3' id='div2'>
                            <div className="mt-3"><h2 className='NavFAQTitle' >
                                Technology
                            </h2>
                            </div>



                            <button class="Ncollapsible" id='b21' onClick={() => this.handleClick('b21')}>Will VPM integrate payroll reporting with my organization's general ledger?</button>
                            <div class="Ncontent">
                                <p className='FaqDescription'>Yes.</p>
                            </div>
                            <button class="Ncollapsible" id='b22' onClick={() => this.handleClick('b22')}>Can you work with the ePost mailbox for paystubs and other employee payroll reports?</button>
                            <div class="Ncontent">
                                <p className='FaqDescription'>Yes. Canada’s ePost and online mailbox service charges a fee for use, and we will pass this fee through to you in our invoicing.</p>
                            </div>
                            <button class="Ncollapsible" id='b23' onClick={() => this.handleClick('b23')}>Can you provide General Ledger suport and integration with our accounting system?</button>
                            <div class="Ncontent">
                                <p className='FaqDescription'>Yes. We have many clients who require this and our system can map GL codes and report numbers to accounting as needed.</p>
                            </div>
                            <button class="Ncollapsible" id='b24' onClick={() => this.handleClick('b24')}>Can you integrate with 3rd-party timesheet mobile apps?</button>
                            <div class="Ncontent">
                                <p className='FaqDescription'>Yes. We can build a 3rd-party timesheet integration with our system securely using financial best practices for technology. You will pay the owner of the app for the use of their app, and there will likely be a small one-time paid to VPM for this integration.</p>
                            </div>
                            <button class="Ncollapsible" id='b25' onClick={() => this.handleClick('b25')}>Is my information secure?</button>
                            <div class="Ncontent">
                                <p className='FaqDescription'>Yes. Online we use encryption that is identical to what you use when you bank online. For emails we password protect all documents and paystubs. Internally our system is accessed through similar encryption with strong passwords used.</p>
                            </div>
                        </div>

                        <div className='Ncard mt-3' id='div3'>
                            <div className="mt-3"><h2 className='NavFAQTitle' >
                                SWITCHING TO VPM
                            </h2>
                            </div>


                            <button class="Ncollapsible" id='b31' onClick={() => this.handleClick('b31')}>How do I switch my payroll to VPM?</button>
                            <div class="Ncontent">
                                <p className='FaqDescription'>Please review our detailed Making the Switch page.</p>
                            </div>
                            <button class="Ncollapsible" id='b32' onClick={() => this.handleClick('b32')}>Is it true you don't charge new clients any onboarding costs?</button>
                            <div class="Ncontent">
                                <p className='FaqDescription'>Yes.</p>
                            </div>
                            <button class="Ncollapsible" id='b33' onClick={() => this.handleClick('b33')}>Do we need to wait until a year-end to join VPM?</button>
                            <div class="Ncontent">
                                <p className='FaqDescription'>No, not at all, and in fact it’s rare. You can switch your payroll to VPM from in-house or from any service provider (ADP, Ceridian etc.) at any time of the year.</p>
                            </div>
                            <button class="Ncollapsible" id='b34' onClick={() => this.handleClick('b34')}>At year-end reporting after switching to VPM, will our employees get two T4s?</button>
                            <div class="Ncontent">
                                <p className='FaqDescription'>No, not at all. To serve you better we perform for you a free year-to-date payroll history load or update in our system, so your organization and your employees have less confusion with any year-to-date reporting for taxes or government filing. And if we find any issues in the reconciliation resulting in an error of any kind from the old process, we will help you quickly resolve it. We believe we’re only payroll service provider in Canada that offers this additional free onboarding service to its clients.</p>
                            </div>
                        </div>

                        <div className='Ncard mt-3' id='div4'>
                            <div className="mt-3"><h2 className='NavFAQTitle' >
                                PRICING
                            </h2>
                            </div>


                            <button class="Ncollapsible" id='b41' onClick={() => this.handleClick('b41')}>How much does payroll cost? Is it standard?</button>
                            <div class="Ncontent">
                                <p className='FaqDescription'>VPM currently deploys custom pricing because we can often give a better price to new clients with some basic company information communicated to us through a price quote request.</p>
                                <p className='FaqDescription'>Furthermore, in some cases, given our high efficiencies we’ve been asked to take on responsibilities that go slightly beyond what a typical Canadian payroll service provider would agree to (such as processing departmental allocations of payroll), creating additional value for clients by lowering their total costs.</p>
                            </div>
                        </div>

                        <div className='Ncard mt-3' id='div5'>
                            <div className="mt-3"><h2 className='NavFAQTitle' >
                                ABOUT VPM
                            </h2>
                            </div>


                            <button class="Ncollapsible" id='b51' onClick={() => this.handleClick('b51')}>How long have you been in business?</button>
                            <div class="Ncontent">
                                <p className='FaqDescription'>VPM has been managing payroll for Canadian businesses and nonprofits since 1997.</p>
                            </div>
                            <button class="Ncollapsible" id='b52' onClick={() => this.handleClick('b52')}>Is VPM the same as V-TAC Payroll Management?</button>
                            <div class="Ncontent">
                                <p className='FaqDescription'>VPM shortened its name from V-Tac Payroll Management in 2018. It is the same company.</p>
                            </div>
                            <button class="Ncollapsible" id='b53' onClick={() => this.handleClick('b53')}>Is VPM a Canadian company?</button>
                            <div class="Ncontent">
                                <p className='FaqDescription'>Yes. We are owned and managed by Canadians. With our offices in Toronto and Calgary, we manage payroll for many thousands of Canadians across Canada. At present, we do not offer services in any other countries.</p>
                            </div>

                        </div>
                    </div>
                </Container>
            </Fragment >
        );
    }
}

export default FAQNavigation;