import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import hospitality1 from '../../asset/images/hospitality-1.jpg';
import hospitality2 from '../../asset/images/hospitality-2.jpg';

class HospitalityIndustryReduceRisk extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className='SectionBetween  ' >
                    <div className='SectionBetween p-5'>
                        <Container >
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="ReducetheriskHospitalityimg1" alt='Person checking in at a hotel' title='Person checking in at a hotel' src={hospitality1} />
                                </Col>
                                <Col lg={6} md={6} sm={12}>

                                    <div ><h3 class="commonTitle "><strong className='spancolor'>Reduce the risk</strong> of human error when paying employees</h3>
                                        <p className='commonDesc'>Mistakes can be costly to resolve. High employee turnover, multiple positions and wages, and different departments under one roof. Managing payroll in the restaurant industry can be tedious and complicated.</p>
                                        <p className='commonDesc'>Eliminate payroll headaches with a solution that can handle different wage structures (part-time, full-time, contracts, &amp; tips) and employee compliance. Quickly incorporate vacation time, benefits and time-off.</p>
                                        <p className='commonDesc'>Let VPM do the heavy-lifting for you.</p></div>
                                

                            </Col>
                        </Row>
                        <Row className='SectionBetween'>
                            <Col lg={6} md={6} sm={12} >
                                <h2 className='commonTitle'><span className='spancolor'>Manage your workforce</span> & gain valuable insights</h2>
                                <p className='commonDesc'>Track all schedules and shifts precisely and view insights on age, seniority, work history, skills, salary/wage increases.</p>
                                <p className='commonDesc'>Extract a report breakdown per employee to gain insights into scheduling and payments.</p>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <img className="ReducetheriskHospitalityimg2 float-right " src={hospitality2} alt="Industrial kitchen staff" title='Industrial kitchen staff' />
                            </Col>
                        </Row>
                </Container>
            </div>

                </Container >
            </Fragment >
        );
    }
}

export default HospitalityIndustryReduceRisk;