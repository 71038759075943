import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faBookmark, faSearch, faDollarSign, faCheck, faCalendarAlt,faUserPlus,faDesktop,faComments,faMobileAlt,faHandsHelping,faExclamationTriangle, faClock} from '@fortawesome/free-solid-svg-icons';


class UnifiedHRSection3 extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className='serviceContainer2 SectionBetween'>

                    <div className='serviceSection'>
                        <h2 className='cardMainTitle ' >
                            WHY <span className='spancolor '>SWITCH</span> TO VPM</h2>
                        <Container>
                            <Row>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                                <FontAwesomeIcon className="cardlogoImg" icon={faDollarSign} />
                                                <Card.Title><h4 class="serviceCardTitle"><span> Integrate Payroll with Benefit Providers</span></h4></Card.Title>
                                                <Card.Text>
                                                    <p className='serviceCardDes'>Have employee benefits and deductions reflect in payroll correctly.</p>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                                <FontAwesomeIcon className="cardlogoImg" icon={faCheck} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>Ensure Government Compliance</span></h4></Card.Title>
                                                <Card.Text>
                                                    <p className='serviceCardDes'>Rest assured that VPM has strict adherence to and knowledge of federal, provincial labour laws and regulations.</p>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                            <FontAwesomeIcon className="cardlogoImg" icon={faCalendarAlt} />
                                                <Card.Title><h4 class="serviceCardTitle"><span> Manage Vacation Time</span></h4></Card.Title>
                                                <Card.Text>
                                                    <p className='serviceCardDes'>Oversee the accrual of vacation time taken/earned by employees.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                            <FontAwesomeIcon className="cardlogoImg" icon={faUserPlus} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>Onboard New Hires Quickly</span></h4></Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>Easily setup new employees in the system.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                            </Row>
                            <Row className=''>

                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                            <FontAwesomeIcon className="cardlogoImg" icon={faDesktop} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>Access VPM Anywhere</span></h4></Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>Connect to your VPM from anywhere in Canada on your mobile or computer.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                            <FontAwesomeIcon className="cardlogoImg" icon={faMobileAlt} />
                                                <Card.Title ><h4 class="serviceCardTitle"><span>Give Employees Online Access</span></h4></Card.Title>
                                                <Card.Text>
                                                    <p className='serviceCardDes'>Your employees can update personal information, enroll in benefits, manage sick leave, and more.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                            <FontAwesomeIcon className="cardlogoImg" icon={faSearch} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>Track All Employee- Related Data</span></h4></Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>Access insights on age, seniority, work history, skills, salary/wage increases.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                            <FontAwesomeIcon className="cardlogoImg" icon={faComments} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>Fast Response Time</span></h4></Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>Get help in 30 minutes or less, guaranteed!</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                            </Row>
                            <Row>

                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                            <FontAwesomeIcon className="cardlogoImg" icon={faBookmark} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>25+ Yrs of Experience</span></h4></Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>A dedicated payroll service developed with real businesses in mind.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                            <FontAwesomeIcon className="cardlogoImg" icon={faHandsHelping} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>Less Hands-On</span></h4></Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>Spend less time trying to prepare your payroll documents, let us do all the work for you.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                            <FontAwesomeIcon className="cardlogoImg" icon={faExclamationTriangle} />
                                                <Card.Title class="serviceCardTitle">Complete Confidentiality</Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>All information is safe, and handled securely on our cloud platform, with a disaster recovery plan in place.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={6}>

                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                            <FontAwesomeIcon className="cardlogoImg" icon={faClock} />
                                                <Card.Title class="serviceCardTitle">Quick Setup</Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>Start using our payroll services within 48 hours no matter the size of your business!</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                            </Row>
                            <div className='text-center mt-3'><Link to="/business-payroll-services"><Button variant="primary" className='button2 text-center'><span>EXPLORE ALL FEATURES</span></Button></Link></div>
                        </Container>

                    </div>



                </Container>
            </Fragment>
        );
    }
}

export default UnifiedHRSection3;