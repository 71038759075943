import React, { Fragment } from 'react';
import Footer from '../components/Footer/Footer';
import TopHead from '../components/TopHead/TopHead';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import PayrollCalculator from '../components/PayrollCalculator/PayrollCalculator';
import ContactBanner from '../components/ContactBanner/ContactBanner';
import bannerImage from '../../src/asset/images/calculator-ban.jpg';
const PayrollCalculatorPage = () => {
 //  const headerData = { title: 'Payroll Calculator' };
    return (
        <Fragment>
            <TopHead />
            <TopNavigation title="Payroll Calculator | V-TAC Payroll Management " />
            {/* <div style={{clear:'both'}}></div> */}
            {/* <TopBanner /> */}
            <ContactBanner image={bannerImage} bannerTitle={""}/>
            <PayrollCalculator />
            <TradePart />
            <Footer />
        </Fragment>
    );
};

export default PayrollCalculatorPage;