import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMousePointer, faCalendar, faBan, faArrowsAlt, faSearch, faExchangeAlt, faMobileAlt, faCheck, faComments } from '@fortawesome/free-solid-svg-icons';
import filechartline from '../../asset/images/file-chart-line.svg';
import document from '../../asset/images/document.svg';


class ConstWhySwitchVpm extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className='serviceContainer2 SectionBetween'>

                    <div className='serviceSection'>
                        <h2 className='cardMainTitle'><strong className='spancolor'>Gain control</strong> of your payroll with VPM</h2>

                        <Container>
                            <Row>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                                <FontAwesomeIcon className="cardlogoImg" icon={faCalendar} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>Run Additional Payrolls Off Cycle</span></h4></Card.Title>
                                                <Card.Text>
                                                    <p className='serviceCardDes'>Setup special cycle payroll runs depending on your needs.</p>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                            <FontAwesomeIcon className="cardlogoImg" icon={faArrowsAlt} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>Customizable to Your Needs</span></h4></Card.Title>
                                                <Card.Text>
                                                    <p className='serviceCardDes'>Adapt VPM to your unique construction business needs at no cost.</p>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                                <Card.Img className="cardlogoImg" src={document} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>Get Detailed Employee Reports</span></h4></Card.Title>
                                                <Card.Text>
                                                    <p className='serviceCardDes'>Extract a report breakdown per employee, Doctor to gain insights into scheduling and payments.</p>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                                <FontAwesomeIcon className="cardlogoImg" icon={faCheck} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>Pay Contractors & Subcontractors</span></h4></Card.Title>
                                                <Card.Text>
                                                    <p className='serviceCardDes'>Setup contractor payments for work completed. Make direct deposits to employees’ bank accounts through e-payroll.</p>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                                <FontAwesomeIcon className="cardlogoImg" icon={faMousePointer} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>Easy to Use</span></h4></Card.Title>
                                                <Card.Text>
                                                    <p className='serviceCardDes'>No more headaches when it’s time for payroll. Intuitive simple design for maximum productivity.</p>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                                <FontAwesomeIcon className="cardlogoImg" icon={faMobileAlt} />
                                                <Card.Title ><h4 class="serviceCardTitle"><span>Access from Anywhere</span></h4></Card.Title>
                                                <Card.Text>
                                                    <p className='serviceCardDes'>Connect to VPM from anywhere in Canada on your mobile or computer.</p>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                                <FontAwesomeIcon className="cardlogoImg" icon={faSearch} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>Track All Employee- Related Data</span></h4></Card.Title>
                                                <Card.Text>
                                                    <p className='serviceCardDes'>Access insights on age, seniority, work history, skills, salary/wage increases.</p>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                                <FontAwesomeIcon className="cardlogoImg" icon={faComments} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>Fast Response Time</span></h4></Card.Title>
                                                <Card.Text>
                                                    <p className='serviceCardDes'>Get help in 30 minutes or less, guaranteed!</p>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                                <Card.Img className="cardlogoImg" src={filechartline} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>Gain Insights with GL</span></h4></Card.Title>
                                                <Card.Text>
                                                    <p className='serviceCardDes'>Know where your money is going and keep track of all financial data.</p>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                                <FontAwesomeIcon className="cardlogoImg" icon={faBan} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>No Contracts</span></h4></Card.Title>
                                                <Card.Text>
                                                    <p className='serviceCardDes'>Unlike with other payroll providers, we don't lock you into contracts. Use VPM for as long as you need.</p>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                                <FontAwesomeIcon className="cardlogoImg" icon={faEdit} />
                                                <Card.Title class="serviceCardTitle">Fast & Accurate ROE</Card.Title>
                                                <Card.Text>
                                                    <p className='serviceCardDes'>Get your ROE completed and submitted on your behalf.</p>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                            <FontAwesomeIcon className="cardlogoImg" icon={faExchangeAlt} />
                                                <Card.Title class="serviceCardTitle">Integrate with Third-Parties</Card.Title>
                                                <Card.Text>
                                                    <p className='serviceCardDes'>Customize your payroll in regards to taxes, unions, deductions, benefits, and paid/unpaid leave.</p>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                            </Row>
                            <div className='text-center mt-3'><Link to="/business-payroll-services"><Button variant="primary" className='button2 text-center'><span>EXPLORE ALL FEATURES</span></Button></Link></div>
                        </Container>
                    </div>
                </Container>
            </Fragment>
        );
    }
}

export default ConstWhySwitchVpm;