import React, { Component, Fragment } from 'react';
import TopHead from '../components/TopHead/TopHead';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import ConstructionBanner from '../components/ConstructionBanner/ConstructionBanner';
import ConstructionPayroll from '../components/ConstructionPayroll/ConstructionPayroll';
import ConstructionOrConstructorSlide from '../components/ConstructionOrConstructorSlide/ConstructionOrConstructorSlide';
import AskQuesConstructionOrConstructor from '../components/AskQuesConstructionOrConstructor/AskQuesConstructionOrConstructor';
import ConstWhySwitchVpm from '../components/ConstWhySwitchVpm/ConstWhySwitchVpm';
import QuoteComponentGray2 from '../components/QuoteComponentGray2/QuoteComponentGray2';
import QuoteComponentGray from '../components/QuoteComponentGray/QuoteComponentGray';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';


class ConstructionOrConstructorPage extends Component {
    render() {
        return (
            <Fragment>
                <TopHead />
                <TopNavigation title="Construction/Constructors | V-TAC Payroll Management" />
                <ConstructionBanner />
                <ConstructionPayroll />
                <ConstWhySwitchVpm />
                <ConstructionOrConstructorSlide />
                <QuoteComponentGray2 />
                <AskQuesConstructionOrConstructor />
                <QuoteComponentGray />
                <TradePart />
                <Footer />
            </Fragment>
        );
    }
}

export default ConstructionOrConstructorPage;