import React, { Component, Fragment } from 'react';
import TopHead from '../components/TopHead/TopHead';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import QuoteComponentCyan from '../components/QuoteComponentCyan/QuoteComponentCyan';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import SchindlerBanner from '../components/SchindlerBanner/SchindlerBanner';
import SchindlerSec1 from '../components/SchindlerSec1/SchindlerSec1';
import SchindlerFeedback1 from '../components/SchindlerFeedback1/SchindlerFeedback1';
import SchindlerFeedback2 from '../components/SchindlerFeedback2/SchindlerFeedback2';
import SchindlerSec2 from '../components/SchindlerSec2/SchindlerSec2';


class SchindlerAssociatesPage extends Component {
    render() {
        return (
            <Fragment>
                <TopHead />
                <TopNavigation title="Dr. Schindler & Associates | V-TAC Payroll Management" />
                <SchindlerBanner />
                <SchindlerSec1 />
                <SchindlerFeedback1 />
                <SchindlerSec2 />
                <SchindlerFeedback2 />
                <QuoteComponentCyan />
                <TradePart />
                <Footer />
            </Fragment>
        );
    }
}

export default SchindlerAssociatesPage;