import React, { Component, Fragment } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import alzheimersocietylogo from '../../asset/images/alzheimersociety-logo.png';
import apglogo from '../../asset/images/apg-logo.png';
import bestwesternlogo from '../../asset/images/bestwestern-logo.png';
import danielbrownlogo from '../../asset/images/danielbrown-logo.png';
import drgauravlogo from '../../asset/images/drgaurav-logo.png';
import eclipselogo from '../../asset/images/eclipse-logo.png';

import fakelogo from '../../asset/images/fake-logo.png';
import fasgaslogo from '../../asset/images/fasgas-logo.png';
import freshiilogo from '../../asset/images/freshii-logo.png';
import groverlogo from '../../asset/images/grover-logo.png';
import herologo from '../../asset/images/hero-logo.png';
import ignitelogo from '../../asset/images/ignite-logo.png';
import kfclogo from '../../asset/images/kfc-logo.png';
import kingswaylogo from '../../asset/images/kingsway-logo.png';
import mollymaidlogo from '../../asset/images/mollymaid-logo.png';
import payrollcalogo from '../../asset/images/payroll-ca-logo.png';
import pizzhutlogo from '../../asset/images/pizzhut-logo.png';
import royalcanadianlegionlogo from '../../asset/images/royalcanadianlegion-logo.png';
import shelllogo from '../../asset/images/shell-logo.png';

import sourcelogo from '../../asset/images/source-logo.png';
import subwaylogo from '../../asset/images/subway-logo.png';
// import tbotlogo from '../../asset/images/tbot-logo.png';
import theberlinlogo from '../../asset/images/theberlin-logo.png';
import tophatlogo from '../../asset/images/tophat-logo.png';
import vislogo from '../../asset/images/vis-logo.png';
import wimpyslogo from '../../asset/images/wimpys-logo.png';





class Client extends Component {
  render() {
    var settings = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 3000,
      slidesToShow: 6,
      slidesToScroll: 3,
      autoplay: true,
      autoplaySpeed: 2000,


      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll:2,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
            initialSlide:1
          }
        },
        
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <Fragment>

        <Container className="slideShowSection SectionBetween">
          <h1 className='mainTitle text-center mb-5'><strong className='spancolor'>Trusted</strong> By:</h1>
          <Slider {...settings}>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={alzheimersocietylogo} loading="lazy"/></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={apglogo} loading="lazy"/></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={bestwesternlogo} loading="lazy"/></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={danielbrownlogo} loading="lazy"/></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={drgauravlogo} loading="lazy"/></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={eclipselogo} loading="lazy"/></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={fakelogo} loading="lazy"/></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={subwaylogo} loading="lazy"/></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={fasgaslogo} loading="lazy"/></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={freshiilogo} loading="lazy"/></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={theberlinlogo} loading="lazy"/></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={groverlogo} loading="lazy"/></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={herologo} loading="lazy"/></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={tophatlogo} loading="lazy"/></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={ignitelogo} loading="lazy"/></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={kfclogo} loading="lazy"/></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={kingswaylogo} loading="lazy"/></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={mollymaidlogo} loading="lazy"/></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={payrollcalogo} loading="lazy"/></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={pizzhutlogo} loading="lazy"/></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={royalcanadianlegionlogo} loading="lazy"/></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={shelllogo} loading="lazy"/></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={vislogo} loading="lazy"/></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={sourcelogo} loading="lazy" /></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={wimpyslogo} loading="lazy"/></a>
                </Col>
              </Row>
            </div>
          </Slider>


        </Container>
      </Fragment>
    );
  }
}

export default Client;