import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import Logo from '../../asset/images/v-tac-favicon.png';

import AOS from 'aos';
import 'aos/dist/aos.css';

class HomeQuoteCard extends Component {
    constructor() {
        super();
        this.state = {
            fullName: "",
            company: "",
            phone: "",
            email: "",
            numberOfEmployees: "",
            postalCode: "",
            message: "",
            hearFrom: "",
            errors: {},
            isVarified: false,
            recapvalue: "",

        }

    }

    //racapcha function
    recapchaValue = (value) => {
        const recapvalue = value;
        console.log("Captcha value:", recapvalue);
        this.setState({ recapvalue: value });
        this.setState({ isVarified: true });
    }
    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
        //this.setState({isVarified:true});

    }
    clearForm = () => {
        this.setState({
            fullName: "",
            company: "",
            phone: "",
            email: "",
            numberOfEmployees: "",
            payperperiod: "",
            province: "",
            postalCode: "",
            hearFrom: "",
        })
    }

    componentDidMount() {
        AOS.init();
        // document.getElementById("RQForm").style.display = "block";
        // document.getElementById("SuccessMSgDiv").style.display = "none";
    }

    submitHandaler = (event) => {
        event.preventDefault();
        //console.log(this.state)

        const isValid = this.Validate();
        if (isValid == true) {

            axios.post("https://api.vtacpayroll.ca/create", this.state).then(Response => {
                // axios.post("http://localhost:5000/create", this.state).then(Response => {
                this.clearForm();
                window.location.href = "https://www.vtacpayroll.ca/#/request-payroll-success";
                // document.getElementById("RQForm").style.display = "none";
                // document.getElementById("SuccessMSgDiv").style.display = "block";

            }).catch(error => {
                console.log(error);
            })
        }
    }

    Validate = () => {
        const errors = {};
        //let regexp = /(?!.*[DFIOQU-])[A-VXY][0-9][A-Z][' '] ?[0-9][A-Z][0-9]/;
        let isValid = true;
        const { fullName, company, phone, email, numberOfEmployees, payperperiod, province, postalCode, hearFrom } = this.state;
        if (fullName == "") {
            errors.fullNameEmpty = "Please provide your Name.";
            isValid = false;
        }
        if (company == "") {
            errors.companyEmpty = "Please provide your company Name.";
            isValid = false;
        }
        if (phone == "") {
            errors.phoneEmpty = "Please provide your Phone Number.";
            isValid = false;
        }
        if (email == "") {
            errors.emailEmpty = "Please provide your Email Address.";
            isValid = false;
        }
        if (numberOfEmployees == "") {
            errors.numberOfEmployeesEmpty = "Please provide your no.of.Employee.";
            isValid = false;
        }
        if (payperperiod == undefined || payperperiod == "") {
            errors.payperperiodEmpty = "Please provide your Pay Period Frequency.";
            isValid = false;
        }

        if (province == undefined || province == "") {
            errors.provinceEmpty = "Please provide your Province.";
            isValid = false;
        }
        if (postalCode == "") {
            errors.postalCodeEmpty = "Please provide your Postal Code.";
            isValid = false;
        }
        // if (regexp.test(postalCode.toUpperCase()) != true) {
        //     errors.postalCodevarify = "Please provide your valid Postal Code.";
        //     isValid = false;
        // }
        if (hearFrom == "" || hearFrom == undefined) {
            errors.hearFromEmpty = "Please provide How'd you hear about us ?.";
            isValid = false;
        }
        this.setState({ errors });
        return isValid;
    }


    render() {
        const { fullName, company, phone, email, numberOfEmployees, payperperiod, province, postalCode, hearFrom, errors } = this.state;
        return (
            <Fragment>
                <Container className='SectionBetween'>
                    <div style={{ width: "80%" }} className=" container text-center" data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1000"
                        data-aos-once="true">
                        <h1 className='mainTitle'>
                            PAYROLL SERVICES AS UNIQUE AS YOUR COMPANY NEEDS THEM TO BE
                        </h1>
                    </div>
                    <img className="logoImage" alt="Paris" src={Logo} />
                    <Row className='mt-3'>
                        <Col sm={12} md={12} lg={6}>
                            <div className='quoteCardDescription'>
                                <b>V‐TAC Payroll Management (VPM)</b> has developed a solid reputation in the community by providing valuable, accessible, and cost‐effective payroll services.
                            </div>
                            <hr className="hrquoteHome" />
                            <p className='description'>Our expertise, flexibility, and creativity are incomparable. It is these benefits that set us apart from our competitors, making us not only the better alternative, but also the best choice.</p>
                            <p className='description'>We provide a dedicated Payroll Coordinator to each client to oversee payroll processing and maintain a detailed record of all financial transactions.</p>
                            <p className='description'>We can offer standard payroll services with the flexibility to add or modify transactions and reports in ways that are suited to your specific requirements, enabling you to get the most out of our payroll services. Regardless of the industry, no two businesses are alike, and neither are their payroll service requirements.</p>
                            <p className='quoteCardDescription'>We have offices in Toronto &amp; Calgary, and have been serving Canadian businesses since <strong><span className='spancolor'>1997.</span></strong></p>
                        </Col>
                        <Col sm={12} md={12} lg={6}>
                            <div className="homeQuoteCard" id="RQForm" data-aos="fade-left"
                                data-aos-anchor="#example-anchor"
                                data-aos-offset="500"
                                data-aos-duration="2000">

                                <h3 className="quotationTitle mb-5">Request a Quote </h3>

                                <Form onSubmit={this.submitHandaler} >
                                    <span style={{ color: 'red' }}>{errors.fullNameEmpty}</span>

                                    <Form.Group >
                                        <Form.Control type="text" title='Full Name' placeholder="Full Name*" name='fullName' value={fullName} onChange={this.handleChange} />
                                    </Form.Group>

                                    <span style={{ color: 'red' }}>{errors.companyEmpty}</span>
                                    <Form.Group >
                                        <Form.Control type="text" title='Company' placeholder="Company*" name='company' value={company} onChange={this.handleChange} />
                                    </Form.Group>

                                    <span style={{ color: 'red' }}>{errors.phoneEmpty}</span>
                                    <Form.Group >
                                        <Form.Control className='formLebel' type="number" title='Phone' placeholder="Phone*" name='phone' value={phone} onChange={this.handleChange} />
                                    </Form.Group>

                                    <span style={{ color: 'red' }}>{errors.emailEmpty}</span>
                                    <Form.Group >
                                        <Form.Control type="email" title='Email Address' placeholder="Email*" name='email' value={email} onChange={this.handleChange} />
                                    </Form.Group>

                                    <span style={{ color: 'red' }}>{errors.numberOfEmployeesEmpty}</span>
                                    <Form.Group >
                                        <Form.Control type="number" min={0} title='Number of Employees' placeholder="Number of Employees*" name='numberOfEmployees' value={numberOfEmployees} onChange={this.handleChange} />
                                    </Form.Group>

                                    <span style={{ color: 'red' }}>{errors.payperperiodEmpty}</span>
                                    <Form.Group >
                                        <select className='quoteDropdown' name='payperperiod' value={payperperiod} onChange={this.handleChange} >
                                            <option value={""}>Pay Period Frequency*</option>
                                            <option value={'WEEKLY'}>WEEKLY</option>
                                            <option value={'SEMI-MONTHLY'}>SEMI-MONTHLY</option>
                                            <option value={'BI-WEEKLY'}>BI-WEEKLY</option>
                                            <option value={'MONTHLY'}>MONTHLY</option>
                                        </select>

                                    </Form.Group>
                                    <span style={{ color: 'red' }}>{errors.provinceEmpty}</span>
                                    <Form.Group >
                                        <select className='quoteDropdown' name='province' value={province} onChange={this.handleChange}>
                                            <option value={""}>Province*</option>
                                            <option value={'Alberta'}>Alberta</option>
                                            <option value={'British Columbia'}>British Columbia</option>
                                            <option value={'Manitoba'}>Manitoba</option>
                                            <option value={'New Brunswick'}>New Brunswick</option>
                                            <option value={'Newfoundland and Labrador'}>Newfoundland and Labrador</option>
                                            <option value={'Nova Scotia'}>Nova Scotia</option>
                                            <option value={'Northwest Territories'}>Northwest Territories</option>
                                            <option value={'Nunavut'}>Nunavut</option>
                                            <option value={'Ontario'}>Ontario</option>
                                            <option value={'Prince Edward Island'}>Prince Edward Island</option>
                                            <option value={'Quebec'}>Quebec</option>
                                            <option value={'Saskatchewan'}>Saskatchewan</option>
                                            <option value={'Yukon'}>Yukon</option>
                                        </select>
                                    </Form.Group>

                                    <span style={{ color: 'red' }}>{errors.postalCodeEmpty}</span>

                                    <Form.Group >
                                        <Form.Control type="text" title='Postal Code' placeholder="Postal Code*" name='postalCode' value={postalCode} onChange={this.handleChange} isInvalid={!!errors.state} />
                                    </Form.Group>

                                    <span style={{ color: 'red' }}>{errors.hearFromEmpty}</span>
                                    <Form.Group >
                                        <select className='quoteDropdown' name='hearFrom' value={hearFrom} onChange={this.handleChange}>
                                            <option value={""}>How'd you hear about us ?*</option>
                                            <option value={"Google Search"}>Google Search</option>
                                            <option value={"Social Media"}>Social Media</option>
                                            <option value={"Magazine Ad"}>Magazine Ad</option>
                                            <option value={"Word Of Mouth"}>Word Of Mouth</option>
                                            <option value={"Promotional Calender"}>Promotional Calender</option>
                                            <option value={"Others"}>Others</option>
                                        </select>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                        <Form.Check type="checkbox" defaultChecked={true} className='checkboxForm' label="Yes, I want to receive VPM info, industry news and promotions from V-TAC Payroll Management by email. You can change your preferences or unsubscribe from our contact list at any time." />
                                    </Form.Group>


                                    <div className='m-2'>
                                        <ReCAPTCHA
                                            // vtacpayroll.ca
                                            sitekey="6LdW8FcjAAAAABvBHj1pzxWTOu3vqp4Hd5UIr9jn"
                                            // vtacpayrol.com
                                            // sitekey="6LewqFUjAAAAADXJfTyYT40ysEYNHl6eX3fZbf9L"
                                            onChange={this.recapchaValue}
                                        />
                                    </div>
                                    <Button name='submitBtn' className="RequestQuoteBtn" variant="primary" type="submit" disabled={!this.state.isVarified}>
                                        <span>GET QUOTE</span>
                                    </Button>
                                </Form>
                            </div>
                            {/* <div id='SuccessMSgDiv' className='successCard'>
                                <h1 className='SuccessMsg'>Thank you for submitting your request for a quote. We will get back to you shortly.</h1>
                                <p className='SuccessMsgDesc'>if you would like to speak with someone right away, feel free to call us  on our toll number at:</p>
                                <p className='SuccessMsgDesc mb-5' style={{ color: "#E6001F", cursor: "pointer" }}><strong>1-866-315-8822</strong></p>
                            </div> */}
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default HomeQuoteCard;