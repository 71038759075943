import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import drschindler6 from '../../asset/images/dr-schindler-6.jpg';
import drschindler5 from '../../asset/images/dr-schindler-5.jpg';
import motheranddaughter from '../../asset/images/mother-and-daughter.jpg';


class SchindlerSec2 extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className='SectionBetween nexasStaffingContainer ' >
                    <div className='SectionBetween p-5'>
                        <Container>
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="nexus3Img" alt='Dr. Schindler & Associates' title='Dr. Schindler & Associates' src={drschindler5} />
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <h3 className='commonTitle'>A VPM customer <span className='spancolor'>since 2017</span></h3>
                                    <p className='desc'>With certain payroll and service provider needs unmet, Dr. Schindler was referred to VPM for payroll services through a colleague and client of VPM. Dr. Schindler & Associates switched and joined VPM in June of 2017.</p>
                                    <p className='desc'>​VPM offered free onboarding, including a completely free reconciliation of YTD reporting on its system via a few existing standard reports, making year-end reporting straightforward and painless for staff and for CRA.</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12}>

                                    <h3 className='commonTitle mt-5'>Eliminating past issues with  <span className='spancolor'>VPM</span></h3>
                                    <p className='desc'>VPM immediately appointed a single point of contact (and back-up) to streamline communications and the relationship through a payroll consultant who would work directly with Dr. Schindler or his delegate, helping avoid payroll and technical support phone queues.</p>
                                    <p className='desc'>​Software issues disappeared by putting the team on email reporting, freeing Dr. Schindler and his staff from spending a lot of time previously spent searching for the information they needed on the large company website that they sometimes found difficult.</p>
                                    <p className='desc'>And VPM’s suite of payroll reports was simple enough for any of the team to read, print or store.</p>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="nexus2Img mt-5" alt='Dr. Schindler & Associates' title='Dr. Schindler & Associates' src={drschindler6} />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="nexus1Img mt-5" alt='Woman and child having fun outdoors' title='Woman and child having fun outdoors' src={motheranddaughter} />
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <h3 className='commonTitle mt-5'><span className='spancolor'>Freeing up time</span> for friends & family</h3>
                                    <p className='desc'>“What I say about VPM is that it works for me and it’s a big improvement. Simple, numbers are correct, and I don’t have to waste any time.</p>
                                    <p className='desc'>I’m no longer spending a lot of time to get the information I need, and they’re easy to follow-up on.</p>
                                    <p className='desc'>​This is not rehearsed. I would not have agreed to do this if I didn’t feel VPM was worth recommending to others.”</p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </Fragment>
        );
    }
}

export default SchindlerSec2;