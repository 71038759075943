import React, {Fragment } from 'react';

// /import img from '../../asset/images/cal-back.jpg'

const ContactBanner = (props) => {
    const { image, bannerTitle } = props;
    return (
        <Fragment>
            <div className="banner-container">
                <img
                    className="banner-image"
                    src={image}
                    alt="Banner Image"
                    loading="lazy"
                />
                <div className="banner-overlay">
                    <h1 className='contacttopTitle'>
                        {bannerTitle.title}
                    </h1>
                </div>
            </div>
        </Fragment>
    );
}

export default ContactBanner;