import React, { Component, Fragment } from 'react';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import QuotationSend from '../components/QuotationSend/QuotationSend';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import TopHead from '../components/TopHead/TopHead';


class QuotationSendPage extends Component {
    render() {
        return (
            <Fragment>
            <TopHead />
            <TopNavigation title="Request Success | V-TAC Payroll Management"/>
            <QuotationSend />
            <TradePart />
            <Footer />
        </Fragment>
        );
    }
}

export default QuotationSendPage;