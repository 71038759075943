import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import professionals from '../../asset/images/professionals.jpg';
import { Link } from 'react-router-dom';

class OurSotution extends Component {
    render() {
        return (
            <Fragment>
                <Container className='SectionBetween'>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <img className="ourSolutionImg " alt="professionals from different industries" title='professionals from different industries' src={professionals} loading="lazy" data-aos="zoom-in" data-aos-duration="1500"
                                data-aos-once="true" />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='text-center mt-5' data-aos="fade-up"
                                data-aos-duration="1500"
                                data-aos-easing="linear"
                                data-aos-once="true">
                                <Link to="/business-payroll-services"><Button className='solution'><span>OUR SOLUTIONS</span></Button></Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default OurSotution;