import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import handshake1 from '../../asset/images/handshake-1.jpg';
import telesupport from '../../asset/images/tele-support.jpg';
import groupofemployees from '../../asset/images/group-of-employees.jpg';
import { Link } from 'react-router-dom';

class WhoWeAreProfessionals extends Component {
    render() {
        return (
            <Fragment>
                <Container className='SectionBetween'>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <h2 className='mainTitle'><strong className='spancolor'> Reputable Professionals</strong> Dedicated to Helping You Achieve Your Financial <strong className='spancolor'>Goals</strong></h2>
                            <p className='description text-center'>V-TAC Payroll Management VPM has over 20-years of experience developing and implementing quality solutions for specific payroll needs. We have developed a solid reputation in the community by providing valuable, efficient, and cost‐effective payroll services. Our expertise, flexibility, and creativity set us apart from our competitors and make us the top contender.</p>
                        </Col>
                    </Row>
                    <Container className='professionalCard'>
                        <Row className='mt-5'>
                            <Col lg={4} md={6} sm={12}>
                                <img className="profesonalimg " src={handshake1} alt='Handshake' title='Handshake' />
                            </Col>
                            <Col lg={8} md={6} sm={12}>
                                <div>
                                    <h3 class="commonTitle">One-stop payroll service contacts</h3>
                                    <p className='commonDesc '>Clients are matched with professional, experienced, and friendly payroll consultants. Our Payroll Consultants are accessible by phone or e-mail and strive to build excellent client rapport. We strive to deliver quality services on which you can continue to depend on. Our consultants continually update their knowledge of new government regulations and legislation in order to maintain your confidence in our payroll services.</p>
                                    <p className='commonDesc'>We seek to foster longstanding partnerships and work together with our clients to ensure their needs are met. Our success is reflected in the success of our clients.</p></div>
                            </Col>
                        </Row>
                    </Container>
                    <Container className='professionalCard'>
                        <Row className='mt-5'>
                            <Col lg={4} md={6} sm={12}>
                                <img className="profesonalimg " src={telesupport} alt='Technical Support' title='Technical Support' />
                            </Col>
                            <Col lg={8} md={6} sm={12}>
                                <div>
                                    <h3 class="commonTitle">Technical & operations wing</h3>
                                    <p className='commonDesc'>VPM’s technology is simple and efficient. New clients are provided with a specialist so that there is a smooth transition. We ensure our processing system secures your valuable data and provides a smooth transfer of all payroll funds -all while providing a user-friendly experience.</p>
                                    <p className='commonDesc'>Our technical team is available to ensure any inquiry can be answered, and issues can be solved within the shortest possible time.</p></div>
                            </Col>
                        </Row>
                    </Container>
                    <Container className='professionalCard'>
                        <Row className='mt-5'>
                            <Col lg={4} md={6} sm={12}>
                                <img className="profesonalimg " src={groupofemployees} alt='A diverse group of professionals' title='A diverse group of professionals' />
                            </Col>
                            <Col lg={8} md={6} sm={12}>

                                <div ><h3 class="commonTitle">Join Our Vibrant Professional Team</h3>
                                    <p className='commonDesc'>We offer likeminded professionals the opportunity to develop & enhance their careers and often move ahead more rapidly than in other companies. Ongoing training & mentoring provides exciting & personally satisfying careers.</p>
                                    <Link to="/careers" onClick={() => {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                                    }}><Button variant="primary" className='quotebutton mt-2' ><span>CAREERS</span></Button>{' '}</Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <div className='SectionBetween'></div>
                </Container>
            </Fragment>
        );
    }
}

export default WhoWeAreProfessionals;