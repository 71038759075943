import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin,faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class ContactSection extends Component {
    constructor() {
        super();
        this.state = {
            fstName: "",
            lstName: "",
            company: "",
            phone: "",
            email: "",
            message: "",
            errors: {},
        }
    }
    // top page load
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    clearForm = () => {
        this.setState({
            fstName: "",
            lstName: "",
            company: "",
            phone: "",
            email: "",
            message: "",
        })
    }
    //success sms shows
    Successnotify = () => {
        toast.success('Message has been sent Successfully!', {
            position: "bottom-right",
            autoClose: 5000,
            theme: "colored",
        })
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }
    submitHandaler = (event) => {
        event.preventDefault();
        axios.post("https://api.vtacpayroll.ca/contact", this.state).then(Response => {

        }).catch(error => {
            console.log(error);
        })
        this.clearForm();
        this.Successnotify();
    }
    render() {
        const { fstName, lstName, company, email, phone, message } = this.state;
        return (
            <Fragment>
                <Container className='SectionBetween '>
                    <div >
                        <Container className='text-center' style={{ width: "70%" }}>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <h2 className='mainTitle'>V-TAC Payroll Management</h2>
                                    <p className='commonDesc text-center'>Do you have any questions or comments? Contact us by giving us a call, sending us an email, or using the contact form below and we’ll get back to you within 24 hours.</p>
                                    <Link to="/promotional-conditions"> <p className='quoteCondition'><strong>*Some conditions apply</strong></p></Link>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Row>
                        <Col lg={6} md={16} sm={12}>
                            <div className=" contactCard mt-5">
                                <h3 className="quotationTitle float-left">Email Form</h3><br></br>
                                <p className='commonDesc float-left'>For regular support please contact us through your dedicated payroll point-of-contact consultant by phone or email so we can get back to you with an answer as quickly as possible.</p>

                                <Form onSubmit={this.submitHandaler}>
                                    <Form.Group >
                                        <Form.Label >First Name<span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control type="text" title='First Name' name='fstName' value={fstName} onChange={this.handleChange} required />
                                    </Form.Group>
                                    <Form.Group >
                                        <Form.Label >Last Name<span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control type="text" title='Last Name' name='lstName' value={lstName} onChange={this.handleChange} required />
                                    </Form.Group>

                                    <Form.Group >
                                        <Form.Label >Company<span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control type="text" title='Company' name='company' value={company} onChange={this.handleChange} required />
                                    </Form.Group>

                                    <Form.Group >
                                        <Form.Label >Email Address<span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control type="email" title='Email Address' name='email' value={email} onChange={this.handleChange} required />
                                    </Form.Group>
                                    <Form.Group >
                                        <Form.Label >Phone<span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control type="number" title='Phone' name='phone' value={phone} onChange={this.handleChange} required />
                                    </Form.Group>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Message<span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control className='form-textarea' as="textarea" rows={3} name='message' value={message} onChange={this.handleChange} required />
                                    </Form.Group>
                                    <Button name='submitBtn' className="RequestQuoteBtn" variant="primary" type="submit">
                                        <span>SEND</span>
                                    </Button>
                                </Form>
                                <div>
                                    <ToastContainer
                                        toastStyle={{ backgroundColor: "green", color: "white" }}
                                    />
                                </div>

                            </div>

                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <div className="mt-5">
                                <h2 className="quotationTitle text-center">Our Locations</h2>
                                <ul className='contactUI float-left'>
                                    <li className=' commonDesc mt-3'><strong >Toronto – Head Office</strong><br></br>
                                        401 Bay Street, Suite 1600<br></br>
                                        Toronto, ON M5H 2Y4</li>
                                    <li className=' commonDesc mt-3'>
                                        <strong>Toronto</strong><br></br> 100 King Street West, Suite 5700
                                        Toronto, ON M5X 1C7
                                    </li>
                                    <li className='commonDesc mt-3'><strong>Toronto</strong><br></br>
                                        881A Jane Street, Suite 207<br></br>
                                        Toronto, ON M6M 4C4</li>
                                    <li className='commonDesc mt-3'><strong>Calgary</strong><br></br>
                                        300, 840-6 Avenue SW<br></br>
                                        Calgary, AB T2P 3E5</li>
                                </ul>
                            </div>
                            <div>
                                <h2 className="quotationTitle text-center">Get in Touch</h2>
                                <ul className='contactUI float-left'>
                                    <li>Free Call:<a href="tel:+1-866-315-8822"><strong style={{ color: "#E6001F" }}> 1-866-315-8822</strong></a></li>
                                    <li>Email:<a><strong style={{ color: "#E6001F" }}> info@vtacpayroll.ca</strong></a></li>
                                </ul>
                            </div>
                            <div className='text-center'>
                                <span> <a href="https://www.linkedin.com/company/v-tac-payroll-management/" target='_blank'><FontAwesomeIcon className='contactIcon' icon={faLinkedin} /> </a></span>
                                <span> <a href="https://www.facebook.com/vtacpayroll/" target='_blank'><FontAwesomeIcon className='contactIcon' icon={faFacebook} /></a></span>
                                <span><a href="https://www.youtube.com/watch?v=oiUQf14cbHs" target='_blank'><FontAwesomeIcon className='contactIcon' icon={faYoutube} /></a></span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default ContactSection;