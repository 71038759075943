import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import different1 from '../../asset/images/different-1.jpg';
import different2 from '../../asset/images/different-2.jpg';
import different3 from '../../asset/images/different-3.jpg';
import different4 from '../../asset/images/different-4.jpg';
import different5 from '../../asset/images/different-5.jpg';
import different6 from '../../asset/images/different-6.jpg';
import different7 from '../../asset/images/different-7.jpg';
import different8 from '../../asset/images/different-8.jpg';
import different9 from '../../asset/images/different-9.jpg';
import different10 from '../../asset/images/different-10.jpg';




class HowWeAreDifferentCauses extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className='SectionBetween  ' >
                    <div className='SectionBetween p-5'>
                        <Container>
                            <Row id='s-1'>
                                <Col lg={6} md={6} sm={12}>
                                    <h3  className='commonTitle'><span className='spancolor'>Affordable</span> & no upfront or hidden costs</h3>
                                    <p className='commonDesc'>Outsource your payroll for less, why pay higher fees for a standardized service? VPM offers more support and ancillary services for a competitive price that can’t be matched.</p>
                                    <p className='commonDesc'>Many service providers advertise low prices but make up the difference by charging hidden fees, this leads to paying more than you should. VPM does not have hidden fees so you can rest assured that no unexpected costs will appear.</p>
                                    <p className='commonDesc'>Reinvest the savings back into your business for further growth!</p>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="HowWeAreDifferentmg1" alt="Business owner" title='Business owner' src={different1} />
                                </Col>
                            </Row>
                            <Row className='mt-5' id='s-2'>

                                <Col lg={6} md={6} sm={12}>
                                    <img className="HowWeAreDifferentmg3  " alt='Businesspeople with tablets' title='Businesspeople with tablets' src={different2} />
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <h3 className='commonTitle'>Customizable to your <span className='spancolor'>needs</span></h3>
                                    <p className='commonDesc'>Every business is different. That is a fact VPM understands, which is why we do not have a “one size fits all” approach.</p>
                                    <p className='commonDesc'>Our consultants will discuss your payroll and business needs before setting up an optimized approach for your solution. We understand you may need additional codes or certain reports on specific dates, and we are able to oblige.</p>
                                </Col>
                            </Row>
                            <Row className='mt-5' id='s-3'>
                                <Col lg={6} md={6} sm={12}>
                                    <h3 className='commonTitle'><span className='spancolor'>Quick</span> setup</h3>
                                    <p className='commonDesc'>Account setup will be completed within as little time as 48 hours. Once we have all the required information submitted to us, we can begin the setup process.</p>
                                    <p className='commonDesc'>This includes the business information, employee information, account information, year-to-date (YTD) data entry and so on.</p>
                                    <p className='commonDesc'>We’re not picky on format either, you can simply send us the information in whichever format is easiest to provide us. Once the setup is complete, we can begin with the first of many payroll runs!</p>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="HowWeAreDifferentmg4" alt="Business meeting" title='Business meeting' src={different3} />
                                </Col>
                            </Row>
                            <Row className='mt-5' id='s-4'>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="HowWeAreDifferentmg4" alt="VPM User Interface" title='VPM User Interface' src={different4} />
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <h3 className='commonTitle'><span className='spancolor'>Easy</span> to use</h3>
                                    <p className='commonDesc'>Our online platform is easy to use and provides you with what you need to complete your payroll.</p>
                                    <p className='commonDesc'>This system has been designed with the end-user in mind and has a simple and intuitive interface. Employees (ESS) can log in to their accounts and view their documents as well as make changes to their personal information.</p>
                                    <p className='commonDesc'>To take it a step further, you may simply provide us with any payroll updates, and we can process the updates accordingly.</p>
                                </Col>

                            </Row>
                            <Row className='mt-5'id='s-5'>
                                <Col lg={6} md={6} sm={12} >
                                    <h3 id='s-7' className='commonTitle'>Access from<span className='spancolor'> anywhere</span></h3>
                                    <p className='commonDesc'>Login to our portal from anywhere in the world and be rest assured that you have secured access to your sensitive information.</p>
                                    <p className='commonDesc'>Make changes, view reports and submit payroll updates all through the online portal from the convenience and comfort of wherever you may be.  </p>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="HowWeAreDifferentmg3  " alt="Accessing information on mobile" title='Accessing information on mobile' src={different5} />
                                </Col>
                            </Row>
                            <Row className='mt-5' id='s-6'>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="HowWeAreDifferentmg3  " alt="Accessing information on a laptop" title='Accessing information on a laptop' src={different6} />
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <h3 className='commonTitle'>Gain insights with <span className='spancolor'>GL</span></h3>
                                    <p className='commonDesc'>General ledger reports don’t have to be difficult to review. We can generate GL reports for your needs based on the criteria provided to us.</p>
                                    <p className='commonDesc'>Use these valuable metrics to see where your resources are going and what you can do to improve or sustain your pace. </p>
                                </Col>

                            </Row>
                            <Row className='mt-5' id='s-7'>
                                <Col lg={6} md={6} sm={12}>
                                    <h3 className='commonTitle'>Get help within <span className='spancolor'>30</span> minutes</h3>
                                    <p className='commonDesc'>Client support is a fundamental part of any service offering. VPM will assist you throughout the entire process.</p>
                                    <p className='commonDesc'>There will be a dedicated payroll consultant assigned to your account, who will know your account inside and out. They will be the main point of contact and can be reached by phone or e-mail for support.</p>
                                    <p className='commonDesc'>There won’t be any frustrations trying to get a hold of someone. </p>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="HowWeAreDifferentmg2" alt="Person pointing at a computer monitor while discussing it over the phone" title='Person pointing at a computer monitor while discussing it over the phone' src={different7} />
                                </Col>
                            </Row>
                            <Row className='mt-5' id='s-8'>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="HowWeAreDifferentmg3" alt="Person with laptop computer thinking" title='Person with laptop computer thinking' src={different8} />
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <h3 className='commonTitle'>We <span className='spancolor'>save</span> you time, less hands-on</h3>
                                    <p className='commonDesc'>The point of outsourcing should be to end up with less work, which leads to more time for you to spend growing your business.</p>
                                    <p className='commonDesc'>If we need to go our separate ways, regardless of the length of time, there will be no additional costs incurred in relation to termination of services.</p>
                                </Col>

                            </Row>
                            <Row className='mt-5' id='s-9'>
                                <Col lg={6} md={6} sm={12}>
                                    <h3 className='commonTitle'>No <span className='spancolor'>contracts</span> or bidding clauses</h3>
                                    <p className='commonDesc'>There are no contracts in place. This goes hand in hand with our no hidden fees policy. VPM has a nearly perfect client retention rate but we understand there are many situations that may occur.</p>
                                    <p className='commonDesc'>Meet with one of our skilled representatives to discuss exactly how VPM can meet your needs.</p>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="HowWeAreDifferentmg3  " alt="Person giving a presentation in a boardroom" title='Person giving a presentation in a boardroom' src={different9} />
                                </Col>
                            </Row>
                            <Row className='mt-5' id='s-10'>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="HowWeAreDifferentmg3  " alt="Downtown Toronto skyline" title='Downtown Toronto skyline' src={different10} />
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <h3 className='commonTitle'><span className='spancolor'>25+</span> years of experience</h3>
                                    <p className='commonDesc'>VPM has been providing payroll services across Canada for over 20 years. We have seen it all. Experience in the Canadian market separates VPM from many outside service providers who simply offer much less than we do.</p>
                                    <p className='commonDesc'>All provinces, territories and businesses are different and must be accounted for accordingly.</p>
                                </Col>

                            </Row>
                        </Container>
                    </div>
                </Container>
            </Fragment>
        );
    }
}

export default HowWeAreDifferentCauses;