import React, { Component, Fragment } from 'react';
import { Container, Row,Col } from 'react-bootstrap';

class CareerGrowingTeam extends Component {
    render() {
        return (
            <Fragment>
                <Container className='SectionBetween'>
                    <div >
                        <h2 className='mainTitle'><strong className='spancolor'>JOIN</strong> OUR GROWING <strong className='spancolor'>TEAM!</strong></h2>
                        <Container style={{width:"70%"}} className = "text-center">
                            <Row>
                                <Col>
                                    <h5 className='quoteCardDescription text-center'> At VPM we foster a culture of integrity, accountability, passion, simplicity, results, diversity, and humility in order to better serve our clients.</h5>
                                    <p className='commonDesc text-center'>We hire talented and ambitious individuals who share our values and who thrive in growing entrepreneurial environments with lots of opportunities to learn.</p>
                                    <p className='commonDesc text-center'>Please see employment opportunities and send us your resume to careers@vtacpayroll.ca indicating your area of interest or ideal role.</p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </Fragment>
        );
    }
}

export default CareerGrowingTeam;