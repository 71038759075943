import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import filechartline from '../../asset/images/file-chart-line.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMousePointer, faBan, faCloudUploadAlt, faSearch, faDollarSign, faCheck,faComments,faHourglassHalf} from '@fortawesome/free-solid-svg-icons';
import document from '../../asset/images/document.svg';

class HospitalityWhySwitchVpm extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className='serviceContainer2 SectionBetween'>

                    <div className='serviceSection'>
                        <h2 className='cardMainTitle '>Why VPM is a <strong className='spancolor'>good fit</strong> for your hospitality business</h2>
                        <Container>
                            <Row>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                            <FontAwesomeIcon className="cardlogoImg" icon={faDollarSign} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>Setup Different Rate Structures</span></h4></Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>Customize your payment structure with rates based on the position, e.g. hygienist vs. admin.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                            <Card.Img className="cardlogoImg" src={document} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>Workforce Management</span></h4></Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>Consolidate “punch in clock” Excel spreadsheet with VPM.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                            <FontAwesomeIcon className="cardlogoImg" icon={faDollarSign} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>Get Detailed Employee Reports</span></h4></Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>Extract a report breakdown per employee &amp; Doctor to gain insights into scheduling and payments.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                            <FontAwesomeIcon className="cardlogoImg" icon={faCheck} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>Pay Contractors</span></h4></Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>Setup contractor payments for work completed.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                            </Row>
                            <Row className=''>

                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                            <FontAwesomeIcon className="cardlogoImg" icon={faMousePointer} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>Easy to Use</span></h4></Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>No more headaches when it’s time for payroll. Intuitive simple design for maximum productivity.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                            <FontAwesomeIcon className="cardlogoImg" icon={faCloudUploadAlt} />
                                                <Card.Title ><h4 class="serviceCardTitle"><span>Access from Anywhere</span></h4></Card.Title>
                                                <Card.Text>
                                                    <p className='serviceCardDes'>Connect to VPM from anywhere in Canada on your mobile or computer.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                            <FontAwesomeIcon className="cardlogoImg" icon={faSearch} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>Track All Employee- Related Data</span></h4></Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>Access insights on age, seniority, work history, skills, salary/wage increases.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                            <FontAwesomeIcon className="cardlogoImg" icon={faComments} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>Fast Response Time</span></h4></Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>Get help in 30 minutes or less, guaranteed!</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                            </Row>

                            <Row>

                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                            <Card.Img className="cardlogoImg" src={filechartline} />
                                            
                                                <Card.Title><h4 class="serviceCardTitle"><span>Gain Insights with GL</span></h4></Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>Know where your money is going and keep track of all financial data.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                            <FontAwesomeIcon className="cardlogoImg" icon={faBan} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>No Contracts</span></h4></Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>Unlike with other payroll providers, we don't lock you into contracts. Use VPM for as long as you need.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                            <FontAwesomeIcon className="cardlogoImg" icon={faEdit} />
                                                <Card.Title class="serviceCardTitle">Fast & Accurate ROE</Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>Get your ROE completed and submitted on your behalf.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={6}>

                                    <Link to="/business-payroll-services">
                                        <Card className="serviceCard">
                                            <Card.Body>
                                            <FontAwesomeIcon className="cardlogoImg" icon={faHourglassHalf} />
                                                <Card.Title class="serviceCardTitle">Save Time</Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>Spend less time trying to prepare your payroll documents, let us do all the work for you.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>

                            </Row>
                            <div className='text-center mt-3'><Link to="/business-payroll-services"><Button variant="primary" className='button2 text-center'><span>EXPLORE ALL FEATURES</span></Button></Link></div>
                        </Container>

                    </div>



                </Container>
            </Fragment>
        );
    }
}

export default HospitalityWhySwitchVpm;