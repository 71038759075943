import React, { Component, Fragment } from 'react';
import EmploymentAgenBanner from '../components/EmploymentAgenBanner/EmploymentAgenBanner';
import EmploymentAgenReduceTIme from '../components/EmploymentAgenReduceTIme/EmploymentAgenReduceTIme';
import EmploymentAgencySlide from '../components/EmploymentAgencySlide/EmploymentAgencySlide';
import AskQuesEmploymentAgency from '../components/AskQuesEmploymentAgency/AskQuesEmploymentAgency';
import TopHead from '../components/TopHead/TopHead';
import TopNavigation from '../components/TopNavigation/TopNavigation';

import EmploymentWhySwitchVpm from '../components/EmploymentWhySwitchVpm/EmploymentWhySwitchVpm';
import QuoteComponentGray2 from '../components/QuoteComponentGray2/QuoteComponentGray2';
import QuoteComponentGray from '../components/QuoteComponentGray/QuoteComponentGray';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';

class EmploymentAgenciesPage extends Component {
    render() {
        return (
            <Fragment>
                <TopHead />
                <TopNavigation title="Employment Agencies | V-TAC Payroll Management" />
                <EmploymentAgenBanner />
                <EmploymentAgenReduceTIme />
                <EmploymentWhySwitchVpm />
                <EmploymentAgencySlide />
                <QuoteComponentGray2 />
                <AskQuesEmploymentAgency />
                <QuoteComponentGray />
                <TradePart />
                <Footer />
            </Fragment>
        );
    }
}

export default EmploymentAgenciesPage;