import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import schindler from '../../asset/images/dr-schindler.jpg';
import global from '../../asset/images/global.jpg';

class TrustedClient extends Component {
    render() {
        return (
            <Fragment>

                <Container fluid={true} className="trustedFixedBanner  p-0">
                    <div className="trustedBannerOverlay" >
                        <p className='serviceMainTitleforTrust'>TRUSTED BY CANADIAN BUSINESSES LIKE YOURS</p>

                        <Container>
                            <Row>
                                <Col lg={6} md={12} sm={12}>
                                    <Link to="/global-imaging">
                                        <div className="trustcaptionCard">
                                            <Row>
                                                <Col lg={6} md={12} sm={12}>
                                                    <div className='trustimgdiv' data-aos="fade-up" data-aos-duration="1000"
                                                        data-aos-once="true">
                                                        <img className="trustcaptionImg" src={global} alt="Gerald Maister" title='Global Imaging' loading="lazy" />
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={12} sm={12}>
                                                    <div data-aos="fade-up" data-aos-duration="1000"
                                                        data-aos-once="true">
                                                        <p className='trustedDescriotion' >“VPM is very reliable and does an outstanding job. Payroll is always done on time. VPM also gets back right away with answers to any questions we have, such as any changes to statutory requirements.</p>
                                                        <p className='trustedDescriotion'>​Our point of contact payroll consultant does a good job for us, she’s extremely good. Overall we’ve been very happy.”</p>
                                                        <span class="clientName">Gerald Maister</span>
                                                        <p className='trustedDescriotion'><span>Global Imaging</span></p>
                                                    </div>
                                                </Col>

                                            </Row>
                                        </div>
                                    </Link>
                                </Col>
                                <Col lg={6} md={12} sm={12}>
                                    <Link to="/dr-schindler-associates">
                                        <div className="trustcaptionCard">
                                            <Row>
                                                <Col lg={6} md={12} sm={12}>
                                                    <div className='trustimgdiv' data-aos="fade-up" data-aos-duration="1000"
                                                        data-aos-once="true">
                                                        <img className="trustcaptionImg" src={schindler} alt="Dr. Schindler & Associates" title='Dr. Schindler & Associates' loading="lazy" />
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={12} sm={12}>
                                                    <div data-aos="fade-up" data-aos-duration="1000"
                                                        data-aos-once="true">
                                                        <p className='trustedDescriotion'>“I need simplicity and reliability. VPM frees me from a stressful but necessary part of my work.</p>
                                                        <p className='trustedDescriotion'>Payroll used to mushroom out of control time-wise, and more time spent on that means less time to focus on my own work. VPM has made my life and my work easier.”</p>
                                                        <span class="clientName">Dr. Schindler</span>
                                                        <p class="trustedDescriotion"><span>Dr. Schindler &amp; Associates</span></p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Link>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </Fragment >
        );
    }
}

export default TrustedClient;