import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";

class AskQuesUnifiedHR extends Component {

    handleClick(id) {
        var coll = document.getElementById(id);
        coll.classList.toggle("factive");
        var content = coll.nextElementSibling;
        if (content.style.maxHeight) {
            content.style.maxHeight = null;
        } else {
            content.style.maxHeight = content.scrollHeight + "px";
        }
    }
    render() {
        return (
            <Fragment>
                <Container className='SectionBetween text-center container'>
                    <Row>
                        <Col>
                            <div className='card'>
                                <div style={{ width: "70%" }} className=" container text-center mt-3"><h2 className='mainTitle text-center ' >FREQUENTLY ASKED QUESTIONS ABOUT OUR <span className='spancolor'>PAYROLL SERVICES</span>
                                </h2></div>


                                <button class="FAQcollapsible" id='b1' onClick={() => this.handleClick('b1')}>What is human capital management software?</button>
                                <div class="FAQcontent">
                                    <p className='FaqDescription'>Human capital management software is a way to apply technology to human resources processes in order to help employers manage are look after their most valuable asset, their employees. HCM software is sometimes referred to as a human resources information system (HRIS) or a human resources management system (HRMS). Regardless of what they are called, they all serve the same purpose.</p>
                                </div>
                                <button class="FAQcollapsible" id='b2' onClick={() => this.handleClick('b2')}>What is the purpose of human capital management software?</button>
                                <div class="FAQcontent">
                                    <p className='FaqDescription'>The purpose of HCM software is to assist human resource departments in optimizing the performance and efficiency of the staff of an organization. HCM software makes managing talent attraction & recruitment, onboarding, employee training and development, performance appraisals and feedback easier for HR specialists in businesses of any size.</p>
                                </div>
                                <button class="FAQcollapsible" id='b3' onClick={() => this.handleClick('b3')}>What is the benefit of an integrated HR, benefits & payroll system?</button>
                                <div class="FAQcontent">
                                    <p className='FaqDescription'>There are a number of benefits to take advantage of by having HR, benefits & payroll management integrated into a single system. A few key benefits include:</p>
                                    <ul className='FaqDescription'>
                                        <li>Having all employee HR, benefits and payroll information accessible through a single platform, meaning there is no need to switch between systems to find information.</li>
                                        <li>Easily automated payroll deductions that are fully compliant with Canadian payroll regulations.</li>
                                        <li>Efficient management of human capital from recruitment & onboarding, to performance evaluation and feedback, to exit interviews & offboarding.</li>
                                        <li>Richer data source for HR analytics and reporting.</li>
                                    </ul>
                                </div>
                                <button class="FAQcollapsible" id='b4' onClick={() => this.handleClick('b4')}>How do I choose the best HR software?</button>
                                <div class="FAQcontent">
                                    <p className='FaqDescription'>Before choosing an HR system, it is incredibly important to understand what the specific HR needs of your business are. Don’t limit this only to your technological needs. Consider the specific HR challenges your business faces as well. When you have a firm understanding of what you need from a system, get in touch with us and we can discuss exactly how VPM can meet your needs.</p>
                                </div>
                                <button class="FAQcollapsible" id='b5' onClick={() => this.handleClick('b5')}>Can VPM track and report payroll by department?</button>
                                <div class="FAQcontent">
                                    <p className='FaqDescription'>Yes. As part of the on-boarding process, we will ensure our systems are set up to reflect how your organization is structured in order to meet your departmental reporting needs.
                                        How does VPM pay a client’s taxes?</p>

                                </div>
                                <button class="FAQcollapsible" id='b6' onClick={() => this.handleClick('b6')}>How does VPM pay a client’s taxes?</button>
                                <div class="FAQcontent">
                                    <p className='FaqDescription'>Taxes are submitted to the government electronically, with source deductions (CPP, EI, and Tax – Federal & Provincial including EHT in Ontario) submitted on your behalf. VPM ensures compliance to CRA provincial legislation by utilizing built-in options.</p>
                                    <p className='FaqDescription'><Link to="/contact" className='faqLink'>Contact VPM </Link> today for more information about our payroll services.</p>
                                </div>
                                <button class="FAQcollapsible" id='b7' onClick={() => this.handleClick('b7')}>Does VPM stay up-to-date with new payroll rules and legislations?</button>
                                <div class="FAQcontent">
                                    <p className='FaqDescription'>Yes. As part of the on-boarding process, we will ensure our systems are set up to reflect how your organization is structured in order to meet your departmental reporting needs.</p>

                                </div>
                                <button class="FAQcollapsible" id='b8' onClick={() => this.handleClick('b8')}>Can you direct deposit payroll into our employees accounts?</button>
                                <div class="FAQcontent">
                                    <p className='FaqDescription'>Yes. As part of the on-boarding process, we will ensure our systems are set up to reflect how your organization is structured in order to meet your departmental reporting needs.</p>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default AskQuesUnifiedHR;