import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import vpminterfacelaptop1 from '../../asset/images/vpm-interface-laptop1.jpg';
import vpminterfacetablet1 from '../../asset/images/vpm-interface-tablet1.jpg';

class PayrollServicefifthPart extends Component {
    render() {
        return (
            <Fragment>
                <Container className='SectionBetween '>
                    <div className='container' style={{ width: "70%" }}>
                        <h2 className='mainTitle'>
                            FEATURES TO HELP YOU GET THE JOB DONE
                            <span className='spancolor'> FASTER & ACCURATELY</span>
                        </h2>
                    </div>

                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            
                           <div> <img className="payrolServicesUlImage mt-5" alt="vpm-interface-laptop" title='vpm-interface-laptop' src={vpminterfacelaptop1} /></div>
                               
                           
                            <div>
                                <ul className='payrolServicesUl mt-5'>
                                    <li>Accommodates small to large organizations (1 -500+ employees)</li>
                                    <li>48-Hour set up</li>
                                    <li>Work with a dedicated Account Manager</li>
                                    <li>Low processing costs</li>
                                    <li>No hidden costs</li>
                                    <li>In-person and online training</li>
                                    <li>Employee login access</li>
                                    <li>Provincial and Federal Government compliance</li>
                                    <li>Customizable to your needs</li>
                                    <li>Quick online access allowing you to process your payroll from any location using your desktop, tablet or mobile device.</li>
                                    <li>Ongoing technical support</li>
                                    {/* <li><strong><a href='https://ian9592.wixsite.com/vtac/post/invest-now-earn-later' target='_blank' style={{ "color": "#E6001F" }}>Learn how to calculate employee payroll</a></strong></li> */}

                                </ul>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <div>
                                <img className="payrolServicesUlImage mt-5" alt="vpm interface tablet" title='vpm interface tablet' src={vpminterfacetablet1} />
                            </div>
                            <div>
                                <ul className='payrolServicesUl mt-5'>
                                    <li>Payroll deductions and benefits tailored to unpaid leave & union dues, multi-pay (hourly, weekly, bi-weekly, monthly, bi-monthly, annually), parental leave.</li>
                                    <li>Direct deposit & e-stubs for part-time employees</li>
                                    <li>Remit source deductions on your behalf without worrying about late penalties from the government.</li>
                                    <li>Track and reflect benefits in payroll reporting to government  & employees</li>
                                    <li>Can accommodate faxed or digital timesheets</li>
                                    <li>Creates and submits Records of Employment on your behalf</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default PayrollServicefifthPart;