import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class UnifiedHRSection5 extends Component {
    render() {
        return (
            <Fragment>
                <Container className='SectionBetween text-center'>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='container' style={{ width: "80%" }}>
                                <h2 className='mainTitle'>
                                    HELPING YOU MANAGE YOUR BUSINESS MORE <span className='spancolor'>EFFICIENTLY & EFFECTIVELY</span>
                                </h2>
                            

                            <p className='UnifiedHrSubTitle text-center'>Enjoy the convenience of having HR, benefits and payroll management integrated into one system. Some key benefits of HCM software include:
                            </p>
                            
                            <ul className='UnifiedHrUl'>
                                <li>Having HR, benefits and payroll information accessible through a single platform, meaning there is no need to switch between systems to find information.</li>
                                <li>Easily automated payroll deductions that are fully compliant with Canadian payroll regulations.</li>
                                <li>Efficient management of human capital from recruitment & onboarding, to performance evaluation and feedback, to exit interviews & offboarding.</li>
                                <li>Richer data source for HR analytics and reporting.</li>
                            </ul>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <Link to="/how-were-different"><Button variant="primary" className='button2 mt-4'><span>SEE HOW WE'RE DIFFERENT</span></Button></Link>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default UnifiedHRSection5;