import React, { Component, Fragment } from 'react';
import TopHead from '../components/TopHead/TopHead';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import QuoteComponentCyan from '../components/QuoteComponentCyan/QuoteComponentCyan';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import GoogleReview from '../components/GoogleReview/GoogleReview';
import GoogleReviewBanner from '../components/GoogleReviewBanner/GoogleReviewBanner';

class GoogleReviewPage extends Component {
    render() {
        return (
            <Fragment>
            <TopHead />
            <TopNavigation title="Google Reviews | V-TAC Payroll Management"/>
            <GoogleReviewBanner />
            <GoogleReview />
            <QuoteComponentCyan />
            <TradePart />
            <Footer />
        </Fragment>
        );
    }
}

export default GoogleReviewPage;