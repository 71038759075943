import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class UnifiedHRSection2 extends Component {
    render() {
        return (
            <Fragment>
                <Container className=''>
                    <Row>
                        <Col lg={12} md={12} sm={12}>                        
                                <h2 className='sixSectionContainerTitle '>HELPING YOU <span className='spancolor'>FOCUS</span> ON YOUR EMPLOYEES</h2>
                                <div className='text-center' style={{ "width": "90%" }} >
                                <p className='sixSectionContainerPra'>By using our Human Capital Management (HCM) service, you can integrate all crucial employee data under one roof – enabling you to make better decisions and lead the company to grow.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default UnifiedHRSection2;