import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
class QuoteComponentCyan extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className='quoteComponentCyan SectionBetween text-center'>
                    <div>
                        <Container>
                            <Row>
                                <Col>
                                    <h2 className='quoteComponentCyanTitle'>NEED HELP SIMPLIFYING YOUR PAYROLL?</h2>
                                    <h4 className='qoutecomponentCyanSubTitle'>Request a quote to see how VPM can fit the needs of your business. Join now to receive one FREE month of payroll & a tablet*!</h4>
                                    <Link to="/promotional-conditions"><p className='quoteCondition'>*some conditions apply</p></Link>
                                    <Link to="/request-payroll-quote"><Button className='quotebutton' ><span onClick={() => {
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }} >GET A QUOTE</span></Button></Link>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </Fragment>
        );
    }
}

export default QuoteComponentCyan;