import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import nexus3 from '../../asset/images/nexus-3.jpg';
import nexus4 from '../../asset/images/nexus-4.jpg';
import nexus5 from '../../asset/images/nexus-5.jpg';


class NexasStaffingSec2 extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className='SectionBetween nexasStaffingContainer ' >
                    <div className='SectionBetween p-5'>
                        <Container>
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="nexus3Img  " src={nexus3} alt='meeting' title='meeting' />
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <h3 className='commonTitle'>Making the switch to <span className='spancolor'>VPM</span></h3>
                                    <p className='desc'>Nexus switched and joined VPM in 2003, after its first year of operation.</p>
                                    <p className='desc'>Onboarding was free and quick – Nexus started using VPM within a week since first contacting VPM! </p>
                                    <p className='desc'>​After being immediately appointed a single point of contact (a dedicated account manager), Nexus was grateful that communication can be streamlined and they wouldn’t have to jump from help desk to help desk in order to get help when they need it.</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12}>

                                    <h3 className='commonTitle mt-5'>A <b>VPM</b> client for <span className='spancolor'>16 years!</span></h3>
                                    <p className='desc'>Nexus has been a client of VPM for nearly 16 years and has seen its business grow tremendously. The growth of Nexus has resulted in VPM producing over 3,000 T4s annually!</p>
                                    <p className='desc'>“It’s a partnership. I’ve recommended VPM to other businesses and I will continue to do so. They are very cost-effective, very fair. And their service is outstanding. VPM is also very nimble in the sense that employee hours are changing and coming in at different times, and despite this, accuracy is like 99.99 percent. We rarely get any errors,” said Mark.</p>

                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="nexus3Img mt-5" alt='vpm client' title='vpm client' src={nexus5} />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="nexus4Img mt-5" alt='looking at tablet' src={nexus4} />
                                </Col>
                                <Col lg={6} md={6} sm={12}>

                                    <h3 className='commonTitle mt-5'>The benefits of <span className='spancolor'>outsourcing payroll !</span></h3>
                                    <p className='desc'>“If you’re not outsourcing your payroll and doing it in-house,  I encourage you to outsource because you probably don’t realize how much time you’re actually spending on payroll. And if you already outsource, give VPM a shout for a free quote. There’s a lot of value in using VPM through the flexibility and service you get from their staff.</p>
                                    <p className='desc'>​Probably for us the biggest thing we get from VPM is their flexibility. They are very flexible compared to other payroll providers.</p>
                                    <p className='desc'>We’ve been dealing with our point of contact for the past 10 years and she’s great. It’s not a cookie-cutter service like you get from ADP,” said Mark as he reflected on his relationship with VPM.</p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </Fragment>
        );
    }
}

export default NexasStaffingSec2;