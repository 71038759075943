import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

//import { faSearch,faArrowCircleUp } from '@fortawesome/free-solid-svg-icons';

export default class TopHead extends Component {
    // scroll to top on page load
    componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    render() {
        return (
            <Fragment>
                <Container fluid={true} className='topHeader p-0' top='fixed'>
                    <div className='topSocialLink'>
                        <Row >
                            <Col lg={12} md={12} sm={12} >

                                <a href="https://www.facebook.com/vtacpayroll/" target='_blank' className="topHeadicon"><FontAwesomeIcon className='headFontawsome' icon={faFacebook} /></a>
                                <a href="https://www.linkedin.com/company/v-tac-payroll-management/" target='_blank' className="topHeadicon"><FontAwesomeIcon className='headFontawsome' icon={faLinkedin} /></a>
                                <a href="https://www.youtube.com/@v-tacpayrollmanagement3092/videos" target='_blank' className='topHeadicon' ><FontAwesomeIcon className='headFontawsome' icon={faYoutube} /></a>
                                <a href="tel:+1-866-315-8822" className='topHeadicon'><FontAwesomeIcon className='headFontawsome' icon={faPhone} /> 1-866-315-8822</a>
                                <a href="https://www.vtacpayroll.cloud" target='_blank' className='topHeadicon'>CLIENT LOGIN</a>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </Fragment>
        )
    }
}
