import React, { Component, Fragment } from 'react';
import TopHead from '../components/TopHead/TopHead';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import CareerBanner from '../components/CareerBanner/CareerBanner';
import CareerGrowingTeam from '../components/CareerGrowingTeam/CareerGrowingTeam';
import AskQuesCareer from '../components/AskQuesCareer/AskQuesCareer';

class CareersPage extends Component {
    render() {
        return (
            <Fragment>
                <TopHead />
                <TopNavigation title="Careers | V-TAC Payroll Management"/>
                <CareerBanner />
                <CareerGrowingTeam />
                <AskQuesCareer />
                <TradePart />
                <Footer />
            </Fragment>
        );
    }
}

export default CareersPage;