import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import mapofcanada from '../../asset/images/map-of-canada.png';
class WhoWeAreMap extends Component {
    render() {
        return (
            <Fragment>
                <Container className='SectionBetween'>
                    <div className='container' style={{width:"70%"}}><h2 className='mainTitle'>
                        CANADA’S TRUSTED PAYROLL  SERVICE PROVIDER FOR <strong className='spancolor'>25+ YEARS</strong>
                    </h2></div>
                    <Row>
                        <Col lg={8} md={6} sm={12}>
                        <h3 class="subTitle mt-5"><FontAwesomeIcon className = "iconBullet" icon= {faCheckDouble} /><span> Offices in Toronto and Calgary</span></h3>
                        <h3 class="subTitle"><FontAwesomeIcon className = "iconBullet" icon= {faCheckDouble} /><span> 50+ Employees</span></h3>
                        <h3 class="subTitle"><FontAwesomeIcon className = "iconBullet" icon= {faCheckDouble} /><span> Administering over 400,000 payments annually</span></h3>
                        <h3 class="subTitle"><FontAwesomeIcon className = "iconBullet" icon= {faCheckDouble} /><span> Paying over 20,000 employees annually</span></h3>
                        <h3 class="subTitle"><FontAwesomeIcon className = "iconBullet" icon= {faCheckDouble} /><span> Handling $500 million in client funds</span></h3>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                        <img className="ReducetheriskHospitalityimg1 float-right" alt='Map of Canada' title='Map of Canada' src={mapofcanada} />
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default WhoWeAreMap;