import React, { Fragment } from 'react';
import TopHead from '../components/TopHead/TopHead';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import QuoteComponentGray2 from '../components/QuoteComponentGray2/QuoteComponentGray2';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import PrivacyPolicy from '../components/PrivacyPolicy/PrivacyPolicy';
import ContactBanner from '../components/ContactBanner/ContactBanner';

import bannerImage from '../../src/asset/images/header-flag-canada2.jpg';

const PrivacyPolicyPage = () => {
    const headerData = { title: 'Privacy' };

    return (
        <Fragment>
            <TopHead />
            <TopNavigation title="Privacy policy | V-TAC Payroll Management" />
            <ContactBanner image={bannerImage} bannerTitle={headerData} />
            <PrivacyPolicy />
            <QuoteComponentGray2 />
            <TradePart />
            <Footer />
        </Fragment>
    );
}


export default PrivacyPolicyPage;