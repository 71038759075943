import React, { Component } from 'react';
import { Fragment } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class ConditionSection extends Component {
    render() {
        return (
            <Fragment>
                <Container className='SectionBetween'>
                    <Row>
                        <Col>
                            <h3 className='ConditionSubTitle'>Qualified businesses can receive one free month of payroll and a free tablet.<br></br>
                                <b>Offer valid until February 28th, 2023.</b></h3>
                            <p className='ConditionDesc'>
                                Eligibility requirements to apply for the promotion include:</p>
                            <ul className='ConditionUl'>
                                <li>Must be a Canadian business with a minimum of 5 employees per payroll</li>
                                <li>Agrees to work with VPM for a minimum 1 year</li>
                                <li>Must request a quote online and switch to VPM for payroll</li>
                            </ul>

                            <p className='ConditionDesc'>After 3 months of continuous service, a tablet will be sent to the business address provided. The brand of tablet is dependent upon supply available, businesses can expect to receive a brand-new Lenovo, Samsung or Acer tablet.</p>
                            <p className='ConditionDesc'>To get started, please click on the Request Quote button below.</p>
                            <Link to="/request-payroll-quote"><Button className='navButtonLink mt-3'><span>REQUEST QUOTE</span></Button></Link>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default ConditionSection;