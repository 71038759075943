import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class UnifiedHRSection1 extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="unifiedHRTopBanner  p-0">
                    <div className='payrollServicesTopBannerOverly'>
                        <Container className="topContent text-center">
                            <Row>
                                <Col lg={12} md={12} sm={12} >
                                    <h1 className='topTitle'>
                                        REDUCE PROCESSING TIME & ERRORS BY INTEGRATING HR WITH PAYROLL
                                    </h1>
                                    <div style={{ width: "70%" }} className=" container text-center">
                                        <h3 className='topSubTitle'>
                                            Manage your workforce & setup benefits/deductions based on your unique payroll process
                                        </h3>
                                    </div>
                                    <Link to="/request-payroll-quote"><Button variant="primary" className='quotebutton mt-2'><span>GET A QUOTE</span></Button></Link>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </Fragment>
        );
    }
}

export default UnifiedHRSection1;