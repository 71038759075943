import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons';


class WhoWeAreMiddleBanner extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="payrollServicesTopBanner  p-0">
                    <div className='payrollServicesTopBannerOverly'>
                        <Container className="topContent text-center">
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <h3 className='topTitle'>
                                    RELIABLE <strong className='spancolor'> PAYROLL</strong> EXPERTS
                                    </h3>


                                    <h3 className='topSubTitle'>
                                    Having considerable payroll management expertise and experience spanning over 20 years, you can rest assured that your organization’s payroll reports, direct deposits, cheque issuances, CRA remittance payments and so on will be managed reliably and accurately.


                                    </h3>

                                    <div className="HhoWeLinkDiv mt-5 text-center">
                                        <a href="https://www.linkedin.com/company/v-tac-payroll-management/" target='_blank' className="footerSocialLink"><FontAwesomeIcon className='footerfontAwsome' icon={faLinkedin} /></a>
                                        <a href="https://www.facebook.com/vtacpayroll/" target='_blank' className='footerSocialLink' ><FontAwesomeIcon className='footerfontAwsome' icon={faFacebook} /></a><br />
                                        <a href="https://www.youtube.com/watch?v=oiUQf14cbHs" target='_blank' className='footerSocialLink' ><FontAwesomeIcon className='footerfontAwsome' icon={faYoutube} /></a>
                                    </div>

                                </Col>

                            </Row>
                        </Container>

                    </div>

                </Container>

            </Fragment>
        );
    }
}

export default WhoWeAreMiddleBanner;