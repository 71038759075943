import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class UnifiedHRSection6 extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className='quoteComponentGray SectionBetween text-center'>
                    <div>
                        <Container>
                            <Row>
                                <Col>
                                    <h2 className='qoutetopTitle'>NEED HELP SIMPLIFYING YOUR PAYROLL?</h2>
                                    <h4 className='qoutetopSubTitle'>Request a quote to see how VPM can fit the needs of your business. Join now to receive one <span className='spancolor'>FREE month of payroll & a tablet*!</span></h4>
                                    <Link to="/promotional-conditions"><p className='quoteCondition'>*some conditions apply</p></Link>

                                    <Link to="/request-payroll-quote"><Button className='quotebutton'><span>GET A QUOTE</span></Button></Link>

                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </Fragment>
        );
    }
}

export default UnifiedHRSection6;