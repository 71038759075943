import React, { Component, Fragment } from 'react';
import DentalOfficesSection1 from '../components/DentalOfficesSection1/DentalOfficesSection1';
import DentalOfficesSection2 from '../components/DentalOfficesSection2/DentalOfficesSection2';
import QuoteComponentGray from '../components/QuoteComponentGray/QuoteComponentGray';
import TopHead from '../components/TopHead/TopHead';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import DentalWhySwitchVpm from '../components/DentalWhySwitchVpm/DentalWhySwitchVpm';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import DentalOfficesSection4 from '../components/DentalOfficesSection4/DentalOfficesSection4';
import DentalOfficesSlide from '../components/DentalOfficesSlide/DentalOfficesSlide';
import QuoteComponentGray2 from '../components/QuoteComponentGray2/QuoteComponentGray2';
import AskQuesDentalOffices from '../components/AskQuesDentalOffices/AskQuesDentalOffices';


class DentalOfficesPage extends Component {
    render() {
        return (
            <Fragment>
                <TopHead />
                <TopNavigation title="Dental Offices | V-TAC Payroll Management"/>
                <DentalOfficesSection1 />
                <DentalOfficesSection2 />
                <DentalWhySwitchVpm />
                <DentalOfficesSection4 />
                <DentalOfficesSlide />
                <QuoteComponentGray2 />
                <AskQuesDentalOffices />
                <QuoteComponentGray />
                <TradePart />
                <Footer />
            </Fragment>
        );
    }
}

export default DentalOfficesPage;