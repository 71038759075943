import React, { Component, Fragment } from 'react';
import TopHead from '../components/TopHead/TopHead';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import QuoteComponentGray2 from '../components/QuoteComponentGray2/QuoteComponentGray2';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import ConditionBanner from '../components/ConditionBanner/ConditionBanner';
import ConditionSection from '../components/ConditionSection/ConditionSection';


class ConditionPage extends Component {
    render() {
        return (
            <Fragment>
                <TopHead />
                <TopNavigation title="Conditions for a Free Tablet & First Month | V-TAC Payroll Management"/>
                <ConditionBanner />
                <ConditionSection />
                <QuoteComponentGray2 />
                <TradePart />
                <Footer />
            </Fragment>
        );
    }
}

export default ConditionPage;