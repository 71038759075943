import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import Vpm1 from '../../asset/images/VPM1.jpg';

// import AOS from 'aos';
// import 'aos/dist/aos.css';

class QuotationSend extends Component {
    componentDidMount() {
        //AOS.init();
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <Fragment>
                <Container fluid={true} className='quoteTopPage SectionBetween text-center'>
                    <div>
                        <Container>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <h1 className='qoutetopTitle'>GET A FREE QUOTE WITHIN 24 HOURS!</h1>
                                    <h4 className='quoteTopSubTitle'>Fill out the form below to receive a custom quote from our experts in the nick of time! Switch to VPM and <span className='spancolor'> FREE month of payroll & a tablet*!</span></h4>
                                    <Link to="/promotional-conditions"><p className='quoteCondition'>*some conditions apply</p></Link>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>

                <Container className='SectionBetween'>
                    <Row>
                        <Col lg={6} md={12} sm={12}>
                            <div className=" quoteCardSuccess" data-aos="fade-right"
                                data-aos-anchor="#example-anchor"
                                data-aos-offset="500"
                                data-aos-duration="1000">
                                <div id='SuccessMSgDiv'>
                                    <h2 className='SuccessMsg'>Thank you for submitting your request for a quote. We will get back to you shortly.</h2>
                                    <p className='SuccessMsgDesc'>if you would like to speak with someone right away, feel free to call us  on our toll number at:</p>
                                    <p className='SuccessMsgDesc mb-5' ><a href="tel:+1-866-315-8822" style={{ color: "#E6001F" }}><FontAwesomeIcon className='headFontawsome' icon={faPhone} /> 1-866-315-8822</a></p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <div data-aos="fade-left"
                                data-aos-anchor="#example-anchor"
                                data-aos-offset="500"
                                data-aos-duration="1000"> <img className="quotationRequestImg" src={Vpm1} />
                            </div>

                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default QuotationSend;