import React, { Component, Fragment } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import pyper from '../../asset/images/pyper.png';
import kapoor from '../../asset/images/kapoor.png';
import danielbrown from '../../asset/images/daniel-brown.png';
import weisberglaw from '../../asset/images/weisberg-law.png';
import edwardroyle from '../../asset/images/edward-royle.png';
import fentonsmith from '../../asset/images/fenton-smith.png';
import brownmillsklinck from '../../asset/images/brown-mills-klinck.png';
import kba from '../../asset/images/kba.png';
import kinglaw from '../../asset/images/king-law.png';
import robichauds from '../../asset/images/robichauds.png';
import pollacktsimmerman from '../../asset/images/pollack-tsimmerman.png';
import rosenkirshen from '../../asset/images/rosen-kirshen.png';
import taplaw from '../../asset/images/tap-law.png';
import singerkatz from '../../asset/images/singer-katz.png';
import vk from '../../asset/images/vk.png';
import yetilaw from '../../asset/images/yeti-law.png';

class LawFirmSlide extends Component {
    render() {
        var settings = {
            dots: false,
            arrows: true,
            infinite: true,
            speed: 3000,
            slidesToShow: 6,
            slidesToScroll: 3,
            autoplay: true,
            autoplaySpeed: 2000,


            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <Fragment>

                <Container className="slideShowSection SectionBetween">
                    <h2 className='mainTitle text-center mb-5'><strong className='spancolor'>Trusted</strong> BY FIRMS LIKE YOURS</h2>
                    <Slider {...settings}>

                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={kapoor} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={danielbrown} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={weisberglaw} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={edwardroyle} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={fentonsmith} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={brownmillsklinck} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={kba} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={kinglaw} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={robichauds} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={pyper} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={pollacktsimmerman} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={rosenkirshen} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={taplaw} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={singerkatz} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={vk} /></a>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <a> <img className="clientImage" src={yetilaw} /></a>
                                </Col>
                            </Row>
                        </div>
                    </Slider>


                </Container>
            </Fragment>
        );
    }
}

export default LawFirmSlide;