import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Event from '../../asset/images/Event.jpg';

class NewsEventsInsights extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className='SectionBetween newsEventContainer'>
                    <h2 className='mainTitle pt-3'>News, Events & Insights</h2>
                    <div>
                        <Container className='newsEventDiv'>
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <div data-aos="fade-up" data-aos-duration="1000"
                                        data-aos-once="true">
                                        <img className="restaurantOwnerwithlaptopImg " alt="Payroll Frequency" title='Payroll Frequency' src={Event} loading="lazy" />
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <div class="mt-5 p-2" data-aos="fade-up" data-aos-duration="1000"
                                        data-aos-once="true"><h4 class="subTitle">Which Payroll Frequency is the Most Effective?</h4>
                                        <p className='description'>The most common payroll frequencies include weekly, bi-weekly, semi-monthly and monthly. We will go into more detail for each and outline the pros and cons and why an employer might choose one type of frequency over another for their payroll process. </p>
                                    </div>
                                    <div>
                                        <Link to="/industry-news"><Button className='Storybutton'><span>READ MORE</span></Button></Link>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                </Container>
            </Fragment>
        );
    }
}

export default NewsEventsInsights;