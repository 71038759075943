import React, { Component, Fragment } from 'react';
import TopHead from '../components/TopHead/TopHead';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import QuoteComponentCyan from '../components/QuoteComponentCyan/QuoteComponentCyan';
import Footer from '../components/Footer/Footer';
import IndustryNewsBanner from '../components/IndustryNewsBanner/IndustryNewsBanner';
import VpmBlog from '../components/VpmBlog/VpmBlog';
import { Container } from 'react-bootstrap';

class IndustryNewsPage extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid="true" className='accContainer'>
                    <TopHead />
                    <TopNavigation title="Industry News | V-TAC Payroll Management" />
                    <IndustryNewsBanner />
                    <VpmBlog />
                    <QuoteComponentCyan />
                    <TradePart />
                    <Footer />
                </Container>

            </Fragment>
        );
    }
}

export default IndustryNewsPage;