import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import employment1 from '../../asset/images/employment-1.jpg';
import employment2 from '../../asset/images/employment-2.jpg';
import employment3 from '../../asset/images/employment-3.jpg';
import employment4 from '../../asset/images/employment-4.jpg';

class EmploymentAgenReduceTIme extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className='SectionBetween  ' >
                    <div className='SectionBetween p-5'>
                        <Container >
                            <Row>
                                <Col lg={6} md={6} sm={12} style={{ width: "20%" }}>
                                    <img className="ReducetheriskHospitalityimg2" alt='Employment interview'title='Employment interview' src={employment1} />
                                </Col>
                                <Col lg={6} md={6} sm={12} style={{ width: "80%" }}>

                                    <div>
                                        <h3 class="commonTitle"><strong className='spancolor'>Reduce processing time</strong> by outsourcing payroll</h3>
                                        <p className='commonDesc'>Eliminate the burden from your accounting team and outsource our payroll for quick and accurate processing.</p>
                                        <p className='commonDesc'>VPM ensures Federal and Provincial compliance and solves any issues on your behalf so that you have the time to focus on your clients.</p></div>
                                </Col>
                            </Row>
                            <Row className='SectionBetween'>
                                <Col lg={6} md={6} sm={12} >
                                    <h3 className='commonTitle'>How Nexus Staffing <strong className='spancolor'>found growth</strong> with VPM</h3>
                                    <p className='commonDesc'>“If you’re not outsourcing your payroll and doing it in-house, I encourage you to outsource because you probably don’t realize how much time you’re actually spending on payroll.</p>
                                    <p className='commonDesc'>And if you already outsource, give them a shout for a quote. There’s a lot of value using VPM through flexibility and service you get from their staff.”</p>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="ReducetheriskHospitalityimg2 float-right" alt='Employment agency' title='Employment agency'  src={employment2} />
                                </Col>

                                <Link to="/nexus-staffing"><Button variant="primary" className='button'><span>Read the full story</span></Button>{' '}</Link>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12} style={{ width: "20%" }}>
                                    <img className="ReducetheriskHospitalityimg2" alt='Employment agency' title='Employment agency' src={employment3} />
                                </Col>
                                <Col lg={6} md={6} sm={12} style={{ width: "80%" }}>

                                    <div ><h3 class="commonTitle float-left">A payroll system<strong className='spancolor'> built for your</strong> employment agency</h3>
                                        <p className='commonDesc'>No matter the size of your employment agency, VPM is capable of tailoring your payroll system to fit the needs of your unique business. We will make sure that your employees are paid on time and correctly, so you can focus your time on other tasks.</p>
                                        <p className='commonDesc'>Learn more about our payroll services for employment agencies across Canada.</p></div>
                                </Col>
                            </Row>
                            <Row className='SectionBetween'>
                                <Col lg={6} md={6} sm={12} >
                                    <h3 className='commonTitle'>Enjoy the <strong className='spancolor'>convenience </strong> of online payroll with VPM</h3>
                                    <p className='commonDesc'>When you work with us, you benefit from the convenience of using our online platform to submit your payroll. VPM uses a secure proprietary payroll system that gives your managers and employees access to all the payroll-related information they may need.</p>
                                    <p className='commonDesc'>Get started with VPM’s payroll services today and book a consultation with our team. </p>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="ReducetheriskHospitalityimg2 float-right" alt='Employment agency' title='Employment agency' src={employment4} />
                                </Col>
                                <Link to="/contact"><Button variant="primary" className='button'><span>CONTACT US</span></Button>{' '}</Link>
                            </Row>
                        </Container>
                    </div>

                </Container >
            </Fragment >
        );
    }
}

export default EmploymentAgenReduceTIme;