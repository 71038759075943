import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import meeting1 from '../../asset/images/meeting1.jpg';
import meeting2 from '../../asset/images/meeting2.jpg';

class PayrollServicesSeventhPart extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className='SectionBetween payrollSerContainer' >
                    <div className='SectionBetween p-5'>
                        <Container>
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <h2 className='commonTitle'>Our errors & omissions <span className='spancolor'>guarantee</span></h2>
                                    <p className='desc'>We guarantee to file all source deductions accurately and pay taxes and all disbursements on time. We protect all our clients against financial loss by comprehensive errors and omissions liability insurance which is a standard part of our e‐payroll management contract.</p>
                                    <p className='desc'>Additionally, our well‐designed and well‐tested disaster recovery plan minimizes the impact on your business’s bottom line.</p>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="errorOmissionsImg" src={meeting1} alt="Three people meeting" title='Three people meeting' />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="errorOmissionsImg mt-5 "  src={meeting2} alt="Two people looking at a tablet" title='Two people looking at a tablet' />
                                </Col>
                                <Col lg={6} md={6} sm={12}>

                                    <h2 className='commonTitle mt-5'><span className='spancolor'>Integrating</span> third-party systems</h2>
                                    <p className='desc'>Map your General Ledger accounts to VPM with the help of your existing accounting system. Produce reports or file-based system integration options as desired.</p>
                                    <p className='desc'>If you have a preference for a 3rd party timesheet application in terms of integrating data your employees can enter into our payroll system, we offer some flexible options. For more details on system integrations, please <Link to="/contact" className='faqLink'>contact us</Link> .</p>

                                </Col>
                            </Row>

                        </Container>
                    </div>

                </Container>
            </Fragment>
        );
    }
}

export default PayrollServicesSeventhPart;