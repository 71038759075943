import React, { Component, Fragment } from 'react';
import HowWeAreDifferentBanner from '../components/HowWeAreDifferentBanner/HowWeAreDifferentBanner';
import HowWeAreDifferentCauses from '../components/HowWeAreDifferentCauses/HowWeAreDifferentCauses';
import TopHead from '../components/TopHead/TopHead';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import QuoteComponentCyan from '../components/QuoteComponentCyan/QuoteComponentCyan';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';

class HowWeAreDifferentPage extends Component {
    render() {
        return (
           <Fragment>
             <TopHead />
                <TopNavigation title="How We're Different | V-TAC Payroll Management"/>
                <HowWeAreDifferentBanner />
                <HowWeAreDifferentCauses />
                <QuoteComponentCyan />
                <TradePart />
                <Footer />
           </Fragment>
        );
    }
}

export default HowWeAreDifferentPage;