import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

class AskQuesCareer extends Component {
    handleClick(id) {
        var coll = document.getElementById(id);
        coll.classList.toggle("factive");
        var content = coll.nextElementSibling;
        if (content.style.maxHeight) {
            content.style.maxHeight = null;
        } else {
            content.style.maxHeight = content.scrollHeight + "px";
        }
    }
    render() {
        return (
            <Fragment>
                <Container className='SectionBetween text-center container'>
                    <Row>
                        <Col>
                            <div className='card'>
                                <div style={{ width: "70%" }} className=" container text-center mt-3"><h2 className='mainTitle text-center ' >CURRENT OPPORTUNITIES
                                </h2>
                                </div>

                                <button class="FAQcollapsible" id='b1' onClick={() => this.handleClick('b1')}>Sales Associate, Toronto</button>
                                <div class="FAQcontent">
                                    <p className='FaqDescription'><strong>Job description</strong></p>
                                    <ul className='FaqDescription'>
                                        <li>Work with and generate sales leads and strategies</li>
                                        <li>Develop and maintain relationships with new and prospective clients</li>
                                        <li>Maintain client records in the CRM application</li>
                                        <li>Communicate VPM’s unique value proposition to prospective clients using a variety of channels and methods
                                        </li>
                                        <li>Prepare and deliver detailed sales proposals and other information as needed</li>
                                        <li>Contribute to continuous improvement of the sales process for prospective clients</li>
                                        <li>Some travel may be required</li>
                                    </ul>
                                    <p className='FaqDescription'><strong>Skills and other qualifications</strong></p>
                                    <ul className='FaqDescription'>
                                        <li>Post-secondary degree or diploma in marketing, accounting or finance</li>
                                        <li>Strong interpersonal skills</li>
                                        <li>Excellent communication skills</li>
                                        <li>Prior sales experience (3-5 years) is preferred</li>
                                        <li>Able to work independently and collaboratively with the sales team</li>
                                        <li>Proficient with MS Office and other applications (e.g. CRM)</li>
                                    </ul>
                                </div>
                                <button class="FAQcollapsible" id='b2' onClick={() => this.handleClick('b2')}>Payroll Consultant, Toronto</button>
                                <div class="FAQcontent">
                                    <p className='FaqDescription'><strong>Job description</strong></p>
                                    <ul className='FaqDescription'>
                                        <li>Perform payroll processing and account management of assigned clients, including data entry and secondary checks as needed (system setups, request forms, timesheets etc.)</li>
                                        <li>Develop and maintain strong relationships and a trusted rapport with clients</li>
                                        <li>Maintain client records in the CRM application</li>
                                        <li>Strong problem solving skills</li>
                                        <li>Contribute to the continuous improvement of internal processes and procedures, and client management</li>
                                    </ul>
                                    <p className='FaqDescription'><strong>Skills and other qualifications</strong></p>
                                    <ul className='FaqDescription'>
                                        <li>Post-secondary degree or diploma in business, accounting or finance</li>
                                        <li>Canadian Payroll Association (CPA) certification or in progress</li>
                                        <li>Prior payroll experience – 3 years minimum is preferred – with good working knowledge of payroll accounting, general accounting procedures, and preparation of government remittances</li>
                                        <li>Strong interpersonal and customer service skills</li>
                                        <li>Excellent communication skills</li>
                                        <li>Able to work independently and collaboratively with operation teams</li>
                                        <li>Willingness to learn</li>
                                        <li>Comfortable learning and working with applications and systems (e.g. Payroll or CRM systems)</li>
                                        <li>Proficient with MS Office with strong Excel skills and attention to detail</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default AskQuesCareer;