import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

class AnualSummay extends Component {
    render() {
        return (
            <Fragment>

                <Container fluid={true} className=" SectionBetween text-center p-0" >
                    <div className='summarySection'>
                        <Container fluid="true" >
                            <Row>

                                <Col lg={2} md={4} sm={12} style={{ marginLeft: "6rem" }}>
                                    <div>
                                        <h2 className="countNumber">
                                        <CountUp start={0} end={100} duration={2}>
                                            {({ countUpRef, start }) => (

                                                <VisibilitySensor onChange={start}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>

                                            )}
                                        </CountUp>
                                            %
                                        </h2>
                                        <h5 className="countTitle">
                                            canadian company
                                        </h5>
                                        <div style={{ width: "30%" }} className="container"><hr className="hrsummary" /></div>
                                    </div>
                                </Col>
                                <Col lg={2} md={4} sm={12} >
                                    <h2 className="countNumber">
                                    <CountUp start={0} end={25} duration={2}>
                                            {({ countUpRef, start }) => (

                                                <VisibilitySensor onChange={start}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>

                                            )}
                                        </CountUp>
                                        + Years
                                    </h2>
                                    <h5 className="countTitle">
                                        experience
                                    </h5>
                                    <div style={{ width: "30%" }} className="container text-center"><hr className="hrsummary" /></div>
                                </Col>

                                <Col lg={2} md={4} sm={12}>

                                    <h2 className="countNumber">
                                        <CountUp start={0} end={400000} duration={2} separator=",">
                                            {({ countUpRef, start }) => (

                                                <VisibilitySensor onChange={start}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>

                                            )}
                                        </CountUp>
                                        +
                                    </h2>
                                    <h5 className="countTitle" >
                                        payments administered annually
                                    </h5>
                                    <div style={{ width: "30%" }} className="container"><hr className="hrsummary" /></div>
                                </Col>
                                <Col lg={2} md={4} sm={12} >
                                    <h2 className="countNumber">
                                        <CountUp start={0} end={20000} duration={2} separator=",">
                                            {({ countUpRef, start }) => (

                                                <VisibilitySensor onChange={start}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>

                                            )}
                                        </CountUp>
                                        +
                                    </h2>
                                    <h5 className="countTitle">
                                        employees paid annually
                                    </h5>
                                    <div style={{ width: "30%" }} className="container"><hr className="hrsummary" /></div>

                                </Col>
                                <Col lg={2} md={4} sm={12} >
                                    <h2 className="countNumber">

                                        $
                                        <CountUp start={0} end={500} duration={2}>
                                            {({ countUpRef, start }) => (

                                                <VisibilitySensor onChange={start}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>

                                            )}
                                        </CountUp>
                                        +&nbsp;million
                                    </h2>
                                    <h5 className="countTitle">
                                        in client funds
                                    </h5>
                                    <div style={{ width: "30%" }} className="container"><hr className="hrsummary" /></div>

                                </Col>
                                {/* <Col lg={2} md={4} sm={12}>
                                </Col> */}
                            </Row>
                        </Container>
                    </div>



                </Container>
            </Fragment>
        );
    }
}

export default AnualSummay;