import React, {  Fragment } from 'react';
import ContactBanner from '../components/ContactBanner/ContactBanner';
import ContactSection from '../components/ContactSection/ContactSection';
import Footer from '../components/Footer/Footer';
import QuoteComponentCyan from '../components/QuoteComponentCyan/QuoteComponentCyan';
import TopHead from '../components/TopHead/TopHead';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import bannerImage from '../../src/asset/images/header-flag-canada2.jpg';
const ContactPage = () => {
    const headerData = { title: 'Contact' };

    return (
        <Fragment>
            <TopHead />
            <TopNavigation title="Contact | V-TAC Payroll Management" />
            <ContactBanner  image={bannerImage} bannerTitle={headerData} />
            <ContactSection />
            <QuoteComponentCyan />
            <TradePart />
            <Footer />
        </Fragment>
    );
}


export default ContactPage;