import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class InsAgentFeedback1 extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="nexasClientSayCard SectionBetween  p-0">
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <div className='container' style={{ width: "50%" }}>
                                <p className='nexasCardDesc SectionBetween'>
                                “It’s never just about money. There are a lot of other factors to consider. In our business, we know it’s very important to be able to demonstrate to clients through our actions that the quality of the relationship matters. We believe this key to a successful experience over the long-term.”
                                </p>
                                <p className='nexasCardName mt-5'>Asif Khan</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default InsAgentFeedback1;