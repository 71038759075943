import React, { Component, Fragment } from 'react';
import TopHead from '../components/TopHead/TopHead';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import QuoteComponentCyan from '../components/QuoteComponentCyan/QuoteComponentCyan';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import ClientSuccessStories from '../components/ClientSuccessStories/ClientSuccessStories';
import ClientSuccessStoriesBanner from '../components/ClientSuccessStoriesBanner/ClientSuccessStoriesBanner';
import Client from '../components/Client/Client';


class ClientSuccessStoriesPage extends Component {
    render() {
        return (
            <Fragment>
                <TopHead />
                <TopNavigation title="Client Success Stories | V-TAC Payroll Management"/>
                <ClientSuccessStoriesBanner />
                <ClientSuccessStories />
                <Client />
                <QuoteComponentCyan />
                <TradePart />
                <Footer />
            </Fragment>
        );
    }
}

export default ClientSuccessStoriesPage;