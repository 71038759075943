import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt, faMousePointer, faComments, faHandsHelping, faBookmark, faSearch, faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import clock from '../../asset/images/clock.svg';
import ban from '../../asset/images/ban.svg';
import settingssliders from '../../asset/images/settings-sliders.svg';
import exclamation from '../../asset/images/exclamation.svg';
import filechartline from '../../asset/images/file-chart-line.svg';

class WhySwitchVpm extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className='serviceContainer SectionBetween'>
                    <div className='serviceSection'>
                        <h2 className='cardMainTitle ' >
                            WHY <span className='spancolor '>SWITCH</span> TO VPM</h2>
                        <Container>
                            <Row>
                                <Col lg={3} md={6} sm={12} >
                                    <Link to='/how-were-different#s-1'>
                                        <Card className="serviceCard text-center" data-aos="zoom-in" data-aos-duration="1500"
                                            data-aos-once="true">
                                            {/* <img className="cardlogoImg text-center" alt="Paris" src={Logo} /> */}

                                            <Card.Body>
                                                <Card.Img className="cardlogoImg" src={exclamation} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>Affordable &amp; No Hidden Fees</span></h4></Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>Get the most bang for your buck with competitively priced payroll. No setup fees.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>

                                <Col lg={3} md={6} sm={12}>
                                    <Link to='/how-were-different#s-2'>
                                        <Card className="serviceCard text-center" data-aos="zoom-in" data-aos-duration="1500"
                                            data-aos-once="true">
                                            <Card.Body>
                                                <Card.Img className="cardlogoImg" src={settingssliders} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>Customizable to Your Needs</span></h4></Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>Adapt VPM to your unique business needs at no cost.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>

                                <Col lg={3} md={6} sm={12}>
                                    <Link to='/how-were-different#s-3'>
                                        <Card className="serviceCard text-center" data-aos="zoom-in" data-aos-duration="1500"
                                            data-aos-once="true">
                                            <Card.Body>
                                                <Card.Img className="cardlogoImg" src={clock} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>Quick Setup</span></h4></Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>Start using our payroll services within 48 hours no matter the size of your business!</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={12}>
                                    <Link to='/how-were-different#s-4'>
                                        <Card className="serviceCard text-center" data-aos="zoom-in" data-aos-duration="1500"
                                            data-aos-once="true">
                                            <Card.Body>
                                                <Card.Img className="cardlogoImg" src={ban} />
                                                <Card.Title><h4 class="serviceCardTitle"><span> No Contracts</span></h4></Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>Unlike with other payroll providers, we don’t lock you into contracts. Use VPM for as long as you need.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                            </Row>
                            <Row className=''>

                                <Col lg={3} md={6} sm={12}>
                                    <Link to='/how-were-different#s-5'>
                                        <Card className="serviceCard text-center" data-aos="zoom-in" data-aos-duration="1500"
                                            data-aos-once="true">
                                            <Card.Body>
                                                <FontAwesomeIcon className="cardlogoImg" icon={faMousePointer} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>Easy to Use</span></h4></Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>No more headaches when it’s time for payroll. Intuitive &amp; simple design for maximum productivity.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={12}>
                                    <Link to='/how-were-different#s-6'>
                                        <Card className="serviceCard text-center" data-aos="zoom-in" data-aos-duration="1500"
                                            data-aos-once="true">
                                            <Card.Body>
                                                <FontAwesomeIcon className="cardlogoImg" icon={faMobileAlt} />
                                                <Card.Title ><h4 class="serviceCardTitle"><span>Access from Anywhere</span></h4></Card.Title>
                                                <Card.Text>
                                                    <p className='serviceCardDes'>Connect to VPM from anywhere in Canada on your mobile or computer.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={12}>
                                    <Link to='/how-were-different#s-7'>
                                        <Card className="serviceCard" data-aos="zoom-in" data-aos-duration="1500"
                                            data-aos-once="true">
                                            <Card.Body>
                                                <Card.Img className="cardlogoImg" src={filechartline} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>Gain Insights with GL</span></h4></Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>Know where your money is going and keep track of all financial data.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={12}>
                                    <Link to='/how-were-different#s-8'>
                                        <Card className="serviceCard" data-aos="zoom-in" data-aos-duration="1500"
                                            data-aos-once="true">
                                            <Card.Body>
                                                <FontAwesomeIcon className="cardlogoImg" icon={faComments} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>Fast Response Time</span></h4></Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>Get help in 30 minutes or less, guaranteed!</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={3} md={6} sm={12}>
                                    <Link to='/how-were-different#s-9'>
                                        <Card className="serviceCard" data-aos="zoom-in" data-aos-duration="1500"
                                            data-aos-once="true">
                                            <Card.Body>
                                                <FontAwesomeIcon className="cardlogoImg" icon={faBookmark} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>25+ Yrs of Experience</span></h4></Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>A dedicated payroll service developed with real businesses in mind.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={12}>
                                    <Link to='/how-were-different#s-10'>
                                        <Card className="serviceCard" data-aos="zoom-in" data-aos-duration="1500"
                                            data-aos-once="true">
                                            <Card.Body>
                                                <FontAwesomeIcon className="cardlogoImg" icon={faHandsHelping} />

                                                <Card.Title><h4 class="serviceCardTitle"><span>Less Hands-On</span></h4></Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>Spend less time trying to prepare your payroll documents, let us do all the work for you.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={12}>
                                    <Link to='/how-were-different'>
                                        <Card className="serviceCard" data-aos="zoom-in" data-aos-duration="1500"
                                            data-aos-once="true">
                                            <Card.Body>
                                                <FontAwesomeIcon className="cardlogoImg" icon={faHourglassHalf} />
                                                <Card.Title class="serviceCardTitle">Save Time</Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>Spend less time trying to prepare your payroll documents, let us do all the work for you.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3} md={6} sm={12}>
                                    <Link to='/how-were-different'>
                                        <Card className="serviceCard" data-aos="zoom-in" data-aos-duration="1500"
                                            data-aos-once="true">
                                            <Card.Body>
                                                <FontAwesomeIcon className="cardlogoImg" icon={faSearch} />
                                                <Card.Title><h4 class="serviceCardTitle"><span>Track All Employee- Related Data</span></h4></Card.Title>

                                                <Card.Text>
                                                    <p className='serviceCardDes'>Access insights on age, seniority, work history, skills, salary/wage increases.</p>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                            </Row>
                        </Container>

                    </div>



                </Container >
            </Fragment >
        );
    }
}

export default WhySwitchVpm;