import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import irwinlogo from '../../asset/images/irwin-logo.png';
import axiomaticlogo from '../../asset/images/axiomatic-logo.png';
import sandhulogo from '../../asset/images/sandhu-logo.png';
import afffyslogo from '../../asset/images/afffys-logo.png';
import gaberlogo from '../../asset/images/gaber-logo.png';
import kfclogohoriz from '../../asset/images/kfc-logo-horiz.png';



class WhoWeAreServingBusiness extends Component {
    render() {
        return (
            <Fragment>
                <Container className="SectionBetween text-center">
                    <h2 className="mainTitle">SERVING <strong className='spancolor'>800+</strong> BUSINESSES ACROSS NUMEROUS VERTICALS</h2>
                    <Row>
                        <Col lg={4} md={6} sm={12}>
                            <div className="servingCard1" >

                                <img className="servingImage" src={irwinlogo} alt='irwin' />
                                <p className='commonDesc text-center'>“We have used VPM to help with our payroll advisory. We are more than happy with the work they have done for us.”</p>
                                <p className="serviceCardTitle text-center" >Marilyn <br></br> Irwin Seating Company</p>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className="servingCard1 text-center">
                                <img className="servingImage" src={axiomaticlogo} alt='axiomatic' />
                                <p className="commonDesc text-center">“We are proud to say that we have developed a sense of trust in VPM’s professional services. We would highly recommend them to any other similar business with complete confidence.”</p>
                                <p className="serviceCardTitle text-center" href="#">Elzbieta,<br></br> Axiomatic Technologies Corp</p>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className="servingCard1 text-center">
                                <img className="servingImage" src={sandhulogo}  alt='sandhu'/>
                                <p className="commonDesc text-center">“Working with VPM has always been a pleasure. They are friendly, resourceful, and insightful. Most importantly, all of our company’s information remained secure and confidential. We respect VPM’s integrity and standard of service.”</p>
                                <p className="serviceCardTitle text-center" href="#">Cheryl <br></br>
                                    Dr. G & R. Sandhu Dentistry</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col lg={4} md={6} sm={12}>
                            <div className="servingCard" >

                                <img className="servingImage"  src={afffyslogo} alt='afffys'/>
                                <p className="commonDesc text-center">“My company has had a high turnover rate with bookkeepers. Just when they were trained and things began to run smoothly, they would have a change of mind and leave. This was stressful because it meant having to hire and re-train a new bookkeeper. By outsourcing my payroll to VPM, I don’t have to worry about staff quitting because I have a reliable payroll partner.”</p>
                                <p className="serviceCardTitle text-center">Afsa<br></br>
                                    Affy’s Premium Grill</p>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className="servingCard text-center">
                                <img className="servingImage" src={gaberlogo} alt='gaber'/>
                                <p className="commonDesc text-center">“VPM’s services are unlike any others’ in the way they easily customized our payroll to the needs of our company while adhering to their 24-hour delivery policy. I would definitely recommend their services to other companies.”</p>
                                <p className="serviceCardTitle text-center" href="#">Gaber’s Corp</p>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className="servingCard text-center">
                                <img className="servingImage" src={kfclogohoriz} alt='kfclogohoriz'/>
                                <p className="commonDesc text-center">“VPM has taken away the stress and pressure that came with payroll processing. Now, we can easily focus on more important matters and business ventures. It is always wonderful working with them.”</p>
                                <p className="serviceCardTitle text-center" href="#">Mohammed M.<br></br>
                                    Navil Group Inc.<br></br>
                                    O/A KFC, 167-001</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default WhoWeAreServingBusiness;