import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class SubLinkTopBanner extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="payrollServicesTopBanner  p-0">
                    <div className='payrollServicesTopBannerOverly'>
                        <Container className="topContent text-center">
                            <Row>
                                <Col>
                                    <h1 className='topTitle'>
                                        ENSURE COMPLIANCE & ACCURACY WITH VPM PAYROLL SERVICES
                                    </h1>


                                    <h3 className='topSubTitle'>
                                        For small-to-medium businesses & <br></br> enterprises across Canada
                                    </h3>

                                   <Link to="/request-payroll-quote"><Button variant="primary" className='quotebutton mt-2'><span>GET A QUOTE</span></Button></Link> 

                                </Col>

                            </Row>
                        </Container>

                    </div>

                </Container>

            </Fragment>
        );
    }
}

export default SubLinkTopBanner;