import React, { Fragment } from 'react';
import TopHead from '../../components/TopHead/TopHead';
import TopNavigation from '../../components/TopNavigation/TopNavigation';
import TradePart from '../../components/TradePart/TradePart';
import QuoteComponentCyan from '../../components/QuoteComponentCyan/QuoteComponentCyan';
import Footer from '../../components/Footer/Footer';
import IndustryNewsBanner from '../../components/IndustryNewsBanner/IndustryNewsBanner';
import UnderstandingPayroll from '../../components/Blogs/UnderstandingPayroll';
import { Container } from 'react-bootstrap';


const Blog1Page = () => {
    return (
        <Fragment>
            <Container fluid="true" className='accContainer'>
                <TopHead />
                <TopNavigation title="Understanding Payroll Companies in Canada | V-TAC Payroll Management" />
                <IndustryNewsBanner />
                <UnderstandingPayroll />
                <QuoteComponentCyan />
                <TradePart />
                <Footer />
            </Container>

        </Fragment>
    );
};

export default Blog1Page;