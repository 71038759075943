import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import payrollcalogo from '../../asset/images/payroll-ca-logo.png';
import tbotlogo from '../../asset/images/tbot-logo.png';

class TradePart extends Component {
  render() {
    return (
      <Fragment>
        <Container className='SectionBetween p-0'>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="text-center" data-aos="fade-down" data-aos-duration="1000"
                data-aos-once="true">
                <Link to="/"><img className=" tradeImg" src={payrollcalogo} alt="CPA-logo" title='CPA' loading="lazy" /></Link>
                <a href='https://bot.com' target='_blank'><img className="tradeImg"  src={tbotlogo} alt='Toronto Region Board of Trade' title='Toronto Region Board of Trade' loading="lazy" /></a>
              </div>

            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default TradePart;