import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import checkoutatsmallstore from '../../asset/images/checkout-at-small-store.jpg';
import hrteam from '../../asset/images/hr-team.jpg';


class PayrollServiceThirdPart extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className='SectionBetween helpSmallBusinessContainer ' >
                    <div className='SectionBetween p-5'>
                        <Container >
                            <Row>
                                <Col lg={4} md={6} sm={12}>
                                    <img className="helpSmallBusinessImage"  src={checkoutatsmallstore} alt="checkout at small store" title='checkout at small store' />
                                </Col>
                                <Col lg={8} md={6} sm={12}>
                                    <div>
                                        <h2 className='commonTitle mt-4'>Helping <span className='spancolor'>small businesses</span> spend less time on payroll, and more time on growth</h2>
                                        <p className='commonDesc'>With a guaranteed error-free payroll process and no hidden costs, VPM ensures your employees are always paid on time and correctly the first time around.</p>
                                    </div>


                                </Col>
                            </Row>
                            <Row className='mt-5'>
                                <Col lg={8} md={6} sm={12}>
                                    <h2 className='commonTitle mt-4'>Providing integrated HR tools to <span className='spancolor'>enterprises</span> for better transparency & communication</h2>
                                    <p className='commonDesc'>Manage your Human Resources and Payroll from one solution. With a simplified human capital management (HCM) system like VPM, gain employee and financial insights like never before.</p>
                                </Col>
                                <Col lg={4} md={6} sm={12}>
                                    <img className="helpSmallBusinessImage float-right "  src={hrteam} alt="hr-team" title='hr-team' />
                                </Col>
                            </Row>
                        </Container>
                    </div>

                </Container>
            </Fragment>
        );
    }
}

export default PayrollServiceThirdPart;