import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
const GoogleReviewBanner = () => {
    return (
        <Fragment>
                <Container fluid={true} className="FAQBannerTopBanner  p-0">
                    <div className='ContactTopBannerOverly'>
                        <Container className="topContent text-center">
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <h1 className='contacttopTitle'>
                                        Client Review
                                    </h1>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </Fragment>
    );
};

export default GoogleReviewBanner;