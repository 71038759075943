import React, { Component, Fragment } from 'react';
import { Container } from 'react-bootstrap';
import Logo from '../../asset/images/vpmlogo.png';

export default class PageTop extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="topFixedPage p-0">
                    <div className="topPageOverlay">
                        <Container className="topPageContent">
                          
                              <div className='container' style={{width:"50%"}}> <img className="ReplylogoImage  " alt="Paris" src={Logo} /></div> 
                           
                        </Container>
                    </div>
                </Container>
            </Fragment>
        )
    }
}
