import React, { Component, Fragment } from 'react';
import MakingTheSwitch48Hours from '../components/MakingTheSwitch48Hours/MakingTheSwitch48Hours';
import MakingTheSwitchBanner from '../components/MakingTheSwitchBanner/MakingTheSwitchBanner';
import TopHead from '../components/TopHead/TopHead';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import QuoteComponentCyan from '../components/QuoteComponentCyan/QuoteComponentCyan';

class MakingTheSwitchPage extends Component {
    render() {
        return (
            <Fragment>
                <TopHead />
                <TopNavigation title="Making The Switch | V-TAC Payroll Management"/>
                <MakingTheSwitchBanner />
                <MakingTheSwitch48Hours />
                <QuoteComponentCyan />
                <TradePart />
                <Footer />
            </Fragment>
        );
    }
}

export default MakingTheSwitchPage;