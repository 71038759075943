import React, { Component, Fragment } from 'react';
import HospitalitySection1 from '../components/HospitalitySection1/HospitalitySection1';
import TopHead from '../components/TopHead/TopHead';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import HospitalityWhySwitchVpm from '../components/HospitalityWhySwitchVpm/HospitalityWhySwitchVpm';
import QuoteComponentGray2 from '../components/QuoteComponentGray2/QuoteComponentGray2';
import QuoteComponentGray from '../components/QuoteComponentGray/QuoteComponentGray';
import HospitalityIndustrySlide from '../components/HospitalityIndustrySlide/HospitalityIndustrySlide';
import HospitalityIndustryReduceRisk from '../components/HospitalityIndustryReduce Risk/HospitalityIndustryReduce Risk';
import AskQuesHospitalityIndustry from '../components/AskQuesHospitalityIndustry/AskQuesHospitalityIndustry';

class HospitalityIndustryPage extends Component {
    render() {
        return (
            <Fragment>
                <TopHead />
                <TopNavigation title="Hospitality Industry | V-TAC Payroll Management" />
                <HospitalitySection1 />
                <HospitalityIndustryReduceRisk />
                <HospitalityWhySwitchVpm />
                <HospitalityIndustrySlide />
                <QuoteComponentGray2 />
                <AskQuesHospitalityIndustry />
                <QuoteComponentGray />
                <TradePart />
                <Footer />
            </Fragment>
        );
    }
}

export default HospitalityIndustryPage;