import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class DentalOfficesSection1 extends Component {
      // scroll to top on page load
      componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="dentalTopBanner  p-0">
                    <div className='payrollServicesTopBannerOverly'>
                        <Container className="topContent text-center">
                            <Row>
                                <Col>
                                    <h1 className='topTitle'>
                                        A RELIABLE & EASY-TO-USE SOLUTION FOR CANADIAN DENTISTS.
                                    </h1>
                                    <div style={{ width: "60%" }} className=" container text-center">
                                        <h3 className='topSubTitle'>
                                            Achieve work-life balance by getting payroll done faster and stress-free.
                                        </h3>
                                    </div>
                                    <Link to="/request-payroll-quote"><Button variant="primary" className='quotebutton mt-2'><span>GET A QUOTE</span></Button></Link>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </Fragment>
        );
    }
}

export default DentalOfficesSection1;