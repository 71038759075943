import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class MountClientFeedback1 extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="nexasClientSayCard SectionBetween p-0">
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <div className='container' style={{ width: "50%" }}>
                                <p className='nexasCardDesc SectionBetween'>
                                “We were looking for something simpler, perhaps more of an email-based approach that would better suit our needs and preferences. Whenever we had issues or questions, ADP didn’t have a single point of contact that knew our account which took longer to resolve. Small businesses like ours don’t benefit from that kind of business arrangement.”
                                </p>
                                <p className='nexasCardName mt-5'>Dr. Sam Gupta, Owner</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default MountClientFeedback1;