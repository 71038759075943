import React, { Fragment } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import resigntation3 from "../../asset/images/resigntation-scaled.jpg";
import { Link } from 'react-router-dom';
const Blog2 = () => {
    return (
        <Fragment>
            <Container className='InsdustryNewSce'>
                <Row>
                    <Col lg={12} md={12} sm={12}>
                        <div>
                            <img className="insNewsImg" src={resigntation3} alt='Managing-Employee-Resignations' />
                            <h2 className='insNewsTitle'>Managing Employee Resignations: Best Practices</h2>
                            <p className='insNewsdetailsDesc'>
                                As a business owner, you must prepare for the inevitable situation of employees resigning. According to a July 2022 survey, 24% of Canadian workers have recently changed jobs, and retirement rates have increased by a third in the last decade. Unfortunately, this trend is unlikely to change.
                            </p>
                            <p className='insNewsdetailsDesc'>
                                Managing employee resignations can be particularly challenging, especially if the departing employee is one of your top performers. So, what are the best practices for handling this hurdle?
                            </p>
                            <p className='insNewssubTitle2' >Hold On:</p>
                            <p className='insNewsdetailsDesc'>
                                Receiving the news of an employee's resignation can be a shock, and it's normal to experience feelings of frustration, betrayal, or disappointment. However, it's important to exercise discretion and avoid making any comments that you may regret later or make the employee feel uncomfortable. Additionally, if the employee is a high performer, you may want to maintain a positive relationship with them.

                            </p>

                            <p className='insNewsdetailsDesc'>
                                Instead, take a moment to collect your thoughts and emotions. While it's okay to express surprise or sadness, it's best to keep your reaction professional and avoid making it personal. Remember that there could be various reasons for their departure that are beyond your control, which we'll discuss further.
                            </p>
                            <p className='insNewssubTitle2' >Understand Your Resignation Protocol:</p>
                            <p className='insNewsdetailsDesc'>
                                Having a plan in place for offboarding employees is crucial when they decide to leave your organization. While some aspects of offboarding may be straightforward, such as changing passwords or revoking access, others may be more complex. For instance, it's important to note that outstanding pay must be provided to the employee within six days of their departure, as per employment standards laws. Additionally, you may be required to provide vacation pay, CPP contributions, employment insurance contributions, overtime, and stat holiday pay.

                            </p>
                            <p className='insNewsdetailsDesc'>
                                To ensure compliance and save time, consider partnering with a payroll service provider like <Link to='/' className='quoteCondition'>VPM</Link>. They can automate these calculations and help streamline the offboarding process.
                            </p>

                            <p className='insNewssubTitle2'>
                                Caution while doing Counteroffers:
                            </p>
                            <p className='insNewsdetailsDesc'>
                                Offering a counteroffer, particularly during an emotional moment, may not always be the best course of action. Even if your employee accepts the counteroffer, their desire to leave may persist, and they may end up leaving anyway for another opportunity. As a business owner, it's important to build a team of committed individuals who share your values and goals. While it may be tempting to retain a valuable employee, it's often better to let them move on and maintain a positive relationship with them for the future.
                            </p>

                            <p className='insNewssubTitle2'>
                                Gain Insight into the Employee's Reasons for Leaving:
                            </p>
                            <p className='insNewsdetailsDesc'>
                                Exit interviews can be uncomfortable, but they provide valuable insights for business owners. By conducting an objective interview, you can gather honest feedback that may help you manage your team more effectively. While you may not always like what you hear, the information you gain can be used to make improvements in your organization.
                            </p>
                            <p className='insNewsdetailsDesc'>
                                It's essential to understand that the employee's decision to leave may have nothing to do with your business. For instance, they may be relocating to be closer to their family or have to care for a loved one. In such cases, it's crucial to offer your support and even keep the door open for future opportunities with your organization.
                            </p>

                            <p className='insNewssubTitle2'>
                                Talk to your team:
                            </p>
                            <p className='insNewsdetailsDesc'>
                                It's important to avoid unexpected departures of colleagues that may cause concern among your team. Therefore, it's recommended to inform them as soon as possible. You don't have to disclose the reasons behind the departure, as that's confidential information, but you should clarify that the employee is no longer with the company and how it will impact the team. For example, who will take on their responsibilities? How will you manage the workload in the short and long term? It's important to stay positive and be a guiding force for your team during this time.
                            </p>
                            <p className='insNewssubTitle2'>
                                Develop a Thorough Transition Plan:
                            </p>
                            <p className='insNewsdetailsDesc'>
                                To ensure a smooth transition when an employee leaves, it's important to create a comprehensive plan. If possible, involve the departing employee in the planning process, as they have valuable knowledge and insight into their role.
                            </p>
                            <p className='insNewsdetailsDesc'>
                                Begin by identifying the duties and responsibilities of the departing employee and assigning them to other team members. However, be mindful of the workload of these employees to avoid overburdening them. Maintaining team morale is crucial during this time.
                            </p>
                            <p className='insNewsdetailsDesc'>
                                It may also be helpful to establish short and long-term goals and priorities for the team to ensure a seamless transition and continued success in the absence of the departing employee.
                            </p>
                            <p className='insNewssubTitle2'>
                                Initiate the Hiring Process:
                            </p>
                            <p className='insNewsdetailsDesc'>
                                To alleviate the increased workload on your team, it's crucial to begin the hiring process as soon as possible. However, for small businesses, this can be a daunting task, requiring significant time and resources to sift through resumes and conduct interviews.
                            </p>
                            <p className='insNewsdetailsDesc'>
                                To streamline the process and save time, consider partnering with a flexible HR provider who can assist with recruitment and onboarding. Alternatively, hiring a freelancer can also be a useful short-term solution to ease the burden on your team while you search for a long-term replacement.
                            </p>
                            <p className='insNewsdetailsDesc'>
                                By initiating the hiring process promptly, you can demonstrate to your team that their increased workload is only temporary, and you're committed to maintaining productivity and success in your business.
                            </p>

                            <Link to="/industry-news"><Button onClick={window.scrollTo({ top: 0, behavior: 'smooth' })} variant="primary" className='view-All-Post mt-2'><span>VIEW ALL POST</span></Button>{' '}</Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default Blog2;