import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import asif4 from '../../asset/images/asif-4.jpg';

class InsAgentSec2 extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className='SectionBetween nexasStaffingContainer ' >
                    <div>
                        <Container>

                            <Row>
                                <Col lg={6} md={6} sm={12}>

                                    <h3 className='commonTitle mt-5'>Customized solutions & same-day assistance with <span className='spancolor'>VPM</span></h3>
                                    <p className='desc'>“VPM has been responsive and effective at working with a small business like ours. The cutoffs are more flexible and a custom fit to accommodate our needs. They can deliver things quickly, even same day.</p>
                                    <p className='desc'>Once while I was on vacation, we sent incorrect payroll information for commissions and adjustments that we had to basically correct on the fly. It was our mistake, not theirs, and yet in an incredibly short period of time they made every effort to make sure we got our corrected payroll out,” said Asif.</p>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="nexus2Img mt-5 " src={asif4} alt='Asif Khan Insurance' title='communicationg over phone' />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </Fragment>
        );
    }
}

export default InsAgentSec2;