import React, { Component, Fragment } from 'react';
import AskQuesUnifiedHR from '../components/AskQuesUnifiedHR/AskQuesUnifiedHR';
import TopHead from '../components/TopHead/TopHead';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import UnifiedHRSection1 from '../components/UnifiedHRSection1/UnifiedHRSection1';
import UnifiedHRSection2 from '../components/UnifiedHRSection2/UnifiedHRSection2';
import UnifiedHRSection3 from '../components/UnifiedHRSection3/UnifiedHRSection3';
import UnifiedHRSection4 from '../components/UnifiedHRSection4/UnifiedHRSection4';
import UnifiedHRSection5 from '../components/UnifiedHRSection5/UnifiedHRSection5';
import UnifiedHRSection6 from '../components/UnifiedHRSection6/UnifiedHRSection6';

class UnifiedHRAndBenefitsPage extends Component {
    render() {
        return (
           <Fragment>
               <TopHead />
                <TopNavigation title="Unified HR & Benefits | V-TAC Payroll Management"/>
                <UnifiedHRSection1 />
                <UnifiedHRSection2 />
                <UnifiedHRSection3 />
                <UnifiedHRSection4 />
                <UnifiedHRSection5 />
                <AskQuesUnifiedHR />
                <UnifiedHRSection6 />
                <TradePart />
                <Footer />
               
           </Fragment>
        );
    }
}

export default UnifiedHRAndBenefitsPage;