import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import dentist1 from '../../asset/images/dentist-1.jpg';
import dentist2 from '../../asset/images/dentist-2.jpg';
import dentist3 from '../../asset/images/dentists-3.jpg';
import dentist4 from '../../asset/images/dentists-4.jpg';



class DentalOfficesSection2 extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className='SectionBetween helpSmallBusinessContainer ' >
                    <div className='SectionBetween p-5'>
                        <Container>
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="errorOmissionsImg" src={dentist1} alt="Dentist with patient" title='Dentist with patient' />
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <h2 className='commonTitle'>Spend less time on payroll and <span className='spancolor'> more time with patients</span></h2>
                                    <p className='commonDesc'>You’re busy, we get it. Do what you do best without worrying about paperwork and payroll by switching to VPM.</p>
                                    <p className='commonDesc'>Manage your workforce, payroll and HR accurately from one complete system that’s customized to your dental practice’s needs.</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12}>

                                    <h2 className='commonTitle mt-5'>Spend more time <span className='spancolor'>outside</span> the office</h2>
                                    <p className='commonDesc'>As a business owner, you wear multiple hats. No more spending time in the evenings and weekends tying up loose ends and figuring out financials. Improve your work-life balance by letting VPM take complete care of the payroll & HR function.</p>
                                    <p className='commonDesc'>Simple to use and reliable, VPM helps to free up your time.</p>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="errorOmissionsImg mt-5 " src={dentist2} alt="A young dentist and her daughter" title='A young dentist and her daughter' />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="errorOmissionsImg mt-5 " src={dentist3} alt="Team of dentists" title='Team of dentists'/>
                                </Col>
                                <Col lg={6} md={6} sm={12}>

                                    <h2 className='commonTitle mt-5'><span className='spancolor'>Benefit</span> from using VPM’s payroll management service</h2>
                                    <p className='commonDesc'>Running a dental practice is not easy and properly managing your staff is crucial to your success. Although good communication and a great working environment go a long way, consistency of their paychecks, the correct amounts and being paid on time is very important to your staff.</p>
                                    <p className='commonDesc'>Simplify the process by working with VPM to create a payroll management system that works for you and your staff.</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <h2 className='commonTitle mt-5'>Building a payroll system <span className='spancolor'>made for</span> your dental practice</h2>
                                    <p className='commonDesc'>When you work with VPM for payroll management services, we set up your payroll structure according to the way you pay your staff, no matter the size of your dental practice. Our payroll systems are fully customizable and adhere to Canadian Revenue Agency Provincial Legislation.</p>
                                    <p className='commonDesc'>​Learn more about our payroll services for dental practices across Canada.</p>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="errorOmissionsImg mt-5 " src={dentist4} alt="Team of dentists" title='Team of dentists' />
                                  
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} md={12} sm={12}> <Link to="/business-payroll-services"><Button variant="primary" className='button mt-5'><span>LEARN MORE</span></Button>{' '}</Link></Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </Fragment>
        );
    }
}

export default DentalOfficesSection2;