import React, { Component, Fragment } from 'react';
import TopHead from '../components/TopHead/TopHead';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import IndustryNewsBanner from '../components/IndustryNewsBanner/IndustryNewsBanner';
import AllIndustryNews from '../components/AllIndustryNews/AllIndustryNews';

class AllIndustryNewsPge extends Component {
    render() {
        return (
            <Fragment>
                <TopHead />
                <TopNavigation title="All Industry News | V-TAC Payroll Management" />
                <IndustryNewsBanner />
                <AllIndustryNews />
                <TradePart />
                <Footer />
            </Fragment>
        );
    }
}

export default AllIndustryNewsPge;