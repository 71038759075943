import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import law1 from '../../asset/images/law-1.jpg';
import law2 from '../../asset/images/law-2.jpg';


class LawFirmPayroll extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className='SectionBetween  ' >
                    <div className='SectionBetween p-5'>
                        <Container >
                            <Row>
                                <Col lg={6} md={6} sm={12} style={{ width: "20%" }}>
                                    <img className="ReducetheriskHospitalityimg2" alt='Lawyers in boardroom'title='Lawyers in boardroom' src={law1} />
                                </Col>
                                <Col lg={6} md={6} sm={12} style={{ width: "80%" }}>

                                    <div ><h3 class="commonTitle float-left mt-3">You take care of business, we take care of<strong className='spancolor'> payroll</strong></h3>
                                        <p className='commonDesc'>Streamline and simplify all payroll and HR-related processes with an easy-to-use human capital management solution in a single cloud-based platform.</p>
                                        <p className='commonDesc'>Reduce costs and improve the accuracy of your payroll.</p></div>


                                </Col>
                            </Row>
                            <Row className='SectionBetween'>
                                <Col lg={6} md={6} sm={12} >
                                <h3 className='commonTitle'>Keep your data<strong className='spancolor'> confidential</strong>  and <strong className='spancolor'>secure</strong></h3>
                                    <p className='commonDesc'>Get peace of mind knowing your sensitive information is secure. Communication is kept strictly between VPM and your authorized payroll representative.</p>
                                    <p className='commonDesc'>All reports are safely and securely transmitted over our cloud-based network. Files are password encrypted. A data recovery process is in place in case of an emergency.</p>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="ReducetheriskHospitalityimg2 float-right" alt='A lawyer examining a document' title='A lawyer examining a document' src={law2} />
                                </Col>
                              
                            </Row>

                        </Container>
                    </div>

                </Container >
            </Fragment >
        );
    }
}

export default LawFirmPayroll;