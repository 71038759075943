import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import nexus1 from '../../asset/images/nexus-1.jpg';
import nexus2 from '../../asset/images/nexus-2.jpg';


class NexasStaffingSec1 extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className='SectionBetween nexasStaffingContainer ' >
                    <div className='SectionBetween p-5'>
                        <Container>
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="nexus1Img" src={nexus1} alt='Nexus Staffing' title='Nexus Staffing' />
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <h3 className='commonTitle'>A bit about <span className='spancolor'>Nexus Staffing</span></h3>
                                    <p className='commonDesc'>Established in 2002, <span><a className='faqLink' href='https://www.nexusstaffing.com/' target='_blank'>Nexus Staffing</a></span> is a full-service employment agency focusing specifically on sourcing, recruiting, screening and placing top candidates in the Manufacturing and Logistics Industries across Canada. </p>
                                    <p className='commonDesc'>Nexus recruits their top candidates to fulfill many temporary part-time, contractual and full-time positions. They develop a custom recruitment and service plan for their clients.</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12}>

                                    <h3 className='commonTitle mt-5'><span className='spancolor'>In-house payroll</span> took the focus away from growth</h3>
                                    <p className='commonDesc'>In its first year of business, Nexus was already growing quickly.</p>
                                    <p className='commonDesc'>Payroll was performed in-house, and like most administrative functions, it was competing for time that took away from its core mission.</p>
                                    <p className='commonDesc'>Senior Management realized it would be better off by outsourcing payroll.</p>
                                    <p className='commonDesc'>Given the importance of payroll to its business, Nexus had begun its search for a reliable payroll service provider that would help to ensure that quality candidates were paid accurately and on time.</p>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="nexus4Img mt-5 " alt='Employment agency' title='Employment agency' src={nexus2} />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </Fragment>
        );
    }
}

export default NexasStaffingSec1;