import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import construction1 from '../../asset/images/construction-1.jpg';
import construction2 from '../../asset/images/construction-2.jpg';

class ConstructionPayroll extends Component {
    render() {
        return (
            <Fragment>
                <Container className='SectionBetween' >
                    <Row>
                        <Col lg={4} md={6} sm={12}>
                            <img className="constructionimg1" alt='Construction workers fist bumping' title='Construction workers fist bumping' src={construction1} />
                        </Col>
                        <Col lg={8} md={6} sm={12}>
                            <h3 class="commonTitle">Pay construction professionals<strong className='spancolor'> correctly & on time</strong></h3>
                            <p className='commonDesc'>You work with a crew, Sub/Contractors, Consultants, Engineers, Architects, building suppliers – a whole team comprised of different payment structures.</p>
                            <p className='commonDesc'>Let VPM handle your payroll needs – we guarantee error-free payments so that you can focus on the task at hand.</p>
                            <p className='commonDesc'>At any point in time, access your payroll system from any location and obtain immediate company updates.</p>
                        </Col>
                    </Row>
                    <Row className='SectionBetween'>
                        <Col lg={8} md={6} sm={12} className="float-left">
                            <h3 className='commonTitle'><strong className='spancolor'>Reduce administration time</strong>  by having employees manage their own information</h3>
                            <p className='commonDesc'>Employees can update their own information online, as well as, view their stubs & T4s so you can focus on managing the project as opposed to administrative tasks.</p>
                            <p className='commonDesc'>Be worry-free with VPM, we ensure compliance is met across the board from various WSIB rates to HST amounts.</p>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <img className="constructionimg1 float-right" alt='Contractors at a harbour' title='Contractors at a harbour' src={construction2} />
                        </Col>

                    </Row>
                </Container >
            </Fragment >
        );
    }
}

export default ConstructionPayroll;