import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import Vpm1 from '../../asset/images/VPM1.jpg';
import Vpm2 from '../../asset/images/VPM2.jpg';

import AOS from 'aos';
import 'aos/dist/aos.css';

export default class QuoteSection extends Component {
  constructor() {
    super();
    this.state = {
      fullName: "",
      company: "",
      phone: "",
      email: "",
      numberOfEmployees: "",
      postalCode: "",
      message: "",
      hearFrom: "",
      errors: {},
      isVarified: false,
      recapvalue: "",

    };
  }


  //racapcha function
  recapchaValue = (value) => {
    const recapvalue = value;
    console.log("Captcha value:", recapvalue);
    this.setState({ recapvalue: value });
    this.setState({ isVarified: true });
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }
  clearForm = () => {
    this.setState({
      fullName: "",
      company: "",
      phone: "",
      email: "",
      numberOfEmployees: "",
      payperperiod: "",
      province: "",
      postalCode: "",
      hearFrom: "",
    })
  }
  componentDidMount() {
    AOS.init();
    window.scrollTo(0, 0);
  }
  submitHandaler = (event) => {
    event.preventDefault();

    const isValid = this.Validate();
    if (isValid == true) {

      axios.post("https://api.vtacpayroll.ca/create", this.state).then(Response => {
        this.clearForm();

        window.location.href = "https://www.vtacpayroll.ca/#/request-payroll-success";

      }).catch(error => {
        console.log(error);
      })

    }
  }

  Validate = () => {

    const errors = {};
    //let regexp = /(?!.*[DFIOQU-])[A-VXY][0-9][A-Z][' '] ?[0-9][A-Z][0-9]/;
    let isValid = true;
    const { fullName, company, phone, email, numberOfEmployees, payperperiod, province, postalCode, hearFrom } = this.state;
    if (fullName == "") {
      errors.fullNameEmpty = "Please provide your Name.";
      isValid = false;
    }
    if (company == "") {
      errors.companyEmpty = "Please provide your company Name.";
      isValid = false;
    }
    if (phone == "") {
      errors.phoneEmpty = "Please provide your Phone Number.";
      isValid = false;
    }
    if (email == "") {
      errors.emailEmpty = "Please provide your Email Address.";
      isValid = false;
    }
    if (numberOfEmployees == "") {
      errors.numberOfEmployeesEmpty = "Please provide your no.of.Employee.";
      isValid = false;
    }
    if (payperperiod == undefined || payperperiod == "") {
      errors.payperperiodEmpty = "Please provide your Pay Period Frequency.";
      isValid = false;
    }
    if (province == undefined || province == "") {
      errors.provinceEmpty = "Please provide your Province.";
      isValid = false;
    }
    if (postalCode == "") {
      errors.postalCodeEmpty = "Please provide your Postal Code.";
      isValid = false;
    }
    // if (regexp.test(postalCode.toUpperCase()) != true) {
    //   errors.postalCodevarify = "Please provide your valid Postal Code.";
    //   isValid = false;
    // }
    if (hearFrom == "" || hearFrom == undefined) {
      errors.hearFromEmpty = "Please provide How'd you hear about us ?.";
      isValid = false;
    }
    this.setState({ errors });
    return isValid;
  }
  render() {
    const { fullName, company, phone, email, numberOfEmployees, payperperiod, province, postalCode, hearFrom, errors } = this.state;

    return (

      <Fragment>
        <Container fluid={true} className='quoteTopPage SectionBetween text-center'>
          <div>
            <Container>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <h2 className='qoutetopTitle'>GET A FREE QUOTE WITHIN 24 HOURS!</h2>
                  <h4 className='quoteTopSubTitle'>Fill out the form below to receive a custom quote from our experts in the nick of time! Switch to VPM and get<span className='spancolor'> FREE month of payroll & a tablet*!</span></h4>
                  <Link to="/promotional-conditions"><p className='quoteCondition'>*some conditions apply</p></Link>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>

        <Container className='SectionBetween'>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <div className=" quoteCard" data-aos="fade-right"
                data-aos-anchor="#example-anchor"
                data-aos-offset="500"
                data-aos-duration="1000">
                <div id="RQForm">
                  <h3 className="quoteCardTitle ">Request a Quote </h3>

                  <Form onSubmit={this.submitHandaler}>

                    <span style={{ color: 'red' }}>{errors.fullNameEmpty}</span>
                    <Form.Group >
                      <Form.Control type="text" title='Full Name' placeholder="Full Name*" name='fullName' value={fullName} onChange={this.handleChange} />
                    </Form.Group>

                    <span style={{ color: 'red' }}>{errors.companyEmpty}</span>
                    <Form.Group >
                      <Form.Control type="text" title='Company' placeholder="Company*" name='company' value={company} onChange={this.handleChange} />
                    </Form.Group>

                    <span style={{ color: 'red' }}>{errors.phoneEmpty}</span>
                    <Form.Group >
                      <Form.Control type="number" title='Phone' placeholder="Phone*" name='phone' value={phone} onChange={this.handleChange} />
                    </Form.Group>

                    <span style={{ color: 'red' }}>{errors.emailEmpty}</span>
                    <Form.Group >
                      <Form.Control type="email" title='Email Address' placeholder="Email*" name='email' value={email} onChange={this.handleChange} />
                    </Form.Group>

                    <span style={{ color: 'red' }}>{errors.numberOfEmployeesEmpty}</span>
                    <Form.Group >
                      <Form.Control type="number" min={0} title='Number of Employees' placeholder="Number of Employees*" name='numberOfEmployees' value={numberOfEmployees} onChange={this.handleChange} />
                    </Form.Group>

                    <span style={{ color: 'red' }}>{errors.payperperiodEmpty}</span>
                    <Form.Group >
                      <select className='quoteDropdown' id='frequency' name='payperperiod' value={payperperiod} onChange={this.handleChange} >
                        <option value={""}>Pay Period Frequency*</option>
                        <option value={'WEEKLY'}>WEEKLY</option>
                        <option value={'SEMI-MONTHLY'}>SEMI-MONTHLY</option>
                        <option value={'BI-WEEKLY'}>BI-WEEKLY</option>
                        <option value={'MONTHLY'}>MONTHLY</option>
                      </select>
                    </Form.Group>

                    <span style={{ color: 'red' }}>{errors.provinceEmpty}</span>
                    <Form.Group >
                      <select className='quoteDropdown' id='province' name='province' value={province} onChange={this.handleChange} >
                        <option value={""}>Province*</option>
                        <option value={'Alberta'}>Alberta</option>
                        <option value={'British Columbia'}>British Columbia</option>
                        <option value={'Manitoba'}>Manitoba</option>
                        <option value={'New Brunswick'}>New Brunswick</option>
                        <option value={'Newfoundland and Labrador'}>Newfoundland and Labrador</option>
                        <option value={'Nova Scotia'}>Nova Scotia</option>
                        <option value={'Northwest Territories'}>Northwest Territories</option>
                        <option value={'Nunavut'}>Nunavut</option>
                        <option value={'Ontario'}>Ontario</option>
                        <option value={'Prince Edward Island'}>Prince Edward Island</option>
                        <option value={'Quebec'}>Quebec</option>
                        <option value={'Saskatchewan'}>Saskatchewan</option>
                        <option value={'Yukon'}>Yukon</option>
                      </select>
                    </Form.Group>

                    <span style={{ color: 'red' }}>{errors.postalCodeEmpty}</span>
                    <Form.Group >
                      <Form.Control type="text" title='Postal Code' placeholder="Postal Code*" name='postalCode' value={postalCode} onChange={this.handleChange} />
                    </Form.Group>
                    <span style={{ color: 'red' }}>{errors.hearFromEmpty}</span>
                    <Form.Group >
                      <select className='quoteDropdown' name='hearFrom' value={hearFrom} onChange={this.handleChange} >
                        <option value={""}>How'd you hear about us ?*</option>
                        <option value={"Google Search"}>Google Search</option>
                        <option value={"Social Media"}>Social Media</option>
                        <option value={"Magazine Ad"}>Magazine Ad</option>
                        <option value={"Word Of Mouth"}>Word Of Mouth</option>
                        <option value={"Promotional Calender"}>Promotional Calender</option>
                        <option value={"Others"}>Others</option>
                      </select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check type="checkbox" defaultChecked={true} className='checkboxForm' label="Yes, I want to receive VPM info, industry news and promotions from V-TAC Payroll Management by email. You can change your preferences or unsubscribe from our contact list at any time." />
                    </Form.Group>
                    <div className='m-2'>
                      <ReCAPTCHA
                        // vtacpayroll.ca
                        sitekey="6LdW8FcjAAAAABvBHj1pzxWTOu3vqp4Hd5UIr9jn"
                        // vtacpayrol.com
                        // sitekey="6LewqFUjAAAAADXJfTyYT40ysEYNHl6eX3fZbf9L"
                        onChange={this.recapchaValue}
                      />
                    </div>
                    <Button name='submitBtn' className="RequestQuoteBtn" variant="primary" type="submit" disabled={!this.state.isVarified}>
                      <span>GET QUOTE</span>
                    </Button>
                  </Form>
                </div>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <div data-aos="fade-left"
                data-aos-anchor="#example-anchor"
                data-aos-offset="500"
                data-aos-duration="1000"> <img className="quotationRequestImg" src={Vpm1} /></div>
              <div data-aos="fade-left"
                data-aos-anchor="#example-anchor"
                data-aos-offset="500"
                data-aos-duration="1000"><img className="quotationRequestImg2" src={Vpm2} /></div>
            </Col>
          </Row>
        </Container>
      </Fragment>

    )
  }
}
