import React, { Component, Fragment } from 'react';
import LawFirmPayroll from '../components/LawFirmPayroll/LawFirmPayroll';
import LawFirmsTopBanner from '../components/LawFirmsTopBanner/LawFirmsTopBanner';
import TopHead from '../components/TopHead/TopHead';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import LawWhySwitchVpm from '../components/LawWhySwitchVpm/LawWhySwitchVpm';
import QuoteComponentGray2 from '../components/QuoteComponentGray2/QuoteComponentGray2';
import QuoteComponentGray from '../components/QuoteComponentGray/QuoteComponentGray';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import LawFirmSlide from '../components/LawFirmSlide/LawFirmSlide';
import AskQuesLawFirm from '../components/AskQuesLawFirm/AskQuesLawFirm';

class LawFirmsPage extends Component {
    render() {
        return (
            <Fragment>
                <TopHead />
                <TopNavigation title="Law Firms | V-TAC Payroll Management" />
                <LawFirmsTopBanner />
                <LawFirmPayroll />
                <LawWhySwitchVpm />
                <LawFirmSlide />
                <QuoteComponentGray2 />
                <AskQuesLawFirm />
                <QuoteComponentGray />
                <TradePart />
                <Footer />
            </Fragment>
        );
    }
}

export default LawFirmsPage;