import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import drschindler1 from '../../asset/images/dr-schindler-1-1.jpg';
import drschindler2 from '../../asset/images/dr-schindler-2.jpg';
import drschindler3 from '../../asset/images/dr-schindler-3.jpg';
import drschindler4 from '../../asset/images/dr-schindler-4.jpg';


class SchindlerSec1 extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className='SectionBetween nexasStaffingContainer ' >
                    <div className='SectionBetween p-5'>
                        <Container>
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="nexus3Img" alt='Dr. Schindler & Associates' title='Dr. Schindler & Associates' src={drschindler1} />
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <h3 className='commonTitle'>A bit about <span className='spancolor'>Dr. Schindler & Associates</span></h3>
                                    <p className='desc'><span><a className='faqLink' href='https://www.drschindler.ca/' target='_blank'>Dr. Schindler & Associates</a></span> has been proudly serving clients in Toronto’s Bloor West Village area, including High Park and surrounding areas, for over 20 years. </p>
                                    <p className='desc'>Offering general dental services including restorative dentistry, and working together with a roster of very experienced specialists in all aspects of dentistry where necessary, Dr. Schindler and his friendly staff do everything possible to ensure the best interests of each patient are always met.</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12}>

                                    <h3 className='commonTitle mt-5'>Targeting a <span className='spancolor'>specific market</span></h3>
                                    <p className='desc'>Dr. Schindler grew his practice almost entirely through referrals, and being also German-speaking further enabled him to tap into Toronto’s community of German-speaking Canadians and expats.</p>
                                    <p className='desc'>Today, Dr. Schindler’s practice has patients ranging in age from 3 to 103, has a great reputation including working with young children, and is now beginning to utilize more digital technology and social media to reach new patients.</p>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="nexus1Img mt-5" alt='Dr. Schindler & Associates' title='Dr. Schindler & Associates' src={drschindler2} />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="nexus2Img mt-5" alt='Dr. Schindler & Associates' title='Dr. Schindler & Associates' src={drschindler3} />
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <h3 className='commonTitle mt-5'><span className='spancolor'>Spending hours</span> on payroll</h3>
                                    <p className='desc'>Business at Dr. Schindler & Associates was similar to many other successful Canadian dental practices—you are never done before 7 pm, and you do your paperwork and payroll at night or on the weekend when you should be home with your family and loved ones.</p>
                                    <p className='desc'>Dr. Schindler did all administrative work including payroll entirely himself for many years before moving payroll to a large service provider that operates across North America. It delivered advantages over in-house payroll, but for Dr. Schindler, the results left lots of room for improvement.</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12}>

                                    <h3 className='dentalOfficeTitle mt-5'><span className='spancolor'>Experiencing problems</span> with other payroll service providers</h3>
                                    <p className='desc'>“For a dental office of our size, 8-10 staff, such a provider turned out to be a very large and cumbersome company to work with. It might work great for some businesses, but it did not work well for me.</p>
                                    <p className='desc'>I don’t want to have to log into a website that throws me out on a regular basis or requires me to go through several barriers just to get to my own payroll numbers.</p>
                                    <p className='desc'>Also, most of our staff could not easily log into the website and access their own payroll data, and I didn’t have the time to be their middleman.”</p>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="nexus2Img mt-5" alt='Dr. Schindler & Associates' title='Dr. Schindler & Associates' src={drschindler4} />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </Fragment>
        );
    }
}

export default SchindlerSec1;