import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
class IndustryNewsBanner extends Component {
    render() {
        return (
            <Fragment>
            <Container fluid={true} className="IndustryNewsBanner  p-0">
                <div className='ContactTopBannerOverly'>
                    <Container className="topContent text-center">
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <h1 className='contacttopTitle'>
                                INDUSTRY NEWS
                                </h1>
                                <h3 className='topSubTitle'>
                                Stay tuned for the latest payroll news!
                                </h3>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </Container>
        </Fragment>
        );
    }
}

export default IndustryNewsBanner;