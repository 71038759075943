import React, { Component, Fragment } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import euro from '../../asset/images/euro.png';
import flyinginhigh from '../../asset/images/flying-in-high.png';
import atr from '../../asset/images/atr.png';
import k2 from '../../asset/images/k2.png';
import altamira from '../../asset/images/altamira.png';
import royalpioneer1 from '../../asset/images/royal-pioneer-1.png';
import trisan from '../../asset/images/trisan.png';
import spaceage from '../../asset/images/space-age.png';
import vbj from '../../asset/images/vbj.png';
import tws from '../../asset/images/tws.png';
import hidri from '../../asset/images/hidri.png';
import irs from '../../asset/images/irs.png';
import solutions from '../../asset/images/solutions.png';
import rwc from '../../asset/images/rwc.png';
import sabb from '../../asset/images/sabb.png';
import qrp from '../../asset/images/qrp.png';


class ConstructionOrConstructorSlide extends Component {
    render() {
        var settings = {
            dots: false,
            arrows: true,
            infinite: true,
            speed: 3000,
            slidesToShow: 6,
            slidesToScroll: 3,
            autoplay: true,
            autoplaySpeed: 2000,


            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <Fragment>

            <Container className="slideShowSection SectionBetween">
                <h2 className='mainTitle text-center mb-5'><strong className='spancolor'>Trusted</strong> BY COMPANIES LIKE YOURS</h2>
                <Slider {...settings}>

                    <div>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <a> <img className="clientImage" src={tws} /></a>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <a> <img className="clientImage" src={vbj} /></a>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <a> <img className="clientImage" src={trisan} /></a>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <a> <img className="clientImage" src={spaceage} /></a>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <a> <img className="clientImage" src={royalpioneer1} /></a>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <a> <img className="clientImage" src={altamira} /></a>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <a> <img className="clientImage" src={atr} /></a>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <a> <img className="clientImage" src={euro} /></a>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <a> <img className="clientImage" src={flyinginhigh} /></a>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <a> <img className="clientImage" src={k2} /></a>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <a> <img className="clientImage" src={hidri} /></a>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <a> <img className="clientImage" src={irs} /></a>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <a> <img className="clientImage" src={solutions} /></a>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <a> <img className="clientImage" src={rwc} /></a>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <a> <img className="clientImage" src={sabb} /></a>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <a> <img className="clientImage" src={qrp} /></a>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <a> <img className="clientImage" src={rwc} /></a>
                            </Col>
                        </Row>
                    </div>
                   
                </Slider>


            </Container>
        </Fragment>
        );
    }
}

export default ConstructionOrConstructorSlide;