import React, { Component, Fragment } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Container, Row, Col, Button } from 'react-bootstrap';
import sliderestaurantowner from '../../asset/images/slide-restaurant-owner.jpg';
import slidedentist from '../../asset/images/slide-dentist.jpg';
import slidewarehousemanager from '../../asset/images/slide-warehouse-manager.jpg';
import slidelawyer from '../../asset/images/slide-lawyer.jpg';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export default class TopBanner extends Component {
    render() {
        var settings = {
            arrows: false,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            fade: true,
            fadeSpeed: 1000,

            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <Fragment>

                <Container fluid={true} className="SectionBetweenTopBaner">
                    <Row>
                        <Col lg={8} md={12} sm={12} className="topBannerleftSide">
                            <div className='topBannerHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='topBanerTitle'>
                                    Canada’s value leader in <span className='spancolor'> payroll services</span>
                                </h2>
                            </div>
                            <div>
                                <Link to="/payroll-calculator">
                                    <Button className="cal-button button-2 mt-4 "><span><strong>Online Payroll Calculator</strong></span></Button>{' '}
                                </Link>
                            </div>
                        </Col>
                        <Col lg={4} md={0} sm={0} className="topBannerRightSide">
                            <Slider {...settings}>
                                <div>
                                    <Row>
                                        <Col>
                                            <img className="topSlideImg" style={{ width: "100%", height: "40rem" }} src={slidelawyer} alt="lawyer" loading="lazy" />
                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                    <Row >
                                        <Col className="topSlideDiv">
                                            <img className="topSlideImg" style={{ width: "100%", height: "40rem" }} src={slidewarehousemanager} alt="warehouse-manager" loading="lazy" />
                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                    <Row >
                                        <Col className="topSlideDiv">
                                            <img className="topSlideImg" style={{ width: "100%", height: "40rem" }} src={slidedentist} alt="dentist" loading="lazy" />

                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                    <Row >
                                        <Col className="topSlideDiv">
                                            <img className="topSlideImg" style={{ width: "100%", height: "40rem" }} src={sliderestaurantowner} alt="restaurant-owner" loading="lazy" />

                                        </Col>
                                    </Row>
                                </div>
                            </Slider>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}