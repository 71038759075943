import React, { Component, Fragment } from 'react';
import WhoWeAreBanner from '../components/WhoWeAreBanner/WhoWeAreBanner';
import TopHead from '../components/TopHead/TopHead';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import WhoWeAreMap from '../components/WhoWeAreMap/WhoWeAreMap';
import WhoWeAreMiddleBanner from '../components/WhoWeAreMiddleBanner/WhoWeAreMiddleBanner';
import WhoWeAreProfessionals from '../components/WhoWeAreProfessionals/WhoWeAreProfessionals';
import QuoteComponentCyan from '../components/QuoteComponentCyan/QuoteComponentCyan';
import QuoteComponentGray from '../components/QuoteComponentGray/QuoteComponentGray';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import WhoWeAreSlide from '../components/WhoWeAreSlide/WhoWeAreSlide';
import WhoWeAreServingBusiness from '../components/WhoWeAreServingBusiness/WhoWeAreServingBusiness';

class WhoWeAREPage extends Component {
    render() {
        return (
            <Fragment>
                <TopHead />
                <TopNavigation title="Who We Are | V-TAC Payroll Management"/>
                <WhoWeAreBanner />
                <WhoWeAreMap />
                <WhoWeAreMiddleBanner />
                <WhoWeAreProfessionals />
                <QuoteComponentGray />
                <WhoWeAreServingBusiness />
                <WhoWeAreSlide />
                <QuoteComponentCyan />
                <TradePart />
                <Footer />
            </Fragment>
        );
    }
}

export default WhoWeAREPage;