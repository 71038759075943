import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class NexasClientFeedback1 extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="nexasClientSayCard SectionBetween  p-0">
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <div className='container' style={{ width: "50%" }}>
                                <p className='nexasCardDesc SectionBetween'>
                                “As we grew, we grew quickly. Administration was taking too much of our time and not where I wanted to spend my efforts. I wanted to use that time to find new clients we could offer our services to and recruit strong candidates for those positions.”
                                </p>
                                <p className='nexasCardName mt-5'>Mark Makowich, President & Owner</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default NexasClientFeedback1;