import React, { Component, Fragment } from 'react';
import AskQuesPayrollServices from '../components/AskQuesPayrollServices/AskQuesPayrollServices';
import PayrollServicefifthPart from '../components/PayrollServicefifthPart/PayrollServicefifthPart';
import PayrollServiceEightPart from '../components/PayrollServicesEightPart/PayrollServiceEightPart';
import PayrollServicesSeventhPart from '../components/PayrollServicesSeventhPart/PayrollServicesSeventhPart';
import PayrollServicesSisPart from '../components/PayrollServicesSisPart/PayrollServicesSisPart';
import PayrollServiceThirdPart from '../components/PayrollServiceThirdPart/PayrollServiceThirdPart';
import PayrollSrrviceSecondPart from '../components/PayrollSrrviceSecondPart/PayrollSrrviceSecondPart';
import QuoteComponentCyan from '../components/QuoteComponentCyan/QuoteComponentCyan';
import QuoteComponentGray from '../components/QuoteComponentGray/QuoteComponentGray';
import SubLinkTopBanner from '../components/SubLinkTopBanner/SubLinkTopBanner';
import TopHead from '../components/TopHead/TopHead';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';


class PayrollServicesPage extends Component {
    render() {
        return (
            <Fragment>
                <TopHead />
                <TopNavigation title="Payroll Services | V-TAC Payroll Management"/>
                <SubLinkTopBanner />
                <PayrollSrrviceSecondPart />
                <PayrollServiceThirdPart />
                <QuoteComponentGray />
                <PayrollServicefifthPart />
                <PayrollServicesSisPart />
                <PayrollServicesSeventhPart />
                <PayrollServiceEightPart />
                <AskQuesPayrollServices />
                <QuoteComponentCyan />
                <TradePart />
                <Footer />
            </Fragment>
        );
    }
}

export default PayrollServicesPage;