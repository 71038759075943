import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import drschindler1 from '../../asset/images/dr-schindler-1.jpg';
import mountroyaldental from '../../asset/images/mount-royal-dental.jpg';
import { Link } from 'react-router-dom';

class DentalOfficesSection4 extends Component {
    render() {
        return (
            <Fragment>
                <Container className='SectionBetween'>
                    <div style={{ width: "80%" }} className="container">
                        <h2 className='mainTitle'>
                            HELPING DENTAL PRACTICES MANAGE THEIR PAYROLL <span className='spancolor'>STRESS-FREE</span>
                        </h2>
                    </div>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <Card className='dentalCard float-right mt-3'>
                                <Card.Img variant="top" src={drschindler1} alt="Dr. Schindler & Associates" title='Dr. Schindler & Associates' />
                                <Card.Body>
                                    <Card.Title className='cardTtle'>Dr. Schindler & Associates</Card.Title>
                                    <Card.Text className='description'>
                                        “VPM frees me from a stressful but necessary part of my work. Payroll used to mushroom out of control time-wise, and more time spent on that means less time to focus on my own work. VPM has made my life and my work easier.”
                                    </Card.Text>

                                    <Link to="/dr-schindler-associates"> <Button variant="primary" className='Dental mt-2'><span>READ FULL STORY</span></Button>{' '}</Link>
                                </Card.Body>
                            </Card>

                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <Card className='dentalCard mt-3'>
                                <Card.Img variant="top" src={mountroyaldental} alt="Mount Royal Dental" title='Mount Royal Dental' />
                                <Card.Body>
                                    <Card.Title className='cardTtle'>Mount Royal Dental</Card.Title>
                                    <Card.Text className='description'>
                                        “On the business side, fees are reasonable compared to other providers, services are outstanding, and the relationship has been strong. What else can anyone else ask for?”
                                    </Card.Text>

                                    <Link to="/mount-royal-dental"> <Button variant="primary" className='Dental mt-2'><span>READ FULL STORY</span></Button>{' '}</Link>

                                </Card.Body>
                            </Card>
                        </Col>c
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default DentalOfficesSection4;