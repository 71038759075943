import React, { Component, Fragment } from 'react';
import NexasClientFeedback1 from '../components/NexasClientFeedback1/NexasClientFeedback1';
import NexasClientFeedback2 from '../components/NexasClientFeedback2/NexasClientFeedback2';
import NexasStaffingBanner from '../components/NexasStaffingBanner/NexasStaffingBanner';
import NexasStaffingSec1 from '../components/NexasStaffingSec1/NexasStaffingSec1';
import NexasStaffingSec2 from '../components/NexasStaffingSec2/NexasStaffingSec2';
import TopHead from '../components/TopHead/TopHead';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import QuoteComponentCyan from '../components/QuoteComponentCyan/QuoteComponentCyan';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';

class NexasStaffingPage extends Component {
    render() {
        return (
            <Fragment>
                <TopHead />
                <TopNavigation title="Nexus Staffing | V-TAC Payroll Management"/>
                <NexasStaffingBanner />
                <NexasStaffingSec1 />
                <NexasClientFeedback1 />
                <NexasStaffingSec2 />
                <NexasClientFeedback2 />
                <QuoteComponentCyan />
                <TradePart />
                <Footer />
            </Fragment>
        );
    }
}

export default NexasStaffingPage;