import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";

class AskQuesConstructionOrConstructor extends Component {

    handleClick(id) {
        var coll = document.getElementById(id);
        coll.classList.toggle("factive");
        var content = coll.nextElementSibling;
        if (content.style.maxHeight) {
            content.style.maxHeight = null;
        } else {
            content.style.maxHeight = content.scrollHeight + "px";
        }
    }
    render() {
        return (
            <Fragment>
                <Container className='SectionBetween text-center container'>
                    <Row>
                        <Col>
                            <div className='card'>
                                <div style={{ width: "70%" }} className=" container text-center mt-3"><h2 className='mainTitle text-center ' >FREQUENTLY ASKED QUESTIONS ABOUT OUR <span className='spancolor'>PAYROLL SERVICES</span>
                                </h2>
                                </div>

                                <button class="FAQcollapsible" id='b1' onClick={() => this.handleClick('b1')}>Can VPM track and report payroll by department?</button>
                                <div class="FAQcontent">
                                    <p className='FaqDescription'>Yes. As part of the onboarding process, we will ensure our systems are set up to reflect how your organization is structured in order to meet your departmental reporting needs. You have the ability to customize your payroll regarding taxes, deductions, benefits and paid/unpaid leave.</p>
                                    <p className='FaqDescription'>If you have any questions about how VPM can help your business with your payroll, <Link to="/contact" className='faqLink'> contact us </Link> today!</p>
                                </div>
                                <button class="FAQcollapsible" id='b2' onClick={() => this.handleClick('b2')}>How does VPM pay a client’s taxes?</button>
                                <div class="FAQcontent">
                                    <p className='FaqDescription'>Taxes are submitted to the government electronically, with source deductions (CPP, EI, and Tax – Federal & Provincial including EHT in Ontario) submitted on your behalf. VPM ensures compliance to CRA provincial legislation by utilizing built-in options.</p>
                                    <p className='FaqDescription'><Link to="/contact" className='faqLink'>Contact VPM </Link> today for more information about our payroll services.</p>
                                </div>
                                <button class="FAQcollapsible" id='b3' onClick={() => this.handleClick('b3')}>Does VPM stay up-to-date with new payroll rules and legislations?</button>
                                <div class="FAQcontent">
                                    <p className='FaqDescription'>Yes. We follow all federal and provincial government changes affecting payroll. We add additional value to some client businesses by privately advising when a client might be offside on any legal obligation to their employees.</p>
                                    <p className='FaqDescription'><Link to="/contact" className='faqLink'>Contact us </Link> today for more information regarding our payroll services.</p>
                                </div>
                                <button class="FAQcollapsible" id='b4' onClick={() => this.handleClick('b4')}>Can you direct deposit payroll into our employees accounts?</button>
                                <div class="FAQcontent">
                                    <p className='FaqDescription'>Yes, we can do that. Using our secure proprietary payroll system, VPM is able to offer direct deposit payroll for your employees. It also gives you and your employees quick and easy online access to payroll information from any location using your desktop, tablet or mobile device.</p>
                                    <p className='FaqDescription'>If you have more questions regarding our payroll services, <Link to="/contact" className='faqLink'>contact us </Link> today!</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className='SectionBetween'></div>
                </Container>
            </Fragment>
        );
    }
}

export default AskQuesConstructionOrConstructor;