import React, { Component, Fragment } from 'react';
import MountClientFeedback1 from '../components/MountClientFeedback1/MountClientFeedback1';
import MountClientFeedback2 from '../components/MountClientFeedback2/MountClientFeedback2';
import MountRoyalDentalBanner from '../components/MountRoyalDentalBanner/MountRoyalDentalBanner';
import MountRoyalDentalSec1 from '../components/MountRoyalDentalSec1/MountRoyalDentalSec1';
 import MountRoyalDentalsec2 from '../components/MountRoyalDentalsec2/MountRoyalDentalsec2';
import TopHead from '../components/TopHead/TopHead';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import QuoteComponentCyan from '../components/QuoteComponentCyan/QuoteComponentCyan';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';


class MountRoyalDentalPage extends Component {
    render() {
        return (
            <Fragment>
                <TopHead />
                <TopNavigation title="Mount Royal Dental | V-TAC Payroll Management" />
                <MountRoyalDentalBanner />
                <MountRoyalDentalSec1 />
                <MountClientFeedback1 />
                <MountRoyalDentalsec2 />         
                <MountClientFeedback2 /> 
                <QuoteComponentCyan />
                <TradePart />
                <Footer />
            </Fragment>
        );
    }
}

export default MountRoyalDentalPage;